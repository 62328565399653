import axios from "axios";
import { get, isEmpty } from "lodash";
import toast, { Toaster } from "react-hot-toast";
import queryString from "query-string";
import dotenv from "react-dotenv";
import { hasStorageToken, getStorageToken } from "../../utils/storage";

const HttpClient = axios.create({
  baseURL: dotenv.base_url,
  paramsSerializer: (params) => {
    return queryString.stringify(params, { arrayFormat: "bracket" });
  },
});

HttpClient.interceptors.request.use(
  (config) => {
    // config.headers["authorization"] = `bearer qgCf8p9iwxLUnD6RUuwqFvjs6viDYSsf`
    // const token = hasStorageToken() && getStorageToken();
    const token = localStorage.getItem("id_token");
    config.headers["Content-Type"] = "application/json";
    config.headers["Authorization"] = `bearer ${token}`;
    // console.log("token")
    // if (!isEmpty(token)) {
    //     config.headers["authorization"] = `bearer ${token}`;
    // }

    return config;
  },
  (error) => {
    toast.error(get(error, "message", "Connection failed!"));
    return Promise.reject(error);
  }
);

HttpClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    let statusCode = get(error, "response.status");

    if (!statusCode) {
      toast.error("Connection failed!", {
        toastId: "connection-failed",
      });
    } else if (statusCode !== 422) {
      if (statusCode === 401 || statusCode === 650 || statusCode === 403) {
        window.localStorage.clear();
        window.location.replace("/");
      } else if (statusCode >= 400 && statusCode < 500) {
        toast.warn(get(error, "response.data.message", "Operation failed!"));
      } else {
        toast.error("Internal server error");
      }
    }

    return Promise.reject(error);
  }
);

export default HttpClient;
