import React, { useEffect, useState } from "react";
import { DateRangePicker, DateRange } from "react-date-range";
import { ListView } from "../../components/ListView";
import moment from "moment";
import { Button } from "@mui/material";
import { api } from "../../services/api";
import { IncomingTasksFields } from "../../context/Forms";
const CancelledCases = () => {
  const [loading, setLoading] = useState(true);
  const [cases, setCases] = useState(null);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(moment().subtract(10, "days").calendar()),
      endDate: new Date(moment()),
      key: "selection",
    },
  ]);
  useEffect(() => {
    const startTimeTmp = moment(dateRange[0].startDate).format("l");
    const endTimeTmp = moment(dateRange[0].endDate).format("l");
    const start_time = [
      `"${startTimeTmp.split("/")[0]}-${startTimeTmp.split("/")[1]}-${
        startTimeTmp.split("/")[2]
      }"`,
      `"${endTimeTmp.split("/")[0]}-${endTimeTmp.split("/")[1]}-${
        endTimeTmp.split("/")[2]
      }"`,
    ];
    api
      .getAll(
        `incoming_task/cancelled_cases?start_time=${
          startTimeTmp.split("/")[2]
        }-${startTimeTmp.split("/")[0]}-${
          startTimeTmp.split("/")[1]
        }&end_time=${endTimeTmp.split("/")[2]}-${endTimeTmp.split("/")[0]}-${
          endTimeTmp.split("/")[1]
        }&sort=updatedAt%20DESC`
      )
      .then((res) => {
        setCases(res);
        setLoading(false);
      });
  }, [dateRange]);
  const [enableSetRang, setEnableSetRang] = useState(false);
  return (
    <React.Fragment>
      <React.Fragment>
        <Button onClick={() => setEnableSetRang(!enableSetRang)}>
          {enableSetRang ? "Hidden" : "Show"} Date Rang
        </Button>
        {enableSetRang && (
          <DateRange
            editableDateInputs={true}
            onChange={(item) => setDateRange([item.selection])}
            moveRangeOnFirstSelection={false}
            ranges={[
              {
                startDate: new Date(dateRange[0].startDate),
                endDate: new Date(dateRange[0].endDate),
                key: "selection",
              },
            ]}
          />
        )}
      </React.Fragment>
      <ListView
        localData={cases}
        loading={loading}
        notFoundTitle="global_not_found"
        // withPagination
        withSearchs
        title="cancelled_cases"
        searchable_fields={["name"]}
        withShow
        // controller="incoming_task/cancelled_cases"
        fields={IncomingTasksFields}
      />
    </React.Fragment>
  );
};
export default CancelledCases;
