import React from "react";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
const FooterStepper = ({
  activeStep,
  steps,
  handleComplete,
  handleNext,
  handleBack,
}) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
      <Button
        color="inherit"
        disabled={activeStep === 0}
        onClick={handleBack}
        sx={{ mr: 1 }}
      >
        {t("stepper.back")}
      </Button>
      <Box sx={{ flex: "1 1 auto" }} />
      <Button
        onClick={activeStep === steps.length - 1 ? handleComplete : handleNext}
        sx={{ mr: 1 }}
      >
        {activeStep === steps.length - 1
          ? t("stepper.finish")
          : t("stepper.next")}
      </Button>
    </Box>
  );
};
export default FooterStepper;
