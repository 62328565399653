import React, { useState } from "react";
// import { withStyles } from "@material-ui/styles";
import { withStyles } from "@mui/styles";
import { alpha } from "@mui/material/styles";
// import { InputLabel } from "@material-ui/core";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";

import InputBase from "@mui/material/InputBase";
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
// import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
// import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { EmailOutlined, PhoneAndroid } from "@material-ui/icons";
import './style.css';
const StyledFormControl = withStyles((theme) => ({
  root: {
    paddingRight: 3,
    width: "100%",
    border: "1px solid #ced4da !important",
    height:56,
    position: "relative",
    backgroundColor: "#fcfcfb",
    borderRadius: 4,
    fontFamily: [
      "Arial",
      "Roboto",
      '"Segoe UI"',
      "sans-serif",
      "-apple-system",
      '"Helvetica Neue"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      "BlinkMacSystemFont",
      '"Apple Color Emoji"',
    ].join(","),
    padding: "10px 12px",
    // transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&$focused": {
      backgroundColor: "#fff",
      // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
  },
}))(FormControl);
const StyledInputLabel = withStyles((theme)=>({
  root:{
    paddingTop:'6px'
  }
}))(InputLabel)
const StyledInputText = withStyles((theme) => ({
  input: {
    height: '34px',
    paddingLeft: '5px',
    marginTop: '10px',
    marginRight: '4px',
    '&:-internal-autofill-selected': {
      transitionDelay: '9999s',
      transitionProperty: 'background-color, color',
      background:"yellow"
    },
  },
  root: {
    display: "flex",
    justifyContent: "space-between",
    "label + &": {
      marginTop: 6,
    },
    "& input": {
      width: "100% !important",
    },
  },
  focused: {
    backgroundColor: theme.palette.common.white,
  },
  input: {
    backgroundColor: theme.palette.common.white,
    fontSize: 16,
    width: "100% !important",
  },
}))(InputBase);

const EmailOrMobile = ({
  id,
  label,
  variant,
  className,
  style,
  defaultValue,
  onChange,
  emailOrMobile,
  value,
}) => {
  const [type, setType] = useState("email");
  const handleChangeEmailOrMobile = (event, newMethod) => {
    if (newMethod !== null) {
      setType(newMethod);
    }
  };
  return (
    <StyledFormControl>
      <StyledInputLabel variant="filled" htmlFor="bootstrap-input">
        {label}
      </StyledInputLabel>
      <StyledInputText
        endAdornment={
          <Adornment
            data={type}
            onChangeType={(_, e) => handleChangeEmailOrMobile(_, e)}
          />
        }
        defaultValue={defaultValue}
        id={id}
        onChange={(e) => {
          let mailformat =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (
            value.match(mailformat) ||
            isNaN(value) ||
            !value.startsWith("+")
          ) {
            setType("email");
          } else {
            setType("mobile");
          }
          onChange({ type: type, data: e.target.value });
        }}
      />
    </StyledFormControl>
  );
};

const StyledInputAdornment = withStyles({
  positionEnd: {
    paddingRight: "0",
  },
})(InputAdornment);

const Adornment = ({ data, onChangeType }) => {
  return (
    <StyledInputAdornment position="end">
      <ToggleButtonGroup
        value={data ? data : "email"}
        exclusive
        onChange={onChangeType}
        aria-label="text alignment"
      >
        <ToggleButton value="email" aria-label="left aligned">
          <EmailOutlined />
        </ToggleButton>
        <ToggleButton value="mobile" aria-label="centered">
          <PhoneAndroid />
        </ToggleButton>
      </ToggleButtonGroup>
    </StyledInputAdornment>
  );
};
export { EmailOrMobile };
