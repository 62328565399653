import React from "react";
import { ListView } from "../../components/ListView";
import { IncomingTasksFields } from "../../context/Forms";
const MyTasks = () => {
  return (
    <ListView
      notFoundTitle="global_not_found"
      withPagination
      withSearch
      title="my_cases"
      searchable_fields={["name"]}
      withShow
      controller="incoming_task/my_cases"
      fields={IncomingTasksFields}
    />
  );
};
export default MyTasks;
