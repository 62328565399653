import React, { useEffect, useState, useRef, useMemo } from "react";
import dotenv from "react-dotenv";
import JoditEditor from "jodit-react";
import { useDropzone } from "react-dropzone";
import {
  InputSelect,
  InputText,
  FormControl,
} from "../../../../../components/Forms";
import { api } from "../../../../../services/api";
import Skeleton from "@mui/material/Skeleton";
import { Redirect } from "react-router-dom";
import {
  Grid,
  Button,
  FormControlLabel,
  FormGroup,
  IconButton,
  Box,
} from "@mui/material";

import { IncomingTasksFields } from "../../../../../context/Forms";
import { SettingsSuggest } from "@mui/icons-material";
import { List as ListIcon, Search as SearchIcon } from "@mui/icons-material";
import InputIcon from "@mui/icons-material/Input";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import StyledSwitch from "./../../../../../components/Forms/StyledComponents/StyledSwitch";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import "./style.css";
const axios = require("axios");

const ReplyComponent = (props) => {
  const [loading, setLoading] = useState(true);
  const [task, setTask] = useState();
  const [searchInput, setSearchInput] = useState("");
  const [taskList, setTaskList] = useState([]);
  const [casesList, setCasesList] = useState([]);
  const [caseId, setCaseId] = useState();
  const [incomingForm, setIncomingForm] = useState({});
  const [message, setMessage] = useState("");
  const [taskType, setTaskType] = React.useState("incoming");
  const [ownerType, setOwnerType] = useState("owner");
  const [newTask, setNewTask] = useState(false);
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [redirect, setRedirect] = useState(null);
  const [files, setFiles] = React.useState([]);
  const [filesSrc, setFilesSrc] = useState([]);
  useEffect(() => {
    setLoading(false);
  }, []);
  const onDrop = React.useCallback((acceptedFiles) => {
    setFiles((prev) => [...prev, ...acceptedFiles]);
    console.log("48===>", files);
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleChange = (event, nextView) => {
    setTaskType(nextView);
  };

  const submitReply = (e) => {
    api
      .create({
        controller: `messages/submit_reply_on_task/${props.params.id}`,
        data: {
          message: message,
          attach: filesSrc,
        },
      })
      .then((result) => {
        console.log(result);
        // if (result && result.data) {
        //   console.log(result.data);
        //   if (result.data.message.incoming || result.data.message.task) {
        //     if (result.data.message.incoming.length > 0) {
        //       setRedirect("/app/incoming/view/" + result.data.message.incoming);
        //     } else {
        //       setRedirect("/tasks/view/" + result.data.task);
        //     }
        //   } else {
        //     props.setRefreshMessage(true);
        //   }
        //   props.setRefreshMessage(true);
        // } else {
        //   console.log(result);
        // }
      });
  };

  const fileList = files.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes{" "}
      <Button
        onClick={() =>
          setFiles((prevState) => prevState.filter((i) => i.path != file.path))
        }
      >
        Delete
      </Button>
    </li>
  ));
  const uploadFunc = () => {
    let formData = new FormData();
    formData.append("attach", files[0]);
    api
      .upload_file({
        controller: `messages/upload`,
        data: formData,
      })
      .then((result) => {
        setFilesSrc((prevState) => [...prevState, ...result.data.files]);
      });
  };
  // const { getRootProps, getInputProps, open } = useDropzone({ noClick: true });

  return (
    <Grid
      container
      style={{ borderTop: "1px dotted #9b9e57", marginTop: 5, paddingTop: 5 }}
    >
      <Grid item md={12} lg={12} sm={12} xs={12}>
        {loading ? (
          <Skeleton height={150} />
        ) : (
          <Grid container>
            <Grid
              item
              style={{
                border: "1px solid #80808069",
                margin: 5,
                padding: 5,
              }}
              xs={12}
              md={12}
              lg={12}
              sm={12}
            >
              <JoditEditor
                // ref={editor}
                value={message}
                // config={config}
                tabIndex={1} // tabIndex of textarea
                onBlur={(newContent) => setMessage(newContent)} // preferred to use only this option to update the content for performance reasons
              />
            </Grid>
            <Grid xs={12} md={12} lg={12} sm={12}>
              <section className="container">
                <div
                  {...getRootProps({
                    className: "dropzone",
                  })}
                >
                  <input {...getInputProps()} />
                  <p>Drag 'n' drop some files here, or click to select files</p>
                </div>
                <aside>
                  <h4>Files</h4>
                  <ul>{fileList}</ul>
                </aside>
              </section>
              <Button
                disabled={filesSrc.length >= files.length}
                onClick={() => uploadFunc()}
              >
                Upload
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Button onClick={() => submitReply()}>Submit Email</Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
export default ReplyComponent;
