import * as React from "react";
import { Grid } from "@material-ui/core";
import AddNote from "./AddNote";

export default function HeaderNote(props) {
  return (
    <Grid container justifyContent="center">
      <Grid item>
        <AddNote {...props} />
      </Grid>
    </Grid>
  );
}
