import React, { useEffect, useState } from "react";
import i18n from "i18next";
import { Grid } from "@material-ui/core";
import { Location } from "../../../../components";
import { TasksFields, TasksSteps } from "../../steps";
import {
  InputRadio,
  InputText,
  FormControl,
} from "../../../../components/Forms";
import { useSelector, useDispatch } from "react-redux";
import { AddNewTask } from "../../../../redux/actions/action";
import { task } from "../../../../redux/store";
import { add, onChangeMarker } from "../../../../redux/features/tasks";
const Fields = [
  {
    type: "check",
    label: i18n.t("services.umzug"),
    id: "move",
  },
  {
    type: "check",
    label: i18n.t("services.demontage"),
    id: "demontage",
  },
  {
    type: "check",
    label: i18n.t("services.montage"),
    id: "montage",
  },
  {
    type: "check",
    label: i18n.t("services.entsorgung"),
    id: "entsorgung",
  },
  {
    type: "check",
    label: i18n.t("services.einpackservice"),
    id: "einpackservice",
  },
  {
    type: "check",
    label: i18n.t("services.auspackservice"),
    id: "auspackservice",
  },
  {
    type: "check",
    label: i18n.t("services.nur_transport_von_a_nach_b"),
    id: "nur_transport_von_a_nach_b",
  },
  {
    type: "check",
    label: i18n.t("services.ausmalen"),
    id: "ausmalen",
  },
  {
    type: "check",
    label: i18n.t("services.reinigung"),
    id: "reinigung",
  },
  {
    type: "check",
    label: i18n.t("services.repairing"),
    id: "repairing",
  },
  {
    type: "check",
    label: i18n.t("services.help_service"),
    id: "help_service",
  },
];
const ServicesComp = () => {
  const dispatch = useDispatch();

  const new_task = useSelector((state) => state.task.new);
  const onChange = (e) => {
    const data = { id: e.target.id, data: e.target.checked };
    dispatch(add(data));
  };
  return (
    <div style={{ margin: 10 }}>
      <FormControl
        column={2}
        read_only={true}
        onChange={onChange}
        sendAfterComplete
        fields={Fields}
      />
    </div>
  );
};
export default ServicesComp;
