import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// import { makeStyles } from '@mui/styles';
import {Button,CircularProgress} from "@mui/material";
import { green } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: 8,
    },
  },
}));

const InputButton = ({
  size,
  href,
  color,
  style,
  loading,
  onClick,
  variant,
  disabled,
  children,
  className,
  component,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Button
        size={size}
        href={href}
        color={color ? color : "primary"}
        variant={variant ? variant : "contained"}
        disabled={disabled}
        className={className}
        component={component}
        style={style}
        onClick={onClick}
      >
        {loading && (
          <CircularProgress
            size={24}
            sx={{
              color: green[500],
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />
        )}
        {children}
      </Button>


    </div>
  );
};

export { InputButton };
