import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: {
    id: null,
    user_id: null,
    token: localStorage.getItem("id_token"),
    role: null,
    status: null,
    email: null,
    mobile: null,
    first_name: "",
    last_name: "",
  },
  company: "all",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state, action) => {
      state.value = action.payload;
    },
    setRoleStatus: (state, action) => {
      state.value.role = action.payload.role;
      state.value.status = action.payload.status;
    },
    setData: (state, action) => {
      return {
        ...state,
        value: {
          ...state.value,
          ...action.payload,
        },
      };
    },
    changeCompany: (state, action) => {
      return { ...state, company: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const { login, setRoleStatus, setData, changeCompany } =
  userSlice.actions;

export default userSlice.reducer;
