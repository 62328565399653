import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from '@material-ui/core/styles';
import { CostsFields } from '../../../context/Forms'
import PageTitle from "../../../components/PageTitle/PageTitle";
import { Button} from "@mui/material";
import ViewOne from "../../../components/ViewOne/ViewOne"
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  table: {
    minWidth: 650,
  },
  cellWidth: {
    width: "30%",
  },
});

const CostsView = (props) => {
  const classes = useStyles();
  const user = useSelector(state => state.user.value)
  const {t} = useTranslation();
  return (
    <React.Fragment>
      <PageTitle title={t("menus.costs")} button={user.role > 2 &&
        (
          <Button component={Link} to="/app/costs/create" variant="contained" size="medium" color="primary">
            {t("events.create")}
          </Button>
        )}
      />
      <div className={classes.root}>
        <ViewOne title="title" manualWithController autoTranslation id={props.match.params.id} fields={CostsFields} controller="costs" />
      </div>
    </React.Fragment>
  );
};
export default CostsView;
