import React from "react"
import { StyledInputAdornment } from "../StyledComponents";
import { Tooltip } from "@material-ui/core";
import { HelpOutline } from "@material-ui/icons";
import i18n from "../../../i18n";

const HelperInput = ({ id }) => {
    return (
      <StyledInputAdornment position="end">
        <Tooltip title={i18n.t(`helper_tooltip.${id}`)} placement="bottom">
          <HelpOutline />
        </Tooltip>
      </StyledInputAdornment>
    );
};
const HelperInputForm = ({id})=>{
  return (
    <StyledInputAdornment position="end">
      <Tooltip title={i18n.t(`helper_tooltip.${id}`)} placement="bottom">
        <HelpOutline />
      </Tooltip>
    </StyledInputAdornment>
  );
}
export {HelperInput,HelperInputForm}