import React from 'react';
import { StyledGrid } from './StyledGrid';
import { Typography, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
const Box = (props) => {
    const { t } = useTranslation();

    return (
        <StyledGrid
            component={props.Link}
            to={props.to}
            onClick={props.onClick}
            item
            {...props.grid}
        >
            {props.title && (
                <Typography variant="h6" component="div" gutterBottom>
                    {props.title}
                </Typography>
            )}
            {props.children}
            {props.endBox}
        </StyledGrid>
    );
};
export { Box };
