import React from "react"
import {Step,Stepper,Typography,StepLabel} from '@mui/material';

const StepperTitles = ({steps,active})=>{
    return(
        <Stepper style={{overflow:"auto",padding:10}} activeStep={active}>
            {steps.map((label, index) => {
                const labelProps = {};
                // if (isStepFailed(index)) {
                //     labelProps.optional = (<Typography variant="caption" color="error">Alert message</Typography>);
                //     labelProps.error = true;
                // }
                return (
                    <Step key={label}>
                        <StepLabel style={{minWidth:113}} {...labelProps}>{label}</StepLabel>
                    </Step>
                );
            })}
        </Stepper>
    )
}
export {StepperTitles}