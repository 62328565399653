import React, { useState, useEffect } from "react";
import {
  Tab,
  Grid,
  Tabs,
  Button,
  TextField,
  Typography,
  CircularProgress,
  InputAdornment,
  Tooltip,
} from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
// import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import userStore from "./../../redux/features/user";
// import { useDispatch } from "react-redux";
// import { bindActionCreators } from "redux";
// import { actionCreators } from "../../redux/state";
import i18n from "../../i18n";
import dotenv from "react-dotenv";
// styles
import useStyles from "./styles";

// logo
import logo from "./logo.svg";
import google from "../../images/google.svg";
// import CodeInput from "./mailVerifCodenput";

import VerifCode from "./VerifCode";
import SwitchButton from "../../components/SwitchButton/SwitchButton";
import ForgetPassword from "../../components/ForgetPassword/ForgetPassword";
// context
import {
  loginUser,
  registerUser,
  useUserDispatch,
} from "../../context/UserContext";
// import { bindExpression } from "@babel/types";

function Login(props) {
  const classes = useStyles();
  const [withRegister, setWithRegister] = useState(true);
  const [register, setRegister] = useState(false);
  const [login, setLogin] = useState(false);
  const userDispatch = useUserDispatch();
  const dispatch = useDispatch();
  const [loginValue, setLoginValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [validateUsername, setValidateUsername] = useState(false);
  const [passwordValue, setPasswordValue] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [seconds, setSeconds] = useState(0);
  const [disableBtn, setDisableBtn] = useState(false);
  const [showVerifyCodeInput, setShowVerifyCodeInput] = useState(false);

  const state = useSelector((state) => state);
  const EmailCheck = (loginValue) => {
    let mailformat =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (
      loginValue.match(mailformat) ||
      (!isNaN(loginValue) && loginValue.length > 10)
    ) {
      setValidateUsername(false);
    } else {
      setValidateUsername(true);
    }
  };

  useEffect(() => {
    if (seconds > 0 && disableBtn) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setSeconds(10);
      setDisableBtn(false);
    }
  }, [
    // startCounter,
    seconds,
    disableBtn,
  ]);

  // const sendMail = useCallback(() => {
  //   setDisableBtn(true);
  //   setStartCounter(!startCounter);
  //   setSeconds(10);
  // }, []);

  useEffect(() => {
    EmailCheck(loginValue);
  }, [loginValue]);

  const onClickLoading = () => {
    setIsLoading(true);
    loginUser(
      {
        dispatch: userDispatch,
        login: loginValue,
        password: passwordValue,
        history: props.history,
        loginPage: login,
      },
      (res) => {
        if (res === "user_not_found") setRegister(true);
        if (res === "enter_your_password") setLogin(true);
        if (res.code) dispatch(userStore.login(res));
        res && setIsLoading(false);
      }
    );
  };

  const onRegisterClick = () => {
    setIsLoading(true);
    setShowVerifyCodeInput(true);
    registerUser(
      {
        dispatch: userDispatch,
        loginValue: loginValue,
        passwordValue: passwordValue,
        history: props.history,
        // error,
      },
      (res) => {
        if (res.code) dispatch(userStore.login(res));
        res && setIsLoading(false);
      }
    );
  };
  return (
    <Grid container className={classes.container}>
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <img src="./assets/Umzugsfirma.webp" style={{ width: "80%" }} />
          <div className={classes.formDividerContainer}>
            <div className={classes.formDivider} />
            <Typography className={classes.formDividerWord}>
              {i18n.t(`global.register_or_login`)}
            </Typography>
            <div className={classes.formDivider} />
          </div>
          <React.Fragment>
            <TextField
              variant="outlined"
              id="username"
              InputProps={{
                classes: {
                  input: classes.textField,
                },
              }}
              disabled={login || register}
              value={loginValue}
              onChange={(e) => setLoginValue(e.target.value)}
              margin="normal"
              placeholder="Email Adress or Mobile Number"
              type="text"
              fullWidth
            />
            {(login || register) && (
              <TextField
                variant="outlined"
                id="password"
                InputProps={{ classes: { input: classes.textField } }}
                value={passwordValue === true ? "" : passwordValue}
                onChange={(e) => setPasswordValue(e.target.value)}
                margin="normal"
                placeholder={i18n.t(`user_fields.password`)}
                type="password"
                fullWidth
              />
            )}
            {register && (
              <TextField
                variant="outlined"
                id="verifyPass"
                InputProps={{ classes: { input: classes.textField } }}
                value={confirmPassword || ""}
                onChange={(e) => setConfirmPassword(e.target.value)}
                margin="normal"
                placeholder={i18n.t(`account.confirmPss`)}
                type="password"
                fullWidth
              />
            )}
          </React.Fragment>
          <div className={classes.formButtons}>
            {isLoading ? (
              <CircularProgress size={26} className={classes.loginLoader} />
            ) : (
              <Button
                disabled={
                  (!login && !register && validateUsername) ||
                  ((register || login) && passwordValue.length < 8) ||
                  (passwordValue !== confirmPassword && register)
                }
                onClick={() =>
                  login || !register ? onClickLoading() : onRegisterClick()
                }
                variant="contained"
                color="primary"
                size="large"
              >
                {(register || login) && i18n.t(`global.continue`)}
                {!login && !register && i18n.t(`account.login`)}
              </Button>
            )}
          </div>
        </div>
        {/* <ForgetPassword /> */}
      </div>
    </Grid>
  );
}

export default withRouter(Login);
