import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Button } from "@material-ui/core";
import { Location } from "../../../../components";
import { TasksFields, TasksSteps } from "../../steps";
import {
  InputRadio,
  InputText,
  FormControl,
} from "../../../../components/Forms";
import { useSelector, useDispatch } from "react-redux";
import TitleComponent from "../TitleComponent";
import { AddNewTask } from "../../../../redux/actions/action";
import { task } from "../../../../redux/store";
import { updateField } from "../../../../redux/features/tasks";

const ServicesComp = (prop) => {
  const { t } = useTranslation();
  const [fields, setFields] = useState([]);
  const [editable, setEditable] = useState(false);
  const dispatch = useDispatch();
  const view = useSelector((state) => state.task.view);
  useEffect(() => {
    if (view[0]) {
      setFields([
        {
          type: "check",
          label: t("services.umzug"),
          id: "move",
          value: view[0].move,
          disabled: !prop.globalEditable,
        },
        {
          type: "check",
          label: t("services.demontage"),
          id: "demontage",
          value: view[0].demontage,
          disabled: !prop.globalEditable,
        },
        {
          type: "check",
          label: t("services.montage"),
          id: "montage",
          value: view[0].montage,
          disabled: !prop.globalEditable,
        },
        {
          type: "check",
          label: t("services.entsorgung"),
          id: "entsorgung",
          disabled: !prop.globalEditable,
          value: view[0].entsorgung,
        },
        {
          type: "check",
          label: t("services.einpackservice"),
          id: "einpackservice",
          value: view[0].einpackservice,
          disabled: !prop.globalEditable,
        },
        {
          type: "check",
          label: t("services.auspackservice"),
          id: "auspackservice",
          value: view[0].auspackservice,
          disabled: !prop.globalEditable,
        },
        {
          type: "check",
          label: t("services.nur_transport_von_a_nach_b"),
          id: "nur_transport_von_a_nach_b",
          value: view[0].nur_transport_von_a_nach_b,
          disabled: !prop.globalEditable,
        },
        {
          type: "check",
          label: t("services.ausmalen"),
          id: "ausmalen",
          value: view[0].ausmalen,
          disabled: !prop.globalEditable,
        },
        {
          type: "check",
          label: t("services.reinigung"),
          id: "reinigung",
          value: view[0].reinigung,
          disabled: !prop.globalEditable,
        },
        {
          id: "repairing",
          type: "check",
          label: t("services.repairing"),
          value: view[0].repairing,
          disabled: !prop.globalEditable,
        },
        {
          id: "help_service",
          type: "check",
          label: t("services.help_service"),
          value: view[0].help_service,
          disabled: !prop.globalEditable,
        },
      ]);
    }
  }, [view, editable, prop.globalEditable]);
  const onChangeOtherFields = (e) => {
    const tmp = {
      task_id: prop.match.params.id,
      id: e.target.id,
      data: !view[0][e.target.id],
    };
    dispatch(updateField(tmp));
  };
  return (
    <div style={{ margin: 10 }}>
      <TitleComponent
        title={"Umzugs Plan"}
        setEditable={() => setEditable(!editable)}
        // editable={prop.globalEditable}
      />
      <FormControl
        column={2}
        read_only
        onChange={onChangeOtherFields}
        sendAfterComplete
        fields={fields}
      />
      {editable && (
        <Grid item xs={10} sm={10} md={10} lg={10}>
          <Button
            onClick={() => {
              setEditable(false);
              prop.onSave();
            }}
          >
            {t("services.save")}
          </Button>
        </Grid>
      )}
    </div>
  );
};
export default ServicesComp;
