import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  Paper,
  Collapse,
  Box,
  Stack,
  Dialog,
  styled,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useTranslation } from "react-i18next";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Transition } from "./OtherComps";
import { DestinationLocationComp } from "./DestinationLocationComp";

import {
  delete_destination_in_view,
  edit_destination_in_view,
} from "../../redux/features/tasks";

import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
const truefalse = ["Ja", "Nein"];
const possibility_to_attach_warning_signs_colors = [
  "disabled",
  "warning",
  "error",
  "success",
  "secondary",
  "primary",
];
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  width: "auto",
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const ItemRender = ({ label, title, children }) => {
  if (!children) return <React.Fragment></React.Fragment>;
  return (
    <Item>
      {label}: {children}
    </Item>
  );
};
function Row(props) {
  const { t } = useTranslation();
  const { row, globalEditable } = props;
  const [open, setOpen] = React.useState(false);
  const [editOne, setEditOne] = React.useState(false);
  const dispatch = useDispatch();
  const handleonSaveDestination = (e) => {
    dispatch(edit_destination_in_view(e));
    setEditOne(false);
  };
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {(row.destination_floor || row.destination_plaque) && (
            <span style={{ paddingRight: 12 }}>
              {row.destination_plaque} - {row.destination_floor}
            </span>
          )}
          {row.address}
        </TableCell>
        <TableCell align="right">{row.destination_hnr}</TableCell>
        <TableCell align="right">{row.destination_city}</TableCell>
        <TableCell align="right">{row.destination_stock}</TableCell>
        <TableCell align="right">
          <IconButton
            disabled={!globalEditable}
            onClick={(e) => setEditOne(true)}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            disabled={!globalEditable}
            onClick={(e) =>
              dispatch(
                delete_destination_in_view({
                  id: row.id,
                  task_id: props.task_id,
                })
              )
            }
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Grid container>
                <Grid item xs={12} md={12} lg={12} sm={12}>
                  <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                    <ItemRender label={t("destination_location.siege")}>
                      {row.destination_siege}
                    </ItemRender>
                    <ItemRender label={t("destination_location.tur_nr")}>
                      {row.destination_tur_nr}
                    </ItemRender>
                    <ItemRender label={t("destination_location.plz")}>
                      {row.destination_postcode}
                    </ItemRender>
                    <ItemRender label={t("destination_location.lift")}>
                      {row.destination_lift}
                    </ItemRender>
                    <ItemRender
                      label={t("destination_location.apartment_area")}
                    >
                      {row.destination_apartment_area}
                    </ItemRender>
                    <ItemRender
                      label={t("destination_location.access_to_parking")}
                    >
                      {truefalse[row.destination_access_to_parking - 1]}
                    </ItemRender>
                    <ItemRender
                      label={t(
                        "destination_location.distance_from_parking_to_home"
                      )}
                    >
                      {row.destination_distance_parking_to_home}
                    </ItemRender>
                    <ItemRender label={t("global_fields.total_truck")}>
                      {row.total_truck}
                    </ItemRender>
                    {parseInt(row.destination_more_than_80_furniture) === 1 && (
                      <ItemRender
                        label={t(
                          "destination_location.what_is_the_number_of_furniture_over_80_kg"
                        )}
                      >
                        {row.destination_number_of_furniture_over_80}
                      </ItemRender>
                    )}
                    {parseInt(row.possibility_to_attach_warning_signs) ? (
                      <ItemRender
                        label={t(
                          "destination_location.possibility_to_attach_warning_signs"
                        )}
                      >
                        <WarningAmberIcon
                          color={
                            possibility_to_attach_warning_signs_colors[
                              parseInt(
                                row.possibility_to_attach_warning_signs - 1
                              )
                            ]
                          }
                        />{" "}
                      </ItemRender>
                    ) : (
                      <React.Fragment />
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Dialog
        fullScreen
        open={editOne}
        // onClose={handleClose}
        onClose={() => console.log("close")}
        TransitionComponent={Transition}
      >
        <DestinationLocationComp
          {...row}
          edit={true}
          destinationRow={row}
          {...props}
          title={t("destination_location.update_destination")}
          // handleClickOpen={handleClickOpen}
          task_id={props.task_id}
          handleClose={() => setEditOne(false)}
          handleonSaveDestination={(e) => handleonSaveDestination(e)}
          // handleClose={() => console.log("handleclose")}
        />
      </Dialog>
    </React.Fragment>
  );
}
export { Row };
