import * as React from "react";
import {
  ImageList,
  Chip,
  ImageListItem,
  ImageListItemBar,
  ListSubheader,
  IconButton,
  Button,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { api } from "../../../services/api";
const ImageGallery = (props) => {
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {}, []);
  const syncAttached = () => {
    api
      .create({
        controller: "messages/attached_files",
        data: { email: props.email, id: props.imap },
      })
      .then((qq) => {
        setData(qq.data);
        setLoading(false);
      });
  };
  if (loading)
    return (
      <React.Fragment>
        <Button onClick={() => syncAttached()}>Show Attached</Button>
      </React.Fragment>
    );
  else
    return (
      <React.Fragment>
        {data.files &&
          data.files.map((item) => (
            <a href={item.url}>
              <Chip label={item.filename} />
            </a>
          ))}
      </React.Fragment>
    );
};
export default ImageGallery;
