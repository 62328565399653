import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Grid, Button } from "@mui/material";
import { api } from "../../services/api";
import FieldContent from "./FieldContent";
import { Box } from "./StyledComponents/BoxComponent";

const EditOne = ({
  autoTranslation,
  controller,
  setEdit,
  fields,
  title,
  footerContent,
  data,
  edit,
  id,
  after_save,
}) => {
  const [updateValue, setUpdateValue] = useState(data);

  const editDone = () => {
    api
      .update({
        id: id,
        data: updateValue,
        controller: controller,
      })
      .then((res) => {
        // toast.success(i18n.t(`toast.successEdited`));
        setEdit(false);
      });
  };

  const onChange = (e) => {
    setUpdateValue((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  return (
    <React.Fragment>
      <Box
        component={Link}
        onClickEdit={editDone}
        subTitle={true}
        edit={edit}
        to={"#"}
        key={1}
        title={title}
        grid={{ xs: 12, md: 12, lg: 12 }}
      >
        {data && (
          <Grid container>
            <Grid item container xs={11} md={11} lg={11} sm={11}>
              {Object.entries(data)
                .filter(
                  (fld_itm) =>
                    fields.map((fld) => fld.id).includes(fld_itm[0]) &&
                    fld_itm[0] != "id"
                )
                .map((vals, key) => (
                  <Grid item key={key} xs={12} lg={6} sm={6} padding="0px 10px">
                    <FieldContent
                      autoTranslation={autoTranslation}
                      onChange={onChange}
                      field_value={vals[1]}
                      field_key={vals[0]}
                      fields={fields}
                      updateValue={updateValue}
                    />
                  </Grid>
                ))}
            </Grid>
            {footerContent && footerContent()}
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Button
                onClick={() =>
                  api
                    .create({
                      controller: controller,
                      data: { ...updateValue, id: id },
                    })
                    .then(() => {
                      if (after_save) {
                        after_save();
                      } else {
                        console.log("ok");
                      }
                    })
                }
              >
                Update
              </Button>
            </Grid>
          </Grid>
        )}
      </Box>
    </React.Fragment>
  );
};
export default EditOne;
