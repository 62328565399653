import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {FormControl} from "../../components/Forms"
import { MessageFields } from "../../context/Forms";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Button, Grid } from "@material-ui/core";
import { GridTable } from "../../components/GridView";
import { MessageRight, MessageLeft,TaskSelector } from './components';
import { useSelector } from 'react-redux';
import List from '@material-ui/core/List';
import { InputText } from '../../components/Forms';
import { Link } from 'react-router-dom';
import moment from "moment"
import { useTranslation } from "react-i18next";
import { ListView } from "../../components/ListView";
import { api } from '../../services/api';
const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  table: {
    minWidth: 650,
  },
  cellWidth: {
    width: "30%",
  },
});
const ReplyFooter = ({ message, ticket_id }) => {
  const classes = useStyles();
  const onSubmitReply = (e) => {
      api.create({
          controller: 'ticket/reply',
          data: { message: message, ticket_id: ticket_id },
      }).then((res) => {
          // console.log(res);
      });
  };
  return (
      <Grid container justifyContent="space-between">
          <Grid item>
              <div className={classes.StyleReplyFooterContent}>
                  ddd
              </div>
          </Grid>
          <Grid item>
              <div className={classes.StyleReplyFooterContent}>
                  <Button
                      disabled={message.length < 3}
                      onClick={() => onSubmitReply()}
                  >
                      Send
                  </Button>
              </div>
          </Grid>
      </Grid>
  );
};

const MessagesView = ({match}) => {
  // const data = useSelector((state) => state.ticket.active);
  const [refreshMessage,setRefreshMessage]= useState(false)
  const [messages, setMessages] = useState({});
  const [taskId,setTaskId] = useState()
  const [messageList,setMessageList]=useState([])
  const [loadingMessage,setLoadingMessage]= useState(true)
  const [replyMessage, setReplyMessage] = useState('');
  const [dataTaskList,setDataTaskList] = useState([])
  const [items,setItems] = useState([{owner:{role:10,first_name:"test",last_name:"okko"},message:"11qqq qsq"}])
  const { t } = useTranslation();
  const classes = useStyles();
  const [data, setData] = useState([]);
  useEffect(() => {
    api
      .create({
        controller: "tasks/tasks_by_email",
        data: { id: match.params.email },
      })
      .then((res) => {
        setDataTaskList(res.data);
        if(res.data.length>0){
            setTaskId(res.data[0].id)
        }
      });
  }, []);
  useEffect(()=>{
        setTimeout(()=>{
            if(taskId){
                api.getAll(`messages/messages_by_task/${taskId}`).then(result=>{
                    setRefreshMessage(false)
                    setMessageList(result)
                    setLoadingMessage(false)
                })
            }
            else{
                setLoadingMessage(false)
            }
        },300)
  },[taskId,refreshMessage])
  const messageSendToOtherTask = (e)=>{
    api
    .create({
      controller: "messages/message_to_other_task",
      data: { message_id: e.message_id,new_task:e.new_task },
    })
    .then((res) => {
        setRefreshMessage(true)
    });
  }
if (!messages) return <div>Not Found</div>;
  return (
    <React.Fragment>
        <PageTitle title={t("user_fields.user_list")} button={
          <Button
            component={Link}
            to="/app/users/create" 
            variant="contained"
            size="medium"
            color="secondary"
          >
            {t("user_fields.create")}
          </Button>
          } 
        />
        <div className={classes.root}>
            {taskId && (
                <TaskSelector selected={taskId} data_list={dataTaskList} onChange={(e)=>{
                setTaskId(dataTaskList.filter(tsk=>tsk.id==e)[0].id);
                setLoadingMessage(true);
                }} />
            )}
            {loadingMessage ? (<div>Loading</div>):
            
            <Grid container className={classes.chatSection}>
                <Grid item xs={12}>
                    <InputText
                        maxWidth={'96%'}
                        onFooterMultiline={() => (
                            <ReplyFooter
                                ticket_id={match.params.id}
                                message={replyMessage}
                            />
                        )}
                        value={replyMessage}
                        onChange={(e) =>
                            setReplyMessage(e.target.value)
                        }
                        id="message"
                        label="Type Something"
                        multiline
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    {!taskId && !loadingMessage && (<div>Task Not Found</div>)}
                    {messageList.length<1 && !loadingMessage && (<div>Not Found</div>)}
                    {messageList && messageList.length > 0 && (
                        <List className={classes.messageArea}>
                            {messageList.map((item, key) => {
                                // if (item.from == "test_imap@dimit.host") {
                                    return (
                                        <MessageRight
                                            key={key}
                                            task_selected={taskId} 
                                            task_list={dataTaskList}
                                            message={item.body}
                                            sendToOtherTask={e=>messageSendToOtherTask({message_id:item.id,new_task:e})}
                                            timestamp={moment(
                                                item.date,
                                            ).fromNow()}
                                            photoURL="https://lh3.googleusercontent.com/a-/AOh14Gi4vkKYlfrbJ0QLJTg_DLjcYyyK7fYoWRpz2r4s=s96-c"
                                            displayName={`${item.from}`}
                                            avatarDisp={true}
                                        />
                                    );
                            })}
                        </List>
                    )}
                </Grid>
            </Grid>
            }
        </div>
    </React.Fragment>
  );
};
export default MessagesView;
