import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FormControl } from "../../components/Forms";
import { ListViewPagination } from "../../components/ListView/ListViewPagination";
import { MessageFields } from "../../context/Forms";
import PageTitle from "../../components/PageTitle/PageTitle";
import { GridTable } from "../../components/GridView";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { deepOrange } from "@material-ui/core/colors";
import { MailOutline, Cached } from "@mui/icons-material";
import ImageGallery from "./components/ImageGallery";

import { Button, Divider, Typography, Chip, Grid } from "@material-ui/core";

import { useSelector } from "react-redux";
import List from "@material-ui/core/List";
import { InputText } from "../../components/Forms";
import { Link } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { ListView } from "../../components/ListView";
import { api } from "../../services/api";
const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  table: {
    minWidth: 650,
  },
  cellWidth: {
    width: "30%",
  },
});

const ArchivedMessage = ({ match }) => {
  // const data = useSelector((state) => state.ticket.active);
  const [listViewPage, setListViewPage] = useState(1);
  const [count, setCount] = useState(false);
  const [refreshMessage, setRefreshMessage] = useState(false);
  const [messages, setMessages] = useState({});
  const [taskId, setTaskId] = useState();
  const [messageList, setMessageList] = useState([]);
  const [loadingMessage, setLoadingMessage] = useState(true);
  const [replyMessage, setReplyMessage] = useState("");
  const [dataTaskList, setDataTaskList] = useState([]);
  const { t } = useTranslation();
  const classes = useStyles();
  const [data, setData] = useState([]);
  useEffect(() => {
    setTimeout(() => {
      api
        .getList({
          controller: "messages/find_archived",
          page: listViewPage,
        })
        .then((result) => {
          setRefreshMessage(false);
          setMessageList(result.list);
          setCount(result.count);
          setLoadingMessage(false);
        });
    }, 300);
  }, [refreshMessage, listViewPage]);
  if (!messages || messages.length < 1) return <div>Not Found</div>;

  return (
    <React.Fragment>
      <PageTitle
        title={t("user_fields.user_list")}
        button={
          <Button
            component={Link}
            to="/app/users/create"
            variant="contained"
            size="medium"
            color="secondary"
          >
            {t("user_fields.create")}
          </Button>
        }
      />
      <div className={classes.root}>
        {loadingMessage ? (
          <div>Loading</div>
        ) : (
          <Grid container className={classes.chatSection}>
            <Grid item xs={12}>
              {messageList.length < 1 && !loadingMessage && (
                <div>Not Found</div>
              )}
              {messageList && messageList.length > 0 && (
                <List className={classes.messageArea}>
                  {messageList.map((item, key) => {
                    if (item.from == "test_imap@dimit.host") {
                      return (
                        <MessageRight
                          key={key}
                          incoming={item}
                          setRefreshMessage={setRefreshMessage}
                          task_selected={taskId}
                          task_list={dataTaskList}
                          message={item.body}
                          timestamp={moment(item.date).fromNow()}
                          photoURL="https://lh3.googleusercontent.com/a-/AOh14Gi4vkKYlfrbJ0QLJTg_DLjcYyyK7fYoWRpz2r4s=s96-c"
                          displayName={`${item.from}`}
                          avatarDisp={true}
                        />
                      );
                    } else {
                      return (
                        <Grid item key={key}>
                          <MessageRight
                            key={key}
                            incoming={item}
                            setRefreshMessage={setRefreshMessage}
                            task_selected={taskId}
                            task_list={dataTaskList}
                            message={item.body}
                            // sendToOtherTask={e=>messageSendToOtherTask({message_id:item.id,new_task:e})}
                            timestamp={moment(item.date).fromNow()}
                            photoURL="https://lh3.googleusercontent.com/a-/AOh14Gi4vkKYlfrbJ0QLJTg_DLjcYyyK7fYoWRpz2r4s=s96-c"
                            displayName={`${item.from}`}
                            avatarDisp={true}
                          />
                        </Grid>
                      );
                    }
                  })}
                </List>
              )}
              {!loadingMessage && (
                <ListViewPagination
                  handleOnChange={(e) => {
                    setListViewPage(e);
                  }}
                  total={count}
                  page={listViewPage}
                />
              )}
            </Grid>
          </Grid>
        )}
      </div>
    </React.Fragment>
  );
};
export const MessageRight = (props) => {
  const [expanded, setExpanded] = React.useState(false);

  const [data, setData] = useState(props.incoming);
  const message = props.message ? props.message : "no message";
  const timestamp = props.timestamp ? props.timestamp : "";
  const displayName = props.displayName ? props.displayName : "名無しさん";
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const classes = useStyles();
  return (
    <Accordion
      expanded={expanded === "panel1"}
      onChange={handleChange("panel1")}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography sx={{ width: "33%", flexShrink: 0 }}>
          {data.subject}
        </Typography>
        <Typography sx={{ color: "text.secondary" }}>
          - {displayName}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div dangerouslySetInnerHTML={{ __html: message }} />
        <Divider style={{ marginTop: 8 }} />
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={8} lg={8} sm={8}>
            <Chip
              style={{ margin: 6 }}
              icon={<MailOutline />}
              color="primary"
              label={displayName}
            />
            <Chip label={timestamp} size="small" />
            {data.attachment && (
              <Chip
                icon={<Cached />}
                label="Attached"
                size="small"
                onClick={() =>
                  api
                    .getAll(
                      `messages/download_attachment/${props.incoming.imap_id}`
                    )
                    .then((result) => setData(result))
                }
                color={"primary"}
                style={{ margin: 6 }}
              />
            )}
          </Grid>
          <Grid item xs={12} lg={2} sm={2} md={2}>
            <Button
              variant="text"
              onClick={(e) =>
                api
                  .getOne({ controller: "messages/to_incoming", id: data.id })
                  .then((result) => props.setRefreshMessage(true))
              }
            >
              To Incoming
            </Button>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            {data.attachment == true && data.files && (
              <ImageGallery preview={false} data={data.files} />
            )}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default ArchivedMessage;
