import React, { useEffect, useState, useRef } from "react";
import CameraPhoto, {
  FACING_MODES,
  IMAGE_TYPES,
} from "jslib-html5-camera-photo";

import { useTranslation } from "react-i18next";
import {
  Button,
  Grid,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import {
  InputRadio,
  InputText,
  FormControl,
  FileUploadInput,
} from "../../../components/Forms";
import { useSelector, useDispatch } from "react-redux";
import { AddNewTask } from "../../../redux/actions/action";
import { task } from "../../../redux/store";
import {
  add,
  onChangeMarker,
  onAddInventory,
} from "../../../redux/features/tasks";
import ImageCameraSwitch from "./ImageCameraSwitch";
import axios from "axios";
import dotenv from "react-dotenv";
import Camera from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
// import {encode, decode} from 'node-base64-image';
import qs from "qs";
// var base64Img = require('base64-img');
let videoElement = document.getElementsByTagName("video");

export default function FormDialog({ status, handleClose, data }) {
  let cameraPhoto = new CameraPhoto(videoElement);
  const { t } = useTranslation();
  const [title, setTitle] = useState("");
  const [amount, setAmount] = useState(0);
  const [room, setRoom] = useState("");
  const [gallery, setGallery] = useState([]);
  const [pictures, setPictures] = useState([]);
  const inventory = useSelector((state) => state.task.new.inventory);
  const [imageOrcamera, setImageOrCamera] = useState(true);
  const dispatch = useDispatch();
  var localVideoref = useRef(null);
  useEffect(() => {
    var constraints = {
      video: true,
      audio: true,
    };
    async function getMedia(constraints) {
      navigator.mediaDevices
        .enumerateDevices()
        .then((result) => console.log(result));

      let stream = null;
      try {
        stream = await navigator.mediaDevices.getUserMedia(constraints);
        // console.log(stream.getAudioTracks()[0].getCapabilities()) ;
        localVideoref.current.srcObject = stream;
        localVideoref.current.muted = true;
      } catch (err) {
        /* handle the error */
      }
    }

    getMedia(constraints);
  }, []);

  useEffect(() => {
    if (data) {
      const tmpData = inventory.filter((item) => item.title == data);
      if (tmpData.length > 0) {
        setTitle(tmpData[0].title);
        setAmount(tmpData[0].amount);
        setRoom(tmpData[0].room);
        setPictures(tmpData[0].pictures);
      }
    }
  }, [data]);
  const onChange = (e) => {
    if (e.target.id === "title") {
      setTitle(e.target.value);
    } else if (e.target.id === "amount") {
      setAmount(e.target.value);
    } else if (e.target.id === "room") {
      setRoom(e.target.value);
    }
  };
  const submit = (e) => {
    dispatch(
      onAddInventory({
        title: title,
        amount: amount,
        room: room,
        pictures: pictures,
      })
    );
    if (data) {
      handleClose();
    } else {
      setTitle("");
      setAmount(1);
      setRoom("");
    }
  };
  const handleTakePhoto = (dataUri) => {
    // Do stuff with the photo...
    const data = { base64: dataUri.split("data:image/png;base64,")[1] };
    const options = {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      data: qs.stringify(data),
      url: `${dotenv.base_url}/tasks/uploadbase64`,
    };
    axios(options)
      .then((res) => {
        if (res.data) {
          const url = res.data;
          setPictures([...pictures, res.data]);
        }
      })
      .catch((err) => {});

    // axios.post(, formData,{
    //     headers: {
    //         authorization: `bearer ${localStorage.getItem("id_token")}`
    //     },
    //     body:{
    //         base64:
    //     }
    // }).then(res=>{
    //         if(res.data){
    //             const url=res.data
    //             setPictures([...pictures,res.data])
    //         }
    //     }).catch((err) => {
    //         console.log("error upload logo")
    //     });

    // const imageFile = decode(dataUri, { fname: 'example', ext: 'png' });
    // console.log(imageFile)
  };

  const onSubmitImage = ({ image_field, images }) => {
    const formData = new FormData();
    images.map((item) => {
      formData.append("images", item.file);
    });
    formData.append("image_field", image_field);
    axios
      .post(`${dotenv.base_url}/tasks/upload_files`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: `bearer ${localStorage.getItem("id_token")}`,
        },
      })
      .then((res) => {
        if (res.data) {
          const url = res.data;
          setPictures([...pictures, res.data]);
        }
      })
      .catch((err) => {});
  };
  return (
    <Dialog maxWidth={"md"} open={status} onClose={handleClose}>
      {JSON.stringify(localVideoref)}
      <DialogTitle>Add Inventory</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} lg={12} md={12}>
            <InputText
              value={title}
              id="title"
              onChange={(e) => onChange(e)}
              label={t("dialog.title")}
            />
            <InputText
              value={amount}
              id="amount"
              type={"number"}
              onChange={(e) => onChange(e)}
              label={t("dialog.amount")}
            />
            <InputText
              value={room}
              id="room"
              onChange={(e) => onChange(e)}
              label={t("dialog.room")}
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={12} md={12}>
            <ImageCameraSwitch
              checked={imageOrcamera}
              onChange={() => setImageOrCamera(!imageOrcamera)}
            />
            {!imageOrcamera && (
              <FileUploadInput
                value={pictures}
                id="pictures"
                onChange={(e) => {
                  onSubmitImage(e);
                }}
              />
            )}
            {imageOrcamera && (
              <Camera
                ref={localVideoref}
                idealFacingMode="user"
                onTakePhoto={(dataUri) => {
                  handleTakePhoto(dataUri);
                }}
              />
            )}
          </Grid>
          <Grid item xs={12} md={12} lg={12} sm={12}>
            {pictures && (
              <div>
                {pictures.map((item) => (
                  <img
                    style={{ maxHeight: 120, maxWidth: 120 }}
                    src={item}
                    srcSet={item}
                    loading="lazy"
                  />
                ))}
              </div>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t("dialog.cancel")}</Button>
        <Button onClick={submit}>{t("dialog.add")}</Button>
      </DialogActions>
    </Dialog>
  );
}
