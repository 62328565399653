import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  Select,
  MenuItem,
  ListItemText,
  ListItemIcon,
  InputLabel,
  Checkbox,
} from "@material-ui/core";
import {
  InputRadio,
  InputText,
  FormControl,
  Input,
  InputSelect,
} from "../../../../components/Forms";
import { useSelector, useDispatch } from "react-redux";
import { task } from "../../../../redux/store";
import { setRoleStatus, setData } from "../../../../redux/features/user";
import { updateField } from "../../../../redux/features/tasks";
import { api } from "../../../../services/api";
import TitleComponent from "../TitleComponent";
import { VolunteerActivismOutlined, NavigateNext } from "@mui/icons-material";
import { MenuProps, useStyles, options } from "./utils";
import { useTranslation } from "react-i18next";
const ChangePasswordComp = (prop) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.value);
  const [editable, setEditable] = useState(false);
  const [old_password, setOldPassword] = useState("");
  const [new_password, setNewPassword] = useState("");
  const classes = useStyles();
  const { t } = useTranslation();
  const onSave = () => {
    console.log(prop);
    api.create(
      {
        controller: "user/change_password",
        id: user.user_id,
        data: { old_password: old_password, new_password: new_password },
      },
      (res) => {}
    );
  };
  return (
    <Grid container>
      <TitleComponent
        title={"Personal Info"}
        setEditable={() => setEditable(!editable)}
        editable={editable}
      />
      <Grid item xs={10} lg={10} sm={10} md={10}>
        <Grid container>
          <Grid item xs={12} sm={12} lg={6} md={6}>
            <InputText
              disabled={!editable}
              label={t("profile_page.current_password")}
              onChange={(e) => setOldPassword(e.target.value)}
              id="old_password"
              value={old_password}
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={6} md={6}>
            <InputText
              disabled={!editable}
              label={t("profile_page.new_password")}
              onChange={(e) => setNewPassword(e.target.value)}
              id="new_password"
              value={new_password}
            />
          </Grid>
        </Grid>
      </Grid>
      {editable && (
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Button onClick={() => onSave()}>{t("profile_page.save")}</Button>
        </Grid>
      )}
    </Grid>
  );
};
export default ChangePasswordComp;
