import React, { useState } from "react";
import { DateRangePicker, DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

import i18n from "i18next";
import {
  Chip,
  Paper,
  Button,
  Tooltip,
  Grid,
  FormControlLabel,
  Box,
  Switch,
  Dialog,
  DialogContent,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  Business,
  PersonPin,
  LocalShipping,
  Coronavirus,
  EmojiPeople,
  Loop,
  Edit,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { ListView } from "../../../components/ListView";
///start
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const possibility_to_attach_warning_signs_colors = [
  "disabled",
  "warning",
  "error",
  "success",
  "secondary",
  "primary",
];
const services_list = [
  "move",
  "demontage",
  "montage",
  "entsorgung",
  "einpackservice",
  "auspackservice",
  "ausmalen",
  "reinigung",
];
const TasksFields = [
  {
    type: "refrence",
    label: i18n.t("events.id"),
    id: "id",
    onlyLink: true,
    show_list: false,
  },
  {
    type: "select",
    label: i18n.t("tasks.status"),
    id: "status",
    show_list: true,
    data: [
      { value: 1, text: i18n.t("tasks_status.created") },
      { value: 2, text: i18n.t("tasks_status.pending") },
      { value: 3, text: i18n.t("tasks_status.accepted") },
      { value: 4, text: i18n.t("tasks_status.doing") },
      { value: 5, text: i18n.t("tasks_status.done") },
      { value: 6, text: i18n.t("events.on_hold") },
      { value: 7, text: i18n.t("tasks_status.cancelled") },
      { value: 8, text: i18n.t("tasks_status.undefined") },
    ],
  },
  {
    type: "text",
    label: i18n.t("events_table.customer_info"),
    id: "name",
    grid: { xs: 12, sm: 12, md: 12, lg: 12 },
    show_list: true,
    format: (e) => (
      <span>
        {e.title && `${e.title} -`} {e.name} {e.phone}
      </span>
    ),
  },
  {
    type: "text",
    label: i18n.t("tasks.email"),
    id: "email",
    grid: { xs: 12, sm: 12, md: 12, lg: 12 },
    show_list: true,
  },
  {
    type: "text",
    label: i18n.t("tasks.price"),
    id: "price",
    grid: { xs: 12, sm: 12, md: 12, lg: 12 },
    show_list: true,
    format: (e) => `${e.price} €`,
  },
  {
    type: "text",
    label: i18n.t("events_table.services"),
    id: "umzugsplan_id",
    show_list: true,
    grid: { xs: 12, sm: 12, md: 12, lg: 12 },
    format: (e) => (
      <span>
        {services_list
          .map((item) => e[item] && item)
          .filter((item) => item !== false).length > 1
          ? services_list
              .map((item) => e[item] && item)
              .filter((item) => item !== false)
              .map((item, key) => {
                if (
                  key <
                  services_list
                    .map((item) => e[item] && item)
                    .filter((item) => item !== false).length -
                    1
                )
                  return (
                    i18n.t(`services.${item}`) +
                    " " +
                    i18n.t("services.and") +
                    " "
                  );
                else return item;
              })
          : "None"}
      </span>
    ),
  },
  {
    type: "text",
    id: "destination",
    grid: { xs: 12, sm: 12, md: 12, lg: 12 },
    label: i18n.t("events_table.destination_address"),
    show_list: true,
    format: (e) =>
      e.destination.map((item, key) => (
        <Grid container justifyContent="center" alignItems="center">
          <Grid item>
            <WarningAmberIcon
              color={
                possibility_to_attach_warning_signs_colors[
                  parseInt(e.origin_possibility_to_attach_warning_signs - 1)
                ]
              }
            />
          </Grid>
          <Grid item>
            {item.transportation && (
              <Chip
                icon={<Loop />}
                // onDelete={(e) => console.log("test")}
                label={item.transportation}
              />
            )}
          </Grid>
          <Grid item>
            {item.total_truck && (
              <Chip icon={<LocalShipping />} label={item.total_truck} />
            )}
          </Grid>
          <Grid item>
            {item.destination_plaque} - {item.destination_floor} {item.address}{" "}
            {key < e.destination.length - 1 && i18n.t("services.and")}{" "}
          </Grid>
        </Grid>
      )),
  },
  {
    type: "text",
    id: "origin_address",
    grid: { xs: 12, sm: 12, md: 12, lg: 12 },
    label: i18n.t("events_table.origin_address"),
    show_list: true,
    format: (e) => (
      <span style={{ display: "flex", alignItems: "center" }}>
        <WarningAmberIcon
          color={
            possibility_to_attach_warning_signs_colors[
              parseInt(e.origin_possibility_to_attach_warning_signs - 1)
            ]
          }
        />
        {e.origin_plaque && e.origin_floor
          ? `${e.origin_plaque}  - ${e.origin_floor} `
          : ""}
        {e.origin_address}
      </span>
    ),
  },

  {
    type: "text",
    id: "services",
    grid: { xs: 12, sm: 12, md: 12, lg: 12 },
    label: i18n.t("events_table.origin_address"),
    show_list: true,
    format: (e) => <div>eee</div>,
  },
];
const ReadMore = ({ short, show_btn, content, title, limit }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <React.Fragment>
      {short
        ? short
        : content
            .split(" ")
            .slice(0, limit)
            .map((item) => item + " ")}
      <Button onClick={handleOpen}>
        {show_btn.text ? show_btn.text : "..."}
      </Button>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <DialogContent sx={{ width: 400 }}>
          {title && <h2 id="parent-modal-title">{title}</h2>}
          {content}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};
const NoneStyleEvents = (props) => {
  const { t } = useTranslation();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [descriptions, setDescriptions] = useState([]);
  const [dateRange, setDateRang] = useState([null, null]);
  const { meetings } = props;
  const [enableSetRang, setEnableSetRang] = useState(false);

  const status_vals = [
    t("tasks_status.created"),
    t("tasks_status.pending"),
    t("tasks_status.accepted"),
    t("tasks_status.doing"),
    t("tasks_status.done"),
    t("tasks_status.on_hold"),
    t("tasks_status.cancelled"),
  ];
  function get_random(list) {
    return list[Math.floor(Math.random() * list.length)];
  }
  const status_color = [
    {
      value: 1,
      text: i18n.t("tasks_color.lila"),
      hex: "#BA55D3",
      color_name: "MediumOrchid",
    },
    {
      value: 2,
      text: i18n.t("tasks_color.orange"),
      hex: "#FFA500",
      color_name: "Orange",
    },
    {
      value: 3,
      text: i18n.t("tasks_color.gray"),
      hex: "#D3D3D3",
      color_name: "LightGray",
    },
    {
      value: 4,
      text: i18n.t("tasks_color.dark_green"),
      hex: "#3CB371",
      color_name: "MediumSeaGreen",
    },
    {
      value: 5,
      text: i18n.t("tasks_color.blue"),
      hex: "#B0C4DE",
      color_name: "LightSteelBlue",
    },
    {
      value: 6,
      text: i18n.t("tasks_color.yellow"),
      hex: "#F0E68C",
      color_name: "Khaki",
    },
    {
      value: 7,
      text: i18n.t("tasks_color.green"),
      hex: "#32CD32",
      color_name: "LimeGreen",
    },
    {
      value: 8,
      text: i18n.t("tasks_color.red"),
      hex: "#CD5C5C",
      color_name: "IndianRed",
    },
    {
      value: 9,
      text: i18n.t("tasks_color.dark_blue"),
      hex: "",
      color_name: "MidnightBlue",
    },
  ];

  function dataFormat(result) {
    const data = {
      id: result.id,
      services: Object.entries(result)
        .filter((item) => item[1] === true)
        .map((item) => t(`services.${item[0]}`)),
      time: {
        status: result.status,
        meeting_time: result.meeting_time,
        start_time: result.start_time,
        end_time: result.end_time,
      },
      customer_info: {
        contract_type: result.contract_type,
        title: result.title,
        name: result.name,
        company_name: result.company_name,
        company_title: result.company_title,
        phone: result.phone,
      },
      price: result.price,
      createdAt: result.createdAt,
      origin_address: {
        origin_address: result.origin_address,
        possibility_to_attach_warning_signs:
          result.origin_possibility_to_attach_warning_signs,
      },
      destination: result.destination.map((item) => {
        return {
          address: item.address,
          possibility_to_attach_warning_signs:
            item.possibility_to_attach_warning_signs,
        };
      }),
      status: result.status,
    };
    const density = 12;
    return { ...data };
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const onChangeDescriptionSwitch = (e) => {
    if (descriptions.filter((item) => item === e).length < 1) {
      setDescriptions((prevState) => [...prevState, e]);
    } else {
      const temp = descriptions.filter((item) => item !== e);
      setDescriptions(temp);
    }
  };
  return (
    <Paper sx={{ width: "100%", overflow: "hidden", padding: "5px" }}>
      <Grid container>
        <Grid item xs={12} md={12} lg={12} sm={12}>
          <Button onClick={() => setEnableSetRang(!enableSetRang)}>
            {enableSetRang ? "Hidden" : "Show"} Date Rang
          </Button>
        </Grid>
        <Grid item xs={12} md={12} lg={12} sm={12}>
          {enableSetRang && (
            <DateRange
              editableDateInputs={true}
              onChange={(item) => props.onChangeDateRange([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={[
                {
                  startDate: new Date(props.dateRange[0].startDate),
                  endDate: new Date(props.dateRange[0].endDate),
                  key: "selection",
                },
              ]}
            />
          )}
        </Grid>
        {meetings.length < 1 && <Grid item>{t("not_found")}</Grid>}
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{i18n.t("global_fields.date")}</TableCell>
                <TableCell>{i18n.t("global_fields.time")}</TableCell>
                <TableCell align="left">
                  {i18n.t("global_fields.name")}
                </TableCell>
                <TableCell align="left">
                  {i18n.t("global_fields.origin_address")}
                </TableCell>
                <TableCell align="left">
                  {i18n.t("global_fields.destination")}
                </TableCell>
                <TableCell align="left">
                  {i18n.t("global_fields.phone")}
                </TableCell>
                <TableCell align="left">
                  {i18n.t("global_fields.email")}
                </TableCell>
                <TableCell align="left">
                  {i18n.t("global_fields.description")}
                </TableCell>
                <TableCell align="left">
                  {i18n.t("view_task.workers")}
                </TableCell>
                <TableCell align="left">{i18n.t("view_task.price")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {meetings.map((row) => (
                <React.Fragment key={row.name}>
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    style={{
                      backgroundColor: row.status_color
                        ? status_color[row.status_color - 1].hex
                        : "white",
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {moment(row.start_time).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell>
                      {moment(row.start_time).format("HH:mm")}
                    </TableCell>
                    <TableCell align="left">
                      <Link to={`tasks/view/${row.id}`}>
                        {row.name || row.company_name}
                      </Link>
                    </TableCell>
                    <TableCell>
                      {row.origin_address.split(" ").length < 3 ? (
                        row.origin_address
                      ) : (
                        <ReadMore
                          show_btn={true}
                          content={row.origin_address}
                          limit={3}
                          short={row.origin_address
                            .split(" ")
                            .slice(-2)
                            .map((it) => it)}
                          title="Origin Address"
                        />
                      )}
                      {/* <ReadMore
                          show_btn={true}
                          content={row.origin_address}
                          short={row.origin_address
                            .split(" ")
                            .slice(-2)
                            .map((it) => it)}
                          limit={3}
                          title="Origin Address"
                        /> */}
                    </TableCell>
                    <TableCell align="left">
                      {/* {row.destination.length < 2
                        ? row.destination.map((item) => item.address + ", ")
                        : "ReadMore"} */}
                      {row.destination.map((ii) => ii.address + " ")}
                      <ReadMore
                        show_btn={true}
                        content={
                          row.length > 0
                            ? row.destination.map(
                                (item) => item.address + "<br />"
                              )
                            : ""
                        }
                        limit={3}
                        title="Destination Address"
                      />
                    </TableCell>
                    <TableCell align="left">
                      <Link to={`tasks/view/${row.id}`}>{row.phone}</Link>
                    </TableCell>
                    <TableCell align="left">
                      <Link to={`tasks/view/${row.id}`}>{row.email}</Link>
                    </TableCell>
                    <TableCell align="left">
                      {row.description.length < 25 ? (
                        row.description
                      ) : (
                        <ReadMore
                          show_btn={true}
                          content={row.description}
                          limit={5}
                          title={i18n.t("global_fields.description")}
                        />
                      )}

                      {/*  */}
                    </TableCell>
                    <TableCell align="left">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        {row.people && (
                          <Chip icon={<EmojiPeople />} label={row.people} />
                        )}

                        {row.total_truck && row.total_truck.length > 0 && (
                          <Chip
                            icon={<LocalShipping />}
                            label={row.total_truck}
                          />
                        )}
                      </div>
                      {row.workers_id.length > 0 && (
                        <ReadMore
                          show_btn={true}
                          content={row.workers_id.map((item, key) => (
                            <span key={key}>{`${item.full_name}, `}</span>
                          ))}
                          short={row.workers_id
                            .map((item) => item.full_name + ", ")
                            .toString()}
                          limit={5}
                          title={i18n.t("view_task.worker_list")}
                        />
                      )}
                    </TableCell>

                    <TableCell align="left">{row.price}</TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Paper>
  );
};
export { NoneStyleEvents };
