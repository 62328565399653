import React, { useState, useCallback, useEffect } from "react";
import { StyledFormControl, StyledInputLabel } from "../StyledComponents";
import { useDropzone } from "react-dropzone";
import useStyles from "./styles";
import ImageUploading from "react-images-uploading";
import clsx from "clsx";
const FileUploadInput = ({
  id,
  label,
  variant,
  className,
  style,
  defaultValue,
  disabled,
  onChange,
  otherOptions,
  value,
  filesLimit,
}) => {
  const [images, setImages] = useState([]);
  const maxNumber = 69;
  useEffect(() => {
    setImages(value);
  }, [value, id]);
  const onDrop = useCallback((acceptedFiles) => {
    onChange({ image_field: id, images: acceptedFiles });
  }, []);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
  });
  const onChangeLocal = (imageList, addUpdateIndex) => {
    // data for submit
    onChange({ image_field: id, images: imageList });
    setImages(imageList);
  };
  const classes = useStyles();
  // const Empty = () => {
  //   if (value) {
  //     if (value.length < 1) {
  //       return <div>Empty</div>;
  //     } else {
  //       return <div></div>;
  //     }
  //   } else {
  //     return <div></div>;
  //   }
  // };
  return (
    <StyledFormControl className={clsx(classes.margin, classes.minFormControl)}>
      <StyledInputLabel
        variant="outlined"
        className={classes.labelStyle}
        htmlFor="bootstrap-input"
      >
        {label}
      </StyledInputLabel>
      <ImageUploading
        multiple
        value={images}
        onChange={onChangeLocal}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <div className={classes.wrapper}>
            <section className={classes.upload_content}>
              {!disabled && (
                <p
                  onClick={onImageUpload}
                  {...dragProps}
                  style={isDragging ? { color: "red" } : undefined}
                >
                  Drag 'n' drop some files here, or click to select files
                </p>
              )}
            </section>
            {/* {value && (
                <div>{value.map(item=>(
                    <img
                      style={{maxHeight:120,maxzWidth:120}}
                      src={item}
                      srcSet={item}
                      loading="lazy"
                    />
                ))}</div>
            )} */}
            {/* {Empty()} */}
            &nbsp;
          </div>
        )}
      </ImageUploading>
      {/* <section className={classes.upload_content}>
        <div {...getRootProps({className: 'dropzone'})}>
          <input {...getInputProps()} />
          <p>Drag 'n' drop some files here, or click to select files</p>
        </div>
        <aside>
          <h4>Files</h4>
          {(images && images.length<1) ? (
            <Grid item>Files not Found</Grid>
          ):files}
        </aside>
      </section> */}
    </StyledFormControl>
  );
};
export { FileUploadInput };
