import React from "react";
import { Grid } from "@material-ui/core";
// import Calendar from "react-google-calendar";
import PageTitle from "../../components/PageTitle";
import { IncomingTasksFields } from "../../context/Forms";
import { ListView } from "../../components/ListView";
import { useSelector } from "react-redux";
// import { getMessaging, getToken } from "firebase/messaging";
// import { message } from "../../firebaseInit";
// import firebase from "firebase";

export default function Dashboard(props) {
  const user = useSelector((state) => state.user.value);

  React.useEffect(() => {
    // message
    //   .requestPermission()
    //   .then(async function () {
    //     const token = await message.getToken();
    //     // Token can be sent to server from here.
    //     console.log(token);
    //   })
    //   .catch(function (err) {
    //     console.log("Unable to get permission to notify.", err);
    //   });
    // navigator.serviceWorker.addEventListener("message", (message) =>
    //   console.log(message)
    // );
  }, []);
  // const calendarProps = {
  //   APIkey: "AIzaSyDUb_he0UVZUDzi0LPT8zrTDi1G34DVKLk",
  // };
  if (!("serviceWorker" in navigator) && !("PushManager" in window))
    return (
      <div>
        Notification feature is supported only in:
        <br />
        Chrome Desktop and Mobile (version 50+)
        <br />
        Firefox Desktop and Mobile (version 44+)
        <br />
        Opera on Mobile (version 37+)
      </div>
    );
  return (
    <React.Fragment>
      <PageTitle title="Dashboard" />
      <Grid container spacing={4}>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <img src="./assets/Umzugsfirma.webp" style={{ height: "80%" }} />
          {/* <Calendar {...calendarProps} /> */}
        </Grid>
      </Grid>
      {user.role > 2 && (
        <Grid container>
          <Grid item>
            <ListView
              notFoundTitle="global_not_found"
              withPagination
              withShow
              dashboard
              external_path="cases"
              controller="incoming_task/my_cases"
              fields={IncomingTasksFields}
            />
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
}
