import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { styled, alpha } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core/styles';
import { CostsFields,CostsFieldsInTask } from '../../../context/Forms'
import PageTitle from "../../../components/PageTitle/PageTitle";
import { Button, Grid, TextField, Radio, RadioGroup, FormControlLabel, FormLabel,Chip } from "@mui/material";
import {FormControl} from "../../../components/Forms"
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { api } from "../../../services/api";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import {
  Business,
  PersonPin,
  SearchIcon as Search,
  LocalShipping,
  Coronavirus,
  EmojiPeople,
  Loop,
  Edit,
} from "@mui/icons-material";

const filter = createFilterOptions();

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  table: {
    minWidth: 650,
  },
  cellWidth: {
    width: "30%",
  },
});

const CostsCreate = (props) => {
  const classes = useStyles();
  const {t} = useTranslation();
  const [defaultData,setDefaultData] = useState({})
  const user = useSelector(state => state.user.value)
  const view = useSelector((state) => state.task.view);
  const [fields,setFields] = useState(props.inTask? CostsFieldsInTask: CostsFields)
  // const [fields,setFields] = useState(CostsFields.filter(item=>item.id!=="paid_date").filter(item=>item.id!=="worker").map(item=>{
  //   if(props.inTask && item.id=="type"){
  //     item.data = item.data.filter(item=>item.value!=="office_cost").filter(item=>item.value!=="employees_cost")
  //   }
  //   if(!props.inTask && item.id=="type"){
  //     item.data = item.data.filter(item=>item.value!=="task_cost").filter(item=>item.value!=="worker_cost")
  //   }
  //   if(props.inTask && item.id=="task"){
  //     return {
  //       type: "text",
  //       label: "Task",
  //       id: "task",
  //       disabled:true
  //     }
  //   }
  //   else if(item.id=="task"){
  //     return {
  //       type: "text",
  //       label: "Task",
  //       id: "task",
  //     }
  //   }else return item
  // }))
  useEffect(()=>{
    if(props.inTask){
      setDefaultData({task:String(props.id),type:"task_cost"})
    }
  },[props.inTask])
  const refreshForm = (e)=>{
    if(e.type==="worker_cost"){
      setFields(prevState =>([...prevState,{
        type: "select",
        linked: true,
        noneExist: true,
        label: "Worker Name",
        id: "worker",
        fetch: true,
        show_field: ["first_name", "last_name"],
        controller: "worker",
        show_view: true,
      }]))
    }else{
      setFields(prevState=>prevState.filter(item=>item.id!=="worker"))
    }
    if(e.status!=="paid"){
      setFields(prevState=>(prevState.filter(item=>item.id!=="paid_date")))
    }
    if(e.status=="paid"){
      setFields(prevState =>([...prevState,{
        type: "datepicker",
        label: t("costs.paid_date"),
        id: "paid_date",
      }]))
    }
    else if(e.type && (e.type=="office_cost" || e.type=="employees_cost")){
      setFields(prevState =>prevState.filter(item=>item.id!=="task" && item.id!=="worker"))
    }else{
      setFields(prevState=>(prevState.map(item=>{
        if(item.id=="task"){
          return {
            type: "text",
            label: "Task",
            id: "task",
            disabled:props.inTask ? true:false
          }
        }else return item
      })))
    }
  }
  return (
    <React.Fragment>
      <PageTitle title={t("menus.costs")}/>
      <div className={classes.root}>
        <Grid container>
        <Grid item xs={10} md={10} lg={10} sm={10}>
        {(props.inTask) ? (
          <FormControl default_data={defaultData} afterSave={props.afterSave?props.afterSave:false} create refreshForm={refreshForm} title="title" autoTranslation  fields={fields.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i)} model="costs" controller="costs" />
        ):(
          <FormControl default_data={{type:"office_cost"}} afterSave={props.afterSave?props.afterSave:false} create refreshForm={refreshForm} title="title" autoTranslation  fields={fields.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i)} model="costs" controller="costs" />
        )}
        </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};
export default CostsCreate;
