import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Button } from "@material-ui/core";
import { Location } from "../../../../components";
import { TasksFields, TasksSteps } from "../../steps";
import {
  InputRadio,
  InputText,
  FormControl,
} from "../../../../components/Forms";
import { useSelector, useDispatch } from "react-redux";
import { AddNewTask } from "../../../../redux/actions/action";
import { task } from "../../../../redux/store";
import { add, onChangeMarker } from "../../../../redux/features/tasks";
import Dialog from "../Dialog";
import { GridTable } from "../../../../components/GridView";
import { Edit, Add } from "@material-ui/icons";
import { ListView } from "../../../../components/ListView";
const InventoryComp = () => {
  const { t } = useTranslation();
  const Fields = [
    {
      type: "hidden",
      label: t("inventory.id"),
      id: "id",
      name: "122323",
    },
    {
      type: "text",
      label: t("inventory.title"),
      id: "title",
      show_list: true,
      name: "122323",
    },
    {
      type: "text",
      label: t("inventory.amount"),
      id: "amount",
      disable_link: true,
      show_list: true,
    },
    {
      type: "text",
      label: t("inventory.room"),
      show_list: true,
      id: "room",
    },
    {
      type: "action",
      label: t("inventory.action"),
      show_list: true,
      render: (e) => (
        <div>
          <Edit
            onClick={() => {
              setData(e[0]);
              setAddNewModal(true);
            }}
          />
        </div>
      ),
    },
  ];
  const dispatch = useDispatch();
  const [Add_new_modal, setAddNewModal] = useState(false);
  const [data, setData] = useState(null);
  const new_task = useSelector((state) => state.task.new);
  return (
    <form style={{ margin: 10 }} onSubmit={(e) => console.log(e)}>
      <ListView
        fields={Fields}
        itemAction={(e) => (
          <div>
            <Button
              onClick={() => {
                setAddNewModal(true);
                setData(e.title);
              }}
            >
              Edit
            </Button>
          </div>
        )}
        localData={new_task.inventory}
        withPagination
      />
      <Button onClick={() => setAddNewModal(true)}>
        <Add />
      </Button>
      <Dialog
        status={Add_new_modal}
        data={data}
        handleClose={() => {
          setAddNewModal(false);
          setData(null);
        }}
      />
    </form>
  );
};
export default InventoryComp;
