import React, { useEffect, useState } from "react";
import { Gallery } from "../../../../components/Gallery";
import { InputText, Uploader } from "../../../../components/Forms";
import { api } from "../../../../services/api";
import {
  Button,
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export default function GalleryModal(props) {
  const [refresh, setRefresh] = React.useState(false);
  const [uploadEnable, setUploadEnable] = useState(false);
  const [filesGallery, setFilesGallery] = useState([]);
  const [fileDetail, setFileDetail] = useState({});
  useEffect(() => {
    api
      .getAll(`files/list/${props.match.params.id}`)
      .then((res) => setFilesGallery(res));
  }, [refresh]);

  return (
    <Gallery
      data={filesGallery}
      withFullScreen
      header={() => (
        <Uploader
          afterUpload={() => {
            setFileDetail({});
            setRefresh(!refresh);
          }}
          body={{
            type: "task",
            ref_id: props.match.params.id,
            ...fileDetail,
          }}
          uploadUrl="files/upload"
          footerContentBeforeUpload={() => (
            <Grid container>
              <Grid item xs={12} md={8} lg={8} sm={8}>
                <InputText
                  id={"title"}
                  autoTranslate
                  onChange={(e) =>
                    setFileDetail((prevState) => ({
                      ...prevState,
                      title: e.target.value,
                    }))
                  }
                  value={fileDetail.title}
                />
              </Grid>
              <Grid item xs={12} md={8} lg={8} sm={8}>
                <InputText
                  id={"category"}
                  autoTranslate
                  onChange={(e) =>
                    setFileDetail((prevState) => ({
                      ...prevState,
                      category: e.target.value,
                    }))
                  }
                  value={fileDetail.category}
                />
              </Grid>
            </Grid>
          )}
          urls={(e) => {
            setFilesGallery((prevState) => [...prevState, e]);
          }}
        />
      )}
    />
  );
}
