import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { deepOrange } from "@material-ui/core/colors";
import { MailOutline, Cached } from "@mui/icons-material";
import {
  Button,
  IconButton,
  Divider,
  Typography,
  Popper,
  Fade,
  Paper,
  Chip,
  Grid,
} from "@material-ui/core";
import { TaskSelector } from "./";
import { Reply } from "./";
import ImageGallery from "./ImageGallery";
import PopupState, { bindToggle, bindPopper } from "material-ui-popup-state";
import { api } from "../../../services/api";
const useStyles = makeStyles((theme) =>
  createStyles({
    messageRow: {
      display: "flex",
      justifyContent: "flex-start",
    },
    messageRowRight: {
      display: "flex",
      justifyContent: "flex-end",
    },
    messageBlue: {
      // position: "relative",
      marginLeft: "20px",
      marginBottom: "10px",
      padding: "10px",
      minHeight: 78,
      maxWidth: "85%",
      backgroundColor: "#A8DDFD",
      textAlign: "left",
      font: "400 .9em 'Open Sans', sans-serif",
      border: "1px solid #97C6E3",
      borderRadius: "0px",
    },
    messageOrange: {
      marginRight: "20px",
      marginBottom: "10px",
      minHeight: 78,
      maxWidth: "85%",
      padding: "10px",
      backgroundColor: "#f8e896",
      textAlign: "left",
      font: "400 .9em 'Open Sans', sans-serif",
      border: "1px solid #dfd087",
      borderRadius: "0px",
    },

    messageContent: {
      padding: 0,
      margin: 0,
    },
    messageTimeStampRight: {
      position: "absolute",
      fontSize: ".85em",
      fontWeight: "300",
      marginTop: "10px",
      bottom: "4px",
      right: "5px",
    },
    messageSetting: {
      position: "absolute",
      fontSize: ".85em",
      fontWeight: "300",
      marginTop: "10px",
      bottom: "4px",
      left: "5px",
    },
    incoming: {
      border: "1px solid gray",
      padding: 15,
    },
    orange: {
      color: theme.palette.getContrastText(deepOrange[500]),
      backgroundColor: deepOrange[500],
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    avatarNothing: {
      color: "transparent",
      backgroundColor: "transparent",
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    displayName: {
      marginLeft: "20px",
    },
  })
);

export const MessageLeft = (props) => {
  const message = props.message ? props.message : "no message";
  const timestamp = props.timestamp ? props.timestamp : "";
  const displayName = props.displayName ? props.displayName : "名無しさん";
  const classes = useStyles();
  return (
    <Grid container className={classes.messageBlue}>
      <Grid item xs={12} md={12} sm={12} lg={12}>
        {message}
      </Grid>
      <Grid item xs={12} md={12} sm={12} lg={12}>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={12} lg={12} sm={12}>
            {/* {timestamp} {displayName} */}
          </Grid>
          <Grid item xs={12} md={12} lg={12} sm={12}>
            <Chip icon={<MailOutline />} label="With Icon" variant="outlined" />
            <Chip
              label="Custom delete icon"
              // onClick={handleClick}
              // onDelete={handleDelete}
              deleteIcon={<MailOutline />}
              variant="outlined"
            />
            {timestamp} {displayName}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export const MessageRight = (props) => {
  const [expanded, setExpanded] = React.useState(false);

  const [data, setData] = useState(props.incoming);
  const message = props.message ? props.message : "no message";
  const timestamp = props.timestamp ? props.timestamp : "";
  const displayName = props.displayName ? props.displayName : "名無しさん";
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const classes = useStyles();
  return (
    <Accordion expanded={expanded == true} onChange={handleChange(!expanded)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} id="accr">
        {data && data.subject && (
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            {props.incoming.subject || data.subject}
          </Typography>
        )}

        <Typography sx={{ color: "text.secondary" }}>
          {" "}
          - {displayName}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div dangerouslySetInnerHTML={{ __html: message }} />
        <Divider style={{ marginTop: 8 }} />
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={8} lg={8} sm={8}>
            <Chip
              style={{ margin: 6 }}
              icon={<MailOutline />}
              color="primary"
              label={displayName}
            />
            {data && data.attachment && (
              <Chip
                icon={<Cached />}
                label="Attached"
                size="small"
                onClick={() =>
                  api
                    .getAll(
                      `messages/download_attachment/${props.incoming.imap_id}`
                    )
                    .then((result) => setData(result))
                }
                color={"primary"}
                style={{ margin: 6 }}
              />
            )}
            <Chip label={timestamp && timestamp} size="small" />
          </Grid>
          {/* {data.id} - {props.incoming.id} */}
          {data && props.incoming.id && (
            <Grid item xs={12} lg={2} sm={2} md={2}>
              <Button
                variant="text"
                onClick={(e) =>
                  api
                    .getOne({
                      controller: "messages/to_archive",
                      id: props.incoming.id,
                    })
                    .then((result) => props.setRefreshMessage(true))
                }
              >
                To Archive
              </Button>
            </Grid>
          )}
        </Grid>
        <Grid container>
          <Grid item>
            {props.incoming && props.incoming.attachment && (
              <React.Fragment>
                <ImageGallery
                  id={data.id}
                  email={props.incoming.from}
                  imap={`${props.incoming.imap_id}`}
                  // imap={`${data.from.split("@")[0]}-at-${
                  //   data.from.split("@")[1]
                  // }/${data.imap_id}`}
                  preview={false}
                />
              </React.Fragment>
            )}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={12} lg={12}>
            {data && <Reply {...props} />}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>

    // <Grid container className={data ? classes.incoming : classes.messageOrange}>
    //   <Grid item xs={12} md={12} sm={12} lg={12}>
    //     <Typography>{data.subject}</Typography>
    //     <div dangerouslySetInnerHTML={{ __html: message }} />
    //   </Grid>
    //   <Grid item xs={12} md={12} sm={12} lg={12}>
    //     <Divider style={{ marginTop: 8 }} />
    //     <Grid container spacing={2} justifyContent="space-between">
    //       <Grid item xs={12} md={12} lg={12} sm={12}>
    //         <Chip
    //           style={{ margin: 6 }}
    //           icon={<MailOutline />}
    //           color="primary"
    //           label={displayName}
    //         />
    //         <Chip label={timestamp} size="small" />
    //         {data.attachment && (
    //           <Chip
    //             icon={<Cached />}
    //             label="Attached"
    //             size="small"
    //             onClick={() =>
    //               api
    //                 .getAll(
    //                   `messages/download_attachment/${props.incoming.imap_id}`
    //                 )
    //                 .then((result) => setData(result))
    //             }
    //             color={"primary"}
    //             style={{ margin: 6 }}
    //           />
    //         )}
    //       </Grid>
    //       {data.attachment == true && data.files && (
    // <ImageGallery preview={false} data={data.files} />
    //       )}
    //        <Grid item xs={12} md={12} lg={12} sm={12}>
    //          {!data && (
    //            <TaskSelector
    //              selected={props.task_selected}
    //              data_list={props.task_list}
    //              iconStyle
    //            onChange={props.sendToOtherTask}
    //            />
    //          )}
    //          {data && <Reply {...props} />}
    // </Grid>
    //     </Grid>
    // </Grid>
    // </Grid>
  );
};
