import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { StepperTitles } from "./StepperTitles";
import FooterStepper from "./FooterStepper";
import { FormControl } from "./../";
const FormStepper = ({
  stepsData,
  fields,
  singleForm,
  afterSave,
  onFinished,
}) => {
  const steps = stepsData.map((item) => item.title);
  const [completed, setCompleted] = React.useState({});
  const [activeStep, setActiveStep] = React.useState(0);
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };
  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    onFinished();
    handleNext();
  };
  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };
  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };
  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };
  const completedSteps = () => {
    //
    return Object.keys(completed).length;
  };
  const totalSteps = () => {
    return steps.length;
  };
  return (
    <Box sx={{ width: "100%", marginTop: "32px" }}>
      <StepperTitles steps={steps} active={activeStep} />
      <div>
        {allStepsCompleted() ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {stepsData[activeStep].FormControl && (
              <FormControl
                read_only={true}
                sendAfterComplete
                fields={fields[activeStep]}
              />
            )}
            {!stepsData[activeStep].FormControl && (
              <div>
                <FormControl
                  sendAfterComplete
                  afterSaveNotify={stepsData[activeStep].after_save_notify}
                  afterSave={handleNext}
                  requirement="all"
                  singleForm={true}
                  socket={true}
                  read_only={true}
                  localStore={"profile"}
                  model={stepsData[activeStep].model}
                  fields={fields[activeStep]}
                />
              </div>
            )}
            <FooterStepper
              activeStep={activeStep}
              handleComplete={handleComplete}
              handleNext={handleNext}
              handleBack={handleBack}
              steps={steps}
            />
          </React.Fragment>
        )}
      </div>
    </Box>
  );
};
export { FormStepper };
