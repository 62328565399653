import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  Select,
  MenuItem,
  ListItemText,
  ListItemIcon,
  InputLabel,
  Checkbox,
} from "@material-ui/core";
import {
  InputRadio,
  InputText,
  FormControl,
  Input,
  InputSelect,
} from "../../components/Forms";
import { Box } from "../../components/ViewOne/StyledComponents/BoxComponent";
import { useSelector, useDispatch } from "react-redux";
import { task } from "../../redux/store";
import { setRoleStatus, setData } from "../../redux/features/user";
import { updateField } from "../../redux/features/tasks";
import { api } from "../../services/api";
import { VolunteerActivismOutlined, NavigateNext } from "@mui/icons-material";
import { MenuProps, useStyles, options } from "./utils";
import EditOne from "../../components/ViewOne/EditOne"
import { UsersFields } from "../../context/Forms";
import { useTranslation } from "react-i18next";
{/* <EditOne
loading={false}
title="title"
autoRoute
// manualWithController
autoTranslation
id={props.match.params.id}
fields={IncomingTasksFields}
controller="incoming_task"
/> */}


const EditInfoComp = (prop) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.value);
  const [editable, setEditable] = useState(false);
  const [old_password, setOldPassword] = useState("");
  const [new_password, setNewPassword] = useState("");
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <React.Fragment>
        <EditOne
            loading={false}
            title="Update Info"
            // autoRoute
            data={prop.data}
            // manualWithController
            autoTranslation
            id={prop.match.params.id}
            fields={UsersFields.filter(item=>item.type!="select")}
            controller="user/update"

        />
    </React.Fragment>        

  );
};
export default EditInfoComp;
