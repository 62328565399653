import React from "react";
import {
  Dashboard,
  TasksList,
  TasksView,
  ProfileComponent,
} from "../../../pages";
import { Route, Switch } from "react-router-dom";
const worker_routes = [
  {
    path: "/app/dashboard",
    component: Dashboard,
  },
  {
    path: "/app/tasks/list",
    component: TasksList,
    socket: true,
    socket_name: "tasks",
  },
  {
    path: "/app/users/profile",
    component: ProfileComponent,
  },
  {
    path: "/app/tasks/view/:id",
    component: TasksView,
  },
];
const WorkerRoutes = () => {
  // return([])
  return (
    <Switch>
      {worker_routes.map((item, key) => (
        <Route path={item.path} key={key} component={item.component} />
      ))}
    </Switch>
  );
};
export { WorkerRoutes, worker_routes };
