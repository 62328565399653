import React, { useEffect, useState } from "react";
import { LinearProgress } from "@mui/material";
import { StyledTableBody, StyledTableCell, StyledTableRow } from "./../StyledComponents"
import RowsView from "./RowsView";
import { useSelector } from "react-redux";
import { api } from "../../../services/api";
const GridTableBody = ({ title, data, controller, localData, page }) => {
  const [loading, setLoading] = React.useState(0);
  const [rows,setRows] = useState(null)
  const user = useSelector(state=>state.user.value)
    const tasks = useSelector(state=>state.task.view)
  React.useEffect(() => {
    setLoading(true)
    // const timer = setInterval(() => {
    //   if (loading !== 100) {
    //     setLoading((oldProgress) => {
    //       if (oldProgress < 1) return 20
    //       else if (!rows) {
    //         const diff = Math.random() * (localData ? 30 : 10);
    //         return Math.min(oldProgress + diff, 100);
    //       } else if (rows && oldProgress > 79) { return 100 }
    //     });
    //   }
    // }, 30);
    // const list = !localData ? api.getList({ controller: controller, page: page, select: data.filter(item => item.show_list).map(item => item.id).toString() }).then(res => {
    //   setRows(res)
    // }) : setRows(localData)
    // if(localData){
    //   setRows(localData)
    //   setLoading(false)
    // }
    setRows(localData)
    setLoading(false)
  }, [localData, page]);
  useEffect(()=>{
    setTimeout(()=>{
      if(user.role<3 && controller==="tasks" && rows && tasks.length!==rows.length) {window.location.reload();}
    },1000)
  },[tasks])
  return (
    <StyledTableBody>
      {loading < 100 || (!rows) ?
        <StyledTableRow>
          <StyledTableCell colSpan={data.filter(item => item.show_list).length}><LinearProgress variant="determinate" value={parseInt(loading)} /></StyledTableCell>
        </StyledTableRow> :
        <RowsView keys={data.filter(item => item.show_list)} rows={rows || localData} />}
    </StyledTableBody>
  );
};

GridTableBody.defaultProps = { title: "No Title", };

export { GridTableBody };
