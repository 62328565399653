import React from "react";
import i18n from "i18next";
import { api } from "./../../services/api";
import { FormControl } from "../../components/Forms";
import { useSelector, useDispatch } from "react-redux";
import { AddNewTask } from "../../redux/actions/action";
import { task } from "../../redux/store";
import { add } from "../../redux/features/tasks";
import {
  ContractType,
  OriginLocationComp,
  DestinationLocationComp,
  ServicesComp,
  InventoryComp,
  UmzugsplanComp,
} from "./components/Form";
const TasksFields = [
  [
    {
      type: "radio",
      label: i18n.t("events.contract_type"),
      id: "contract_type",
      hidden_form: true,
      show_list: true,
      FormComponent: () => <ContractType />,
    },
  ],
  [
    {
      type: "grid",
      label: i18n.t("events.origin_location"),
      id: "origin_location",
      hidden_form: true,
      FormComponent: () => <OriginLocationComp />,
    },
  ],
  [
    {
      type: "grid",
      label: i18n.t("events.destination_location"),
      id: "destination_location",
      hidden_form: true,
      FormComponent: () => <DestinationLocationComp />,
    },
  ],
  [
    {
      type: "grid",
      label: i18n.t("events.services"),
      id: "contract_type",
      hidden_form: true,
      FormComponent: () => <ServicesComp />,
    },
  ],
  // [
  //   {
  //     type: "grid",
  //     label: i18n.t("events.umzugsplan"),
  //     id: "umzugsplan",
  //     hidden_form: true,
  //     FormComponent: () => <UmzugsplanComp />,
  //   },
  // ],
  [
    {
      type: "grid",
      label: i18n.t("events.inventory"),
      id: "contract_type",
      hidden_form: true,
      FormComponent: () => <InventoryComp />,
    },
  ],
];
const TasksSteps = [
  {
    title: i18n.t("events.personal_info"),
    FormControl: true,
    model: "kyc/set_personal_info",
    socket: true,
    socket_store: "profile",
    after_save_notify: i18n.t("events.updated"),
    after_save: "next",
  },
  {
    title: i18n.t("events.umzug_von"),
    FormControl: true,
    model: "kyc/set_personal_info",
    socket: true,
    socket_store: "profile",
    after_save_notify: i18n.t("events.updated"),
    after_save: "next",
  },
  {
    title: i18n.t("events.umzug_nach"),
    FormControl: true,
    model: "kyc/set_personal_info",
    socket: true,
    socket_store: "profile",
    after_save_notify: i18n.t("events.updated"),
    after_save: "next",
  },
  {
    title: i18n.t("events.services"),
    model: "tasks/add",
    column: 2,
    socket: true,
    socket_store: "profile",
    after_save_notify: i18n.t("events.updated"),
    after_save: "next",
  },
  // {
  //   title: i18n.t("events.umzugsplan"),
  //   model: "tasks/add",
  //   column: 2,
  //   socket: true,
  //   socket_store: "profile",
  //   after_save_notify: i18n.t("events.updated"),
  //   after_save: "next",
  // },
  {
    title: i18n.t("events.inventory"),
    model: "tasks/add",
    column: 2,
    socket: true,
    socket_store: "tasks",
    after_save_notify: i18n.t("events.updated"),
    after_save: "next",
  },
];
export { TasksSteps, TasksFields };
