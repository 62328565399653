import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from '@material-ui/core/styles';
// import { TasksFields } from '../../context/Forms'
import PageTitle from "../../components/PageTitle/PageTitle";
import { Button, Grid, TextField,Tooltip, Switch,Radio, RadioGroup, FormControlLabel, FormControl, FormLabel,Chip } from "@mui/material";
import { ListView } from "../../components/ListView";
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import { api } from "../../services/api";
import Autocomplete from '@mui/material/Autocomplete';
import i18n from "i18next";
import moment from "moment";
import {
  Business,
  PersonPin,
  Coronavirus,
  EmojiPeople,
  Loop,
  Edit,
  LocalShipping,
  WarningAmber as WarningAmberIcon,

} from "@mui/icons-material";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  table: {
    minWidth: 650,
  },
  cellWidth: {
    width: "30%",
  },
});
const possibility_to_attach_warning_signs_colors = [
  "disabled",
  "warning",
  "error",
  "success",
  "secondary",
  "primary",
];
const services_list = [
  "move",
  "demontage",
  "montage",
  "entsorgung",
  "einpackservice",
  "auspackservice",
  "ausmalen",
  "reinigung",
];
const TasksFields = [
  {
    type: "refrence",
    label: i18n.t("events.id"),
    id: "id",
    onlyLink: true,
    show_list: false,
  },
  {
    type: "select",
    label: i18n.t("tasks.status"),
    id: "status",
    show_list: true,
    data: [
      { value: 1, text: i18n.t("tasks_status.created") },
      { value: 2, text: i18n.t("tasks_status.pending") },
      { value: 3, text: i18n.t("tasks_status.accepted") },
      { value: 4, text: i18n.t("tasks_status.doing") },
      { value: 5, text: i18n.t("tasks_status.done") },
      { value: 6, text: i18n.t("events.on_hold") },
      { value: 7, text: i18n.t("tasks_status.cancelled") },
      { value: 8, text: i18n.t("tasks_status.undefined") },
    ],
  },
  {
    type: "text",
    label: i18n.t("events_table.customer_info"),
    id: "name",
    grid: { xs: 12, sm: 12, md: 12, lg: 12 },
    show_list: true,
    format: (e) => (
      <span>
        {e.title && `${e.title} -`} {e.name} {e.phone}
      </span>
    ),
  },
  {
    type: "text",
    label: i18n.t("tasks.email"),
    id: "email",
    grid: { xs: 12, sm: 12, md: 12, lg: 12 },
    show_list: true,
  },
  {
    type: "text",
    label: i18n.t("events_table.services"),
    id: "umzugsplan_id",
    show_list: true,
    grid: { xs: 12, sm: 12, md: 12, lg: 12 },
    format: (e) => (
      <span>
        {services_list
          .map((item) => e[item] && item)
          .filter((item) => item !== false).length > 1
          ? services_list
              .map((item) => e[item] && item)
              .filter((item) => item !== false)
              .map((item, key) => {
                if (
                  key <
                  services_list
                    .map((item) => e[item] && item)
                    .filter((item) => item !== false).length -
                    1
                )
                  return (
                    i18n.t(`services.${item}`) +
                    " " +
                    i18n.t("services.and") +
                    " "
                  );
                else return item;
              })
          : "None"}
      </span>
    ),
  },
  {
    type: "text",
    id: "destination",
    grid: { xs: 12, sm: 12, md: 12, lg: 12 },
    label: i18n.t("events_table.destination_address"),
    show_list: true,
    format: (e) =>
      e.destination.map((item, key) => (
        <Grid container justifyContent="center" alignItems="center">
          <Grid item>
            <WarningAmberIcon
              color={
                possibility_to_attach_warning_signs_colors[
                  parseInt(e.origin_possibility_to_attach_warning_signs - 1)
                ]
              }
            />
          </Grid>
          <Grid item>
            {item.transportation && (
              <Chip
                icon={<Loop />}
                // onDelete={(e) => console.log("test")}
                label={item.transportation}
              />
            )}
          </Grid>
          <Grid item>
            {item.total_truck && (
              <Chip icon={<LocalShipping />} label={item.total_truck} />
            )}
          </Grid>
          <Grid item>
            {item.destination_plaque} - {item.destination_floor} {item.address}{" "}
            {key < e.destination.length - 1 && i18n.t("services.and")}{" "}
          </Grid>
        </Grid>
      )),
  },
  {
    type: "text",
    id: "origin_address",
    grid: { xs: 12, sm: 12, md: 12, lg: 12 },
    label: i18n.t("events_table.origin_address"),
    show_list: true,
    format: (e) => (
      <span style={{ display: "flex", alignItems: "center" }}>
        <WarningAmberIcon
          color={
            possibility_to_attach_warning_signs_colors[
              parseInt(e.origin_possibility_to_attach_warning_signs - 1)
            ]
          }
        />
        {e.origin_plaque && e.origin_floor
          ? `${e.origin_plaque}  - ${e.origin_floor} `
          : ""}
        {e.origin_address}
      </span>
    ),
  },

  {
    type: "text",
    id: "services",
    grid: { xs: 12, sm: 12, md: 12, lg: 12 },
    label: i18n.t("events_table.origin_address"),
    show_list: true,
    format: (e) => <div>eee</div>,
  },
];
const TasksList = (props) => {
  const classes = useStyles();
  const user = useSelector(state => state.user.value)
  const company = useSelector(state => state.user.company)
  const {t} = useTranslation();
  const [value, setValue] = React.useState(null);
  const [searchedList, setSearchedList] = useState([])
  const [searchField, setSearchField] = useState('name')
  const [blackList,setBlackList] = useState(false)
  const [descriptions, setDescriptions] = useState([]);
  const onChangeDescriptionSwitch = (e) => {
    if (descriptions.filter((item) => item === e).length < 1) {
      setDescriptions((prevState) => [...prevState, e]);
    } else {
      const temp = descriptions.filter((item) => item !== e);
      setDescriptions(temp);
    }
  };
  const onChangeSearchInput = (e) => {
    if (e.key==="Enter") {
      if(e.target.value==="Black:List:Working"){
        setBlackList(true)
      }else{
        api.getSearchedList({ controller: "tasks", where: { field: searchField, method: "startsWith", value: e.target.value } })
          .then(res => {
            const {list} = res
            setSearchedList(list.map(item => { return { id: item.id, title: item.name + " " + item.phone } }))
          })
      }
    }
  }
  return (
    <React.Fragment>
      
      <PageTitle title={t("tasks.title")} button={user.role > 2 &&
        (
          <Button component={Link} to="/app/tasks/create" variant="contained" size="medium" color="primary">
            {t("events.create")}
          </Button>
        )}
      />
      <div className={classes.root}>
        <Grid container>
          <Grid item style={{ border: '1px solid #d7dee6', borderRadius:0, margin: 10, padding: 15 }} xs={12} md={12} lg={12} sm={12}>
            <Autocomplete
              style={{ width: 'auto' }}
              value={value}
              onChange={(event, newValue) => {
                props.history.push(`./view/${newValue.id}`)
                if (typeof newValue === 'string') {
                  setValue({
                    title: newValue,
                  });
                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  setValue({
                    title: newValue.inputValue,
                  });
                } else {
                  setValue(newValue);
                }
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="search"
              options={searchedList}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.title;
              }}
              renderOption={(props, option) => <li {...props}>{option.title}</li>}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} onKeyDown={(e)=>onChangeSearchInput(e)} label={t("search")} />
              )}
            />
            <FormControl component="fieldset">
              <FormLabel component="legend">{t("events.search_with")}:</FormLabel>
              <RadioGroup row value={searchField} onChange={(e) => setSearchField(e.target.value)} aria-label="field" name="row-radio-buttons-group">
                <FormControlLabel value="name" control={<Radio />} label={t("events.name")} />
                <FormControlLabel value="company_name" control={<Radio />} label={t("events.company_name")} />
                <FormControlLabel value="phone" control={<Radio />} label={t("events.phone_number")} />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container>
        </Grid>
        {(blackList && user.role>2) && (
          <ListView autoTranslation notFoundTitle="global_not_found" withShow pagination title={"eee"} controller={"tasks/findallb"} fields={TasksFields} />
        )}




        <ListView autoTranslation
        avatar={(e)=>(<img width={64} height={64} src={e.company ==2 ?"https://dashboard.umzugsritter.at/assets/herzog.png":"https://dashboard.umzugsritter.at/assets/logo.jpg"} />)}
        headerContent={(e) => {
          const start = moment(new Date(e.start_time));
          const end = moment(new Date(e.end_time));
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <span
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  paddingBottom: 5,
                }}
              >
                <Tooltip
                  title={`${moment(e.start_time).fromNow()} - ${end.from(
                    start
                  )}`}
                  arrow
                >
                  <Chip
                    label={
                      <b>
                        {moment(e.start_time).format("DD/MM/YYYY")} -{" "}
                        {moment(e.start_time).format("HH:mm")}
                      </b>
                    }
                  />
                </Tooltip>
              </span>
              <span
                style={{
                  display: "flex",
                  marginLeft: 4,
                  marginRight: 4,
                  borderRadius: 5,
                  border: "1px solid gray",
                  alignItems: "center",
                  padding: 8,
                }}
              >
                {e.company == 2 ? "Herzog" : "Umzugs"}
              </span>
 
            </div>
          );
        }}
        footerContent={(e) => (
          <Grid justifyContent="flex-end" container spacing={2}>
            {descriptions.filter((item) => item === e.id).length > 0 && (
              <Grid item style={{ margin: 12 }} xs={12} md={12} sm={12} lg={12}>
                <Chip label={e.description} />
              </Grid>
            )}
            {e.description && e.description.length > 0 && (
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      value={
                        descriptions.filter((item) => item === e.id).length > 0
                          ? true
                          : false
                      }
                      onChange={(w) => onChangeDescriptionSwitch(e.id)}
                    />
                  }
                  label="Description"
                />
              </Grid>
            )}
                         {e.workers_id.length > 0 && (
                <Grid item xs={12} md={12} sm={12} lg={12}>
                  {e.workers_id.map((item) => (
                    <React.Fragment>{item.full_name}, </React.Fragment>
                  ))}
                </Grid>
              )}
            <Grid item>
              <Chip
                avatar={
                  <React.Fragment>
                    {e.contract_type === 1 ? <PersonPin /> : <Business />}
                    {e.pcr_test === 1 && <Coronavirus />}
                  </React.Fragment>
                }
              />
            </Grid>
            <Grid item>
              {e.people && <Chip icon={<EmojiPeople />} label={e.people} />}
            </Grid>
            <Grid item>
              {e.transportation && (
                <Chip icon={<Loop />} label={e.transportation} />
              )}
            </Grid>
            <Grid item>
              {e.total_truck && e.total_truck.length > 0 && (
                <Chip icon={<LocalShipping />} label={e.total_truck} />
              )}
            </Grid>
          </Grid>
        )}
         notFoundTitle="global_not_found" withShow withPagination controller={company && company!=="all" && user.role>2?`tasks/${company}`:'tasks'} fields={TasksFields} />



        {/* <ListView autoTranslation
        avatar={(e)=>(<img width={64} height={64} src={e.company ==2 ?"https://dashboard.umzugsritter.at/assets/herzog.png":"https://dashboard.umzugsritter.at/assets/logo.jpg"} />)}
         footerContent={(e) => (
            <Grid item>
              <Grid container>
                <Grid item>
                  <Chip
                    avatar={
                      <React.Fragment>
                        {e.contract_type === 1 ? <PersonPin /> : <Business />}
                        {e.pcr_test == 2 && <Coronavirus />}
                      </React.Fragment>
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          )} notFoundTitle="global_not_found" withShow withPagination controller={company && company!=="all" && user.role>2?`tasks/${company}`:'tasks'} fields={TasksFields} /> */}
      </div>
    </React.Fragment>
  );
};
export default TasksList;
