import React, { useState, useEffect } from "react";
import { addDays } from "date-fns";
import { DateRangePicker, DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

import i18n from "i18next";
import {
  Chip,
  Paper,
  Button,
  Tooltip,
  Grid,
  Typography,
  FormControlLabel,
  TextField,
  Box,
  Switch,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  Business,
  PersonPin,
  LocalShipping,
  Coronavirus,
  EmojiPeople,
  Loop,
  Edit,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { grey, purple } from "@mui/material/colors";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import EditIcon from "@mui/icons-material/Edit";
import { ListView } from "../../../components/ListView";
const possibility_to_attach_warning_signs_colors = [
  "disabled",
  "warning",
  "error",
  "success",
  "secondary",
  "primary",
];
const services_list = [
  "move",
  "demontage",
  "montage",
  "entsorgung",
  "einpackservice",
  "auspackservice",
  "ausmalen",
  "reinigung",
];
const TasksFields = [
  {
    type: "refrence",
    label: i18n.t("events.id"),
    id: "id",
    onlyLink: true,
    show_list: false,
  },
  {
    type: "select",
    label: i18n.t("tasks.status"),
    id: "status",
    show_list: true,
    data: [
      { value: 1, text: i18n.t("tasks_status.created") },
      { value: 2, text: i18n.t("tasks_status.pending") },
      { value: 3, text: i18n.t("tasks_status.accepted") },
      { value: 4, text: i18n.t("tasks_status.doing") },
      { value: 5, text: i18n.t("tasks_status.done") },
      { value: 6, text: i18n.t("events.on_hold") },
      { value: 7, text: i18n.t("tasks_status.cancelled") },
      { value: 8, text: i18n.t("tasks_status.undefined") },
    ],
  },
  {
    type: "text",
    label: i18n.t("events_table.customer_info"),
    id: "name",
    grid: { xs: 12, sm: 12, md: 12, lg: 12 },
    show_list: true,
    format: (e) => (
      <span>
        {e.title && `${e.title} -`} {e.name} {e.phone}
      </span>
    ),
  },
  {
    type: "text",
    label: i18n.t("tasks.price"),
    id: "price",
    grid: { xs: 12, sm: 12, md: 12, lg: 12 },
    show_list: true,
    format: (e) => `${e.price} €`,
  },
];
const AccountingTable = (props) => {
  const { t } = useTranslation();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [descriptions, setDescriptions] = useState([]);
  const [dateRange, setDateRang] = useState([null, null]);
  const { meetings } = props;
  const [enableSetRang, setEnableSetRang] = useState(false);

  const status_vals = [
    t("tasks_status.created"),
    t("tasks_status.pending"),
    t("tasks_status.accepted"),
    t("tasks_status.doing"),
    t("tasks_status.done"),
    t("tasks_status.on_hold"),
    t("tasks_status.cancelled"),
  ];
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const onChangeDescriptionSwitch = (e) => {
    if (descriptions.filter((item) => item === e).length < 1) {
      setDescriptions((prevState) => [...prevState, e]);
    } else {
      const temp = descriptions.filter((item) => item !== e);
      setDescriptions(temp);
    }
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", padding: "5px" }}>
      <Grid container>
        <Grid item xs={12} md={12} lg={12} sm={12}>
          <Button onClick={() => setEnableSetRang(!enableSetRang)}>
            {enableSetRang ? "Hidden" : "Show"} Date Rang
          </Button>
        </Grid>
        <Grid item xs={8} sm={8} md={8} lg={8}>
          <Chip
            color="success"
            label={
              <b>
                Gesamtsumme:{" "}
                {meetings
                  .map((item) => ({
                    id: item.id,
                    title: item.title,
                    name: item.name,
                    price: item.price,
                  }))
                  .reduce((total, item) => item.price + total, 0)}
                €
              </b>
            }
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12} sm={12}>
          {enableSetRang && (
            <DateRange
              editableDateInputs={true}
              onChange={(item) => props.onChangeDateRange([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={[
                {
                  startDate: new Date(props.dateRange[0].startDate),
                  endDate: new Date(props.dateRange[0].endDate),
                  key: "selection",
                },
              ]}
            />
          )}
        </Grid>
      </Grid>
      {/* {meetings.length < 1 && <Grid item>{t("not_found")}</Grid>} */}
      {/* {meetings
        .map((item) => ({
          id: item.id,
          title: item.title,
          name: item.name,
          price: item.price,
        }))
        .reduce((total, item) => item.price + total, 0)} */}
      <ListView
        localData={meetings}
        // controller={"tasks"}
        notFoundTitle="global_not_found"
        headerContent={(e) => {
          const start = moment(new Date(e.start_time));
          const end = moment(new Date(e.end_time));
          return (
            <span
              style={{
                display: "flex",
                justifyContent: "flex-start",
                paddingBottom: 5,
              }}
            >
              <Tooltip
                title={`${moment(e.start_time).fromNow()} - ${end.from(start)}`}
                arrow
              >
                <Chip
                  label={
                    <b>
                      {moment(e.start_time).format("DD/MM/YYYY")} -{" "}
                      {moment(e.start_time).format("LT")}
                    </b>
                  }
                />
              </Tooltip>
            </span>
          );
        }}
        // footerContent={(e) => (
        //   <Grid justifyContent="flex-end" container spacing={2}>
        //     {descriptions.filter((item) => item === e.id).length > 0 && (
        //       <Grid item style={{ margin: 12 }} xs={12} md={12} sm={12} lg={12}>
        //         <Chip label={e.description} />
        //       </Grid>
        //     )}
        //     {e.description && e.description.length > 0 && (
        //       <Grid item>
        //         <FormControlLabel
        //           control={
        //             <Switch
        //               value={
        //                 descriptions.filter((item) => item === e.id).length > 0
        //                   ? true
        //                   : false
        //               }
        //               onChange={(w) => onChangeDescriptionSwitch(e.id)}
        //             />
        //           }
        //           label="Description"
        //         />
        //       </Grid>
        //     )}
        //     <Grid item>
        //       <Chip
        //         avatar={
        //           <React.Fragment>
        //             {e.contract_type === 1 ? <PersonPin /> : <Business />}
        //             {e.pcr_test === 1 && <Coronavirus />}
        //           </React.Fragment>
        //         }
        //       />
        //     </Grid>
        //     <Grid item>
        //       {e.people && <Chip icon={<EmojiPeople />} label={e.people} />}
        //     </Grid>
        //     <Grid item>
        //       {e.transportation && (
        //         <Chip icon={<Loop />} label={e.transportation} />
        //       )}
        //     </Grid>
        //     <Grid item>
        //       {e.total_truck && e.total_truck.length > 0 && (
        //         <Chip icon={<LocalShipping />} label={e.total_truck} />
        //       )}
        //     </Grid>
        //     <Grid item>
        //       <Link to={`./tasks/view/${e.id}`}>
        //         <Button variant="outlined" startIcon={<Edit />}>
        //           More
        //         </Button>
        //       </Link>
        //     </Grid>
        //   </Grid>
        // )}
        fields={TasksFields}
      />
    </Paper>
  );
};
export { AccountingTable };
