import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, IconButton,Button, FormControl, OutlinedInput } from "@mui/material";
import { ListViewPagination } from "../../components/ListView/ListViewPagination";
import PageTitle from "../../components/PageTitle/PageTitle";
import { MessageRight } from './components';
import { useSelector } from 'react-redux';
import SearchIcon from "@mui/icons-material/Search";
import List from '@material-ui/core/List';
import { Link } from 'react-router-dom';
import moment from "moment"
import { useTranslation } from "react-i18next";
import { api } from '../../services/api';
const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  table: {
    minWidth: 650,
  },
  cellWidth: {
    width: "30%",
  },
});
const ReplyFooter = ({ message, ticket_id }) => {
  const classes = useStyles();
  const onSubmitReply = (e) => {
      api.create({
          controller: 'ticket/reply',
          data: { message: message, ticket_id: ticket_id },
      }).then((res) => {
          // console.log(res);
      });
  };
  return (
      <Grid container justifyContent="space-between">
          <Grid item>
              <div className={classes.StyleReplyFooterContent}>
                  <Button
                      disabled={message.length < 3}
                      onClick={() => onSubmitReply()}
                  >
                      Send
                  </Button>
              </div>
          </Grid>
      </Grid>
  );
};

const IncomingMessage = ({match}) => {
  // const data = useSelector((state) => state.ticket.active);
  const [searchInput, setSearchInput] = useState("");
  const [listViewPage, setListViewPage] = useState(1);
  const [count, setCount] = useState(false);
  const [refreshMessage,setRefreshMessage]= useState(false)
  const [messages, setMessages] = useState({});
  const [taskId,setTaskId] = useState()
  const [messageList,setMessageList]=useState([])
  const [loadingMessage,setLoadingMessage]= useState(true)
  const [replyMessage, setReplyMessage] = useState('');
  const [dataTaskList,setDataTaskList] = useState([])
  const { t } = useTranslation();
  const classes = useStyles();
  const [data, setData] = useState([]);
  useEffect(()=>{
    setTimeout(()=>{
        api
        .getList({
          controller: 'messages/incoming_messages',
          where:searchInput.length>0?searchInput:"",
          page: listViewPage,
        }).then((result=>{
            setRefreshMessage(false)
            setMessageList(result.list)
            setCount(result.count);
            setLoadingMessage(false)
        }))

    },300)
},[refreshMessage,listViewPage])
const onClickSearch = (e) => {
  setLoadingMessage(true);
  api
    .getList({
      controller: `messages/incoming_messages`,
      where: searchInput,
    })
    .then((result) => {
      setTimeout(() => {
        // console.log(result)
        if (result.list) {
          setMessageList(result.list)
          setCount(result.count);
        } else {
          console.log("Not Found");
        }
        setLoadingMessage(false)
      }, 300);
    });
};

if (!messages || messages.length<1) return <div>Not Found</div>;
  return (
    <React.Fragment>
        <PageTitle title={t("user_fields.user_list")} button={
          <Button
            component={Link}
            to="/app/users/create" 
            variant="contained"
            size="medium"
            color="secondary"
          >
            {t("user_fields.create")}
          </Button>
          } 
        />
        <div className={classes.root}>
            {loadingMessage ? (<div>Loading</div>):
            <Grid container className={classes.chatSection}>
              <FormControl sx={{ width: "45ch", marginBottom: 5 }}>
                <OutlinedInput
                  disabled={loadingMessage}
                  onChange={(e) => setSearchInput(e.target.value)}
                  style={{ paddingLeft: 5 }}
                  endAdornment={
                    <IconButton onClick={onClickSearch}>
                      <SearchIcon />
                    </IconButton>
                  }
                  value={searchInput}
                  placeholder="Please enter text"
                />
              </FormControl>
                <Grid item xs={12}>
                    {messageList.length<1 && !loadingMessage && (<div>Not Found</div>)}
                    {messageList && messageList.length > 0 && (
                        <List className={classes.messageArea}>
                            {messageList.map((item, key) => {
                                    return (
                                      <React.Fragment>
                                        <MessageRight
                                            key={key}
                                            incoming={item}
                                            setRefreshMessage={setRefreshMessage}
                                            task_selected={taskId} 
                                            task_list={dataTaskList}
                                            message={item.body}
                                            // sendToOtherTask={e=>messageSendToOtherTask({message_id:item.id,new_task:e})}
                                            timestamp={`Updated at ${moment(
                                                item.createdAt,
                                            ).format("YYYY-MM-DD HH:mm")} and start at ${moment(
                                              item.date,
                                          ).fromNow()}`}
                                            photoURL="https://lh3.googleusercontent.com/a-/AOh14Gi4vkKYlfrbJ0QLJTg_DLjcYyyK7fYoWRpz2r4s=s96-c"
                                            displayName={`${item.from}`}
                                            avatarDisp={true}
                                        />
                                      </React.Fragment>
                                    );
                            })}
                        </List>
                    )}
                          {!loadingMessage && (
        <ListViewPagination
          handleOnChange={(e) => {
            setListViewPage(e);
          }}
          total={count}
          page={listViewPage}
        />
      )}
                </Grid>
            </Grid>
            }
        </div>
    </React.Fragment>
  );
};
export default IncomingMessage;
