import React from "react";

import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
// import 'moment/locale/fa';

import {
  InputText,
  DatePicker,
  InputRadio,
  InputSelect,
  MobileInput,
} from "../Forms";

const FieldContent = ({
  fields,
  onChange,
  field_key,
  submitValue,
  updateValue,
  field_value,
  setSubmitValue,
  autoTranslation,
}) => {
  const { t } = useTranslation();

  if (
    fields.filter((item) => item.id === field_key).length > 0 &&
    fields.filter(
      (item) =>
        (item.id === field_key && item.show_list) ||
        (item.id === field_key && item.show_view)
    ).length > 0
  ) {
    return (
      <Grid container>
        <Grid item xs={12} lg={12} sm={12}>
          {fields.filter((item) => item.id === field_key)[0].type === "text" ? (
            <InputText
              id={field_key}
              value={updateValue[field_key] || field_value || ""}
              label={
                autoTranslation
                  ? t(`global_fields.${field_key}`)
                  : fields.filter((item) => item.id === field_key)[0].label
              }
              onChange={(e) => {
                onChange(e);
              }}
            />
          ) : fields.filter((item) => item.id === field_key)[0].type ===
            "select" ? (
            <InputSelect
              id={field_key}
              // noneExist={true}
              value={updateValue[field_key] || field_value || ""}
              label={
                autoTranslation
                  ? t(`global_fields.${field_key}`)
                  : fields.filter((item) => item.id === field_key)[0].label
              }
              onChange={(e) => {
                onChange(e);
              }}
            />
          ) : fields.filter((item) => item.id === field_key)[0].type ===
            "radio" ? (
            <InputRadio
              id={field_key}
              value={updateValue[field_key] || field_value || ""}
              label={
                autoTranslation
                  ? t(`global_fields.${field_key}`)
                  : fields.filter((item) => item.id === field_key)[0].label
              }
              onChange={(e) => {
                onChange(e);
              }}
            />
          ) : // fields.filter(
          //       (item) => item.id === field_key,
          //   )[0].type === 'fileupload' ? (
          //     <FileUploadInput
          //         id={field_key}
          //         value={field_value}
          //         label={
          //             autoTranslation
          //                 ? t(`global_fields.${field_key}`)
          //                 : fields.filter(
          //                       (item) =>
          //                           item.id === field_key,
          //                   )[0].label
          //         }
          //         onChange={handleOnChangeFile}
          //     />
          // ) :
          fields.filter((item) => item.id === field_key)[0].type ===
            "datepicker" ? (
            <DatePicker
              id={field_key}
              value={updateValue[field_key] || field_value || ""}
              label={
                autoTranslation
                  ? t(`global_fields.${field_key}`)
                  : fields.filter((item) => item.id === field_key)[0].label
              }
              onChange={(e) => {
                onChange(e);
              }}
            />
          ) : fields.filter((item) => item.id === field_key)[0].type ===
            "phone" ? (
            <MobileInput
              value={updateValue[field_key] || field_value || ""}
              id={field_key}
              label={
                autoTranslation
                  ? t(`global_fields.${field_key}`)
                  : fields.filter((item) => item.id === field_key)[0].label
              }
              onChange={(e) => {
                onChange(e);
              }}
            />
          ) : fields.filter((item) => item.id === field_key)[0].type ===
            "textarea" ? (
            <InputText
              id={field_key}
              value={updateValue[field_key] || field_value || ""}
              label={
                autoTranslation
                  ? t(`global_fields.${field_key}`)
                  : fields.filter((item) => item.id === field_key)[0].label
              }
              onChange={(e) => {
                onChange(e);
              }}
            />
          ) : fields.filter((item) => item.id === field_key)[0].type ===
            "number" ? (
            <InputText
              value={updateValue[field_key] || field_value || ""}
              id={field_key}
              label={
                autoTranslation
                  ? t(`global_fields.${field_key}`)
                  : fields.filter((item) => item.id === field_key)[0].label
              }
              onChange={(e) => {
                onChange(e);
              }}
            />
          ) : null}
        </Grid>
      </Grid>
    );
  }
  return <React.Fragment />;
};

export default FieldContent;
