import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// import { makeStyles } from '@mui/styles';
import { StyledInputCheck } from "../StyledComponents";
import { FormControlLabel } from "@mui/material";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: 8,
    },
  },
}));

const InputCheck = ({
  size,
  href,
  color,
  id,
  label,
  onChange,
  defaultChecked,
  style,
  onClick,
  variant,
  disabled,
  children,
  className,
  component,
  value,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <FormControlLabel
        control={
          <StyledInputCheck
            onChange={onChange}
            disabled={disabled}
            checked={value}
            id={id}
          />
        }
        label={label}
      />
    </div>
  );
};

export { InputCheck };
