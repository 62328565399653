import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import { makeStyles } from '@mui/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: 8,
        },
    },
}));

const InputUnstyledButton = ({
    size,
    href,
    color,
    style,
    onClick,
    variant,
    disabled,
    children,
    className,
    component,
}) => {
    const classes = useStyles();

    return (
        <div className={classes.root} style={style}>
            <Button
                size={size}
                href={href}
                variant="text"
                disabled={disabled}
                className={className}
                component={component}
                style={style}
                onClick={onClick}
            >
                {children}
            </Button>
        </div>
    );
};

export { InputUnstyledButton };
