import React, { useEffect, useState } from "react";
import { Gallery } from "../../../components/Gallery";
import { InputText, Uploader } from "../../../components/Forms";
import { api } from "../../../services/api";
import {
  Button,
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export default function GalleryModal(props) {
  const [open, setOpen] = React.useState(false);
  const [uploadEnable, setUploadEnable] = useState(false);
  const [filesGallery, setFilesGallery] = useState([]);
  const [fileDetail, setFileDetail] = useState({});
  useEffect(() => {
    if (props.open)
      api
        .getAll(`files/list/${props.match.params.id}`)
        .then((res) => setFilesGallery(res));
  }, [props.open]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog fullScreen open={props.open} onClose={props.onClose}>
        <DialogTitle>Files Gallery</DialogTitle>
        <DialogContent>
          <Gallery
            data={filesGallery}
            withFullScreen
            header={() => (
              <Uploader
                afterUpload={() => setFileDetail({})}
                body={{
                  type: "case",
                  ref_id: props.match.params.id,
                  ...fileDetail,
                }}
                uploadUrl="files/upload"
                footerContentBeforeUpload={() => (
                  <Grid container>
                    <Grid item xs={12} md={8} lg={8} sm={8}>
                      <InputText
                        id={"title"}
                        autoTranslate
                        onChange={(e) =>
                          setFileDetail((prevState) => ({
                            ...prevState,
                            title: e.target.value,
                          }))
                        }
                        value={fileDetail.title}
                      />
                    </Grid>
                    <Grid item xs={12} md={8} lg={8} sm={8}>
                      <InputText
                        id={"category"}
                        autoTranslate
                        onChange={(e) =>
                          setFileDetail((prevState) => ({
                            ...prevState,
                            category: e.target.value,
                          }))
                        }
                        value={fileDetail.category}
                      />
                    </Grid>
                  </Grid>
                )}
                urls={(e) => {
                  setFilesGallery((prevState) => [...prevState, e]);
                }}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
