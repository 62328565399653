import React, { useState, useEffect } from "react";
import {
  InputRadio,
  InputText,
  InputSelect,
  GoogleAutocomplete,
} from "../../components/Forms";
import {
  Grid,
  IconButton,
  Button,
  Typography,
  AppBar,
  Toolbar,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { add } from "../../redux/features/tasks";
import CloseIcon from "@mui/icons-material/Close";
var _ = require("lodash");
const DestinationLocationComp = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const view = useSelector((state) => state.task.view);
  const eee = useSelector((state) => state.task);

  const [destinationFields, setdestinationFields] = useState({});
  const [destinationList, setDestinationList] = useState([]);
  const [address, setAddress] = useState("");
  useEffect(() => {
    if (
      view.filter((item) => item.id == props.match.params.id)[0] &&
      view.filter((item) => item.id == props.match.params.id)[0].destination
    ) {
      // setValues(view.filter((item) => item.id == props.match.params.id));
      setDestinationList(
        view.filter((item) => item.id == props.match.params.id)[0].destination
      );
    }
  }, [view]);
  useEffect(() => {
    if (props.destinationRow && props.edit && _.isEmpty(destinationFields)) {
      setAddress(props.destinationRow.address);
      setdestinationFields(props.destinationRow);
    }
  }, [props.destinationRow]);
  const onChangeAddress = (e) => {
    setAddress(e.label);
  };
  const onChangeDestinationField = (e) => {
    setdestinationFields((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };
  if (view)
    if (destinationList && destinationList.length > 0) {
      return (
        <React.Fragment>
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={props.handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {props.title
                  ? props.title
                  : t("destination_location.add_new_destination")}
              </Typography>
              <Button
                autoFocus
                color="inherit"
                onClick={() => {
                  props.handleonSaveDestination({
                    ...destinationFields,
                    address: address,
                    task_id: props.task_id,
                  });
                }}
              >
                SAVE
              </Button>
            </Toolbar>
          </AppBar>
          <br />
          <br />
          <Grid container>
            <Grid item xs={10} lg={10} sm={10} md={10}>
              <Grid container>
                <Grid
                  item
                  style={{ zIndex: 2, marginLeft: 10 }}
                  xs={12}
                  sm={12}
                  lg={12}
                  md={12}
                >
                  <GoogleAutocomplete
                    address={address}
                    onChangeAddress={onChangeAddress}
                  />
                </Grid>
                <Grid item xs={12} sm={4} lg={4} md={4}>
                  <InputText
                    label={t("destination_location.hnr")}
                    onChange={onChangeDestinationField}
                    id="destination_hnr"
                    value={destinationFields.destination_hnr || ""}
                  />
                </Grid>
                <Grid item xs={6} sm={4} lg={4} md={4}>
                  <InputText
                    label={t("destination_location.siege")}
                    onChange={onChangeDestinationField}
                    id="destination_siege"
                    value={destinationFields.destination_siege}
                  />
                </Grid>
                <Grid item xs={6} sm={4} lg={4} md={4}>
                  <InputText
                    label={t("destination_location.tur_nr")}
                    onChange={onChangeDestinationField}
                    id="destination_tur_nr"
                    value={destinationFields.destination_tur_nr}
                  />
                </Grid>
                <Grid container>
                  <Grid item xs={4} sm={3} lg={3} md={3}>
                    <InputText
                      label={t("destination_location.plz")}
                      onChange={onChangeDestinationField}
                      id="destination_postcode"
                      value={destinationFields.destination_postcode}
                    />
                  </Grid>
                  <Grid item xs={8} sm={6} lg={6} md={6}>
                    <InputText
                      label={t("destination_location.bundesland")}
                      onChange={onChangeDestinationField}
                      id="destination_city"
                      value={destinationFields.destination_city}
                    />
                  </Grid>
                  <Grid item xs={4} sm={3} lg={3} md={3}>
                    <InputText
                      label={t("destination_location.stock")}
                      onChange={onChangeDestinationField}
                      id="destination_stock"
                      value={destinationFields.destination_stock}
                    />
                  </Grid>
                  <Grid item xs={4} sm={5} lg={3} md={4}>
                    <InputText
                      label={t("destination_location.plaque")}
                      onChange={onChangeDestinationField}
                      id="destination_plaque"
                      value={destinationFields.destination_plaque}
                    />
                  </Grid>
                  <Grid item xs={4} sm={5} lg={3} md={4}>
                    <InputText
                      label={t("destination_location.floor")}
                      onChange={onChangeDestinationField}
                      id="destination_floor"
                      value={destinationFields.destination_floor}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={12} lg={12} md={12}>
                    <InputRadio
                      label={"Lift"}
                      id="destination_lift"
                      onChange={onChangeDestinationField}
                      value={destinationFields.destination_lift}
                      data={[
                        { value: 1, label: t("destination_location.ja") },
                        { value: 2, label: t("destination_location.nein") },
                      ]}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  {destinationFields.destination_lift == 1 && (
                    <Grid item xs={12} sm={4} lg={6} md={6}>
                      <InputText
                        label={t("destination_location.lift_load")}
                        id={"destination_lift_load"}
                        onChange={onChangeDestinationField}
                        value={destinationFields.destination_lift_load}
                      />
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    lg={destinationFields.destination_lift == 1 ? 6 : 12}
                    md={destinationFields.destination_lift == 1 ? 6 : 12}
                  >
                    <InputText
                      label={t("destination_location.apartment_area")}
                      onChange={onChangeDestinationField}
                      value={destinationFields.destination_apartment_area}
                      id="destination_apartment_area"
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={12} lg={12} md={12}>
                    <InputRadio
                      label={t("destination_location.access_to_parking")}
                      id="destination_access_to_parking"
                      onChange={onChangeDestinationField}
                      value={destinationFields.destination_access_to_parking}
                      data={[
                        { value: 1, label: t("destination_location.ja") },
                        { value: 2, label: t("destination_location.nein") },
                      ]}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={12} lg={12} md={12}>
                    <InputText
                      label={t(
                        "destination_location.distance_from_parking_to_home"
                      )}
                      id="destination_distance_parking_to_home"
                      onChange={onChangeDestinationField}
                      value={
                        destinationFields.destination_distance_parking_to_home
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={12} lg={12} md={12}>
                    <InputSelect
                      noneExist
                      label={t(
                        "destination_location.possibility_to_attach_warning_signs"
                      )}
                      id="possibility_to_attach_warning_signs"
                      onChange={onChangeDestinationField}
                      value={
                        destinationFields.possibility_to_attach_warning_signs
                      }
                      data={[
                        {
                          value: 1,
                          text: t(
                            "tasks.origin_location.possibility_to_attach_warning_signs_list.no_need"
                          ),
                        },
                        {
                          value: 2,
                          text: t(
                            "tasks.origin_location.possibility_to_attach_warning_signs_list.it_must"
                          ),
                        },
                        {
                          value: 3,
                          text: t(
                            "tasks.origin_location.possibility_to_attach_warning_signs_list.requested"
                          ),
                        },
                        {
                          value: 4,
                          text: t(
                            "tasks.origin_location.possibility_to_attach_warning_signs_list.request_accepted"
                          ),
                        },
                      ]}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={4} lg={4} md={4}>
                    <InputText
                      label={t("global_fields.total_truck")}
                      onChange={onChangeDestinationField}
                      id="total_truck"
                      value={destinationFields.total_truck}
                    />
                  </Grid>
                  <Grid item xs={11} sm={4} md={4} lg={4}>
                    <InputText
                      label={t("global_fields.people")}
                      onChange={onChangeDestinationField}
                      id="people"
                      value={destinationFields.people}
                    />
                  </Grid>
                  <Grid item xs={11} sm={4} md={4} lg={4}>
                    <InputText
                      label={t("global_fields.transportation")}
                      onChange={onChangeDestinationField}
                      id="transportation"
                      value={destinationFields.transportation}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12} md={12}>
                    <InputRadio
                      label={t(
                        "destination_location.is_there_more_than_80_kg_of_furniture"
                      )}
                      id="destination_more_than_80_furniture"
                      onChange={onChangeDestinationField}
                      value={
                        destinationFields.destination_more_than_80_furniture
                      }
                      data={[
                        { value: 1, label: t("destination_location.ja") },
                        { value: 2, label: t("destination_location.nein") },
                      ]}
                    />
                  </Grid>
                </Grid>
                {destinationFields.destination_more_than_80_furniture == 1 && (
                  <Grid container>
                    <Grid item xs={12} sm={12} lg={12} md={12}>
                      <InputText
                        label={t(
                          "destination_location.what_is_the_number_of_furniture_over_80_kg"
                        )}
                        id="destination_number_of_furniture_over_80"
                        onChange={onChangeDestinationField}
                        value={
                          destinationFields.destination_number_of_furniture_over_80
                        }
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={props.handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {props.title
                  ? props.title
                  : t("destination_location.add_new_destination")}
              </Typography>
              <Button
                autoFocus
                color="inherit"
                onClick={() => {
                  props.handleonSaveDestination({
                    ...destinationFields,
                    address: address,
                    task_id: props.task_id,
                  });
                }}
              >
                save
              </Button>
            </Toolbar>
          </AppBar>
          <br />
          <br />
          <Grid container>
            <Grid item xs={10} lg={10} sm={10} md={10}>
              <Grid container>
                <Grid
                  item
                  style={{ zIndex: 2, marginLeft: 10 }}
                  xs={12}
                  sm={12}
                  lg={12}
                  md={12}
                >
                  <GoogleAutocomplete
                    address={address}
                    onChangeAddress={onChangeAddress}
                  />
                </Grid>
                <Grid item xs={12} sm={4} lg={4} md={4}>
                  <InputText
                    label={t("destination_location.hnr")}
                    onChange={onChangeDestinationField}
                    id="destination_hnr"
                    value={destinationFields.destination_hnr || ""}
                  />
                </Grid>
                <Grid item xs={6} sm={4} lg={4} md={4}>
                  <InputText
                    label={t("destination_location.siege")}
                    onChange={onChangeDestinationField}
                    id="destination_siege"
                    value={destinationFields.destination_siege}
                  />
                </Grid>
                <Grid item xs={6} sm={4} lg={4} md={4}>
                  <InputText
                    label={t("destination_location.tur_nr")}
                    onChange={onChangeDestinationField}
                    id="destination_tur_nr"
                    value={destinationFields.destination_tur_nr}
                  />
                </Grid>
                <Grid container>
                  <Grid item xs={4} sm={3} lg={3} md={3}>
                    <InputText
                      label={t("destination_location.plz")}
                      onChange={onChangeDestinationField}
                      id="destination_postcode"
                      value={destinationFields.destination_postcode}
                    />
                  </Grid>
                  <Grid item xs={8} sm={6} lg={6} md={6}>
                    <InputText
                      label={t("destination_location.bundesland")}
                      onChange={onChangeDestinationField}
                      id="destination_city"
                      value={destinationFields.destination_city}
                    />
                  </Grid>
                  <Grid item xs={4} sm={3} lg={3} md={3}>
                    <InputText
                      label={t("destination_location.stock")}
                      onChange={onChangeDestinationField}
                      id="destination_stock"
                      value={destinationFields.destination_stock}
                    />
                  </Grid>
                  <Grid item xs={4} sm={5} lg={3} md={4}>
                    <InputText
                      label={t("destination_location.plaque")}
                      onChange={onChangeDestinationField}
                      id="destination_plaque"
                      value={destinationFields.destination_plaque}
                    />
                  </Grid>
                  <Grid item xs={4} sm={5} lg={3} md={4}>
                    <InputText
                      label={t("destination_location.floor")}
                      onChange={onChangeDestinationField}
                      id="destination_floor"
                      value={destinationFields.destination_floor}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={12} lg={12} md={12}>
                    <InputRadio
                      label={"Lift"}
                      id="destination_lift"
                      onChange={onChangeDestinationField}
                      value={destinationFields.destination_lift}
                      data={[
                        { value: 1, label: t("destination_location.ja") },
                        { value: 2, label: t("destination_location.nein") },
                      ]}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  {destinationFields.destination_lift == 1 && (
                    <Grid item xs={12} sm={4} lg={6} md={6}>
                      <InputText
                        label={t("destination_location.lift_load")}
                        id={"destination_lift_load"}
                        onChange={onChangeDestinationField}
                        value={destinationFields.destination_lift_load}
                      />
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    lg={destinationFields.destination_lift == 1 ? 6 : 12}
                    md={destinationFields.destination_lift == 1 ? 6 : 12}
                  >
                    <InputText
                      label={t("destination_location.apartment_area")}
                      onChange={onChangeDestinationField}
                      value={destinationFields.destination_apartment_area}
                      id="destination_apartment_area"
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={12} lg={12} md={12}>
                    <InputRadio
                      label={t("destination_location.access_to_parking")}
                      id="destination_access_to_parking"
                      onChange={onChangeDestinationField}
                      value={destinationFields.destination_access_to_parking}
                      data={[
                        { value: 1, label: t("destination_location.ja") },
                        { value: 2, label: t("destination_location.nein") },
                      ]}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={12} lg={12} md={12}>
                    <InputText
                      label={t(
                        "destination_location.distance_from_parking_to_home"
                      )}
                      id="destination_distance_parking_to_home"
                      onChange={onChangeDestinationField}
                      value={
                        destinationFields.destination_distance_parking_to_home
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={12} lg={12} md={12}>
                    <InputSelect
                      noneExist
                      label={t(
                        "destination_location.possibility_to_attach_warning_signs"
                      )}
                      id="possibility_to_attach_warning_signs"
                      onChange={onChangeDestinationField}
                      value={
                        destinationFields.possibility_to_attach_warning_signs
                      }
                      data={[
                        {
                          value: 1,
                          text: t(
                            "tasks.origin_location.possibility_to_attach_warning_signs_list.no_need"
                          ),
                        },
                        {
                          value: 2,
                          text: t(
                            "tasks.origin_location.possibility_to_attach_warning_signs_list.it_must"
                          ),
                        },
                        {
                          value: 3,
                          text: t(
                            "tasks.origin_location.possibility_to_attach_warning_signs_list.requested"
                          ),
                        },
                        {
                          value: 4,
                          text: t(
                            "tasks.origin_location.possibility_to_attach_warning_signs_list.request_accepted"
                          ),
                        },
                      ]}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={4} lg={4} md={4}>
                    <InputText
                      label={t("global_fields.total_truck")}
                      onChange={onChangeDestinationField}
                      id="total_truck"
                      value={destinationFields.total_truck}
                    />
                  </Grid>
                  <Grid item xs={11} sm={4} md={4} lg={4}>
                    <InputText
                      label={t("global_fields.people")}
                      onChange={onChangeDestinationField}
                      id="people"
                      value={destinationFields.people}
                    />
                  </Grid>
                  <Grid item xs={11} sm={4} md={4} lg={4}>
                    <InputText
                      label={t("global_fields.transportation")}
                      onChange={onChangeDestinationField}
                      id="transportation"
                      value={destinationFields.transportation}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12} md={12}>
                    <InputRadio
                      label={t(
                        "destination_location.is_there_more_than_80_kg_of_furniture"
                      )}
                      id="destination_more_than_80_furniture"
                      onChange={onChangeDestinationField}
                      value={
                        destinationFields.destination_more_than_80_furniture
                      }
                      data={[
                        { value: 1, label: t("destination_location.ja") },
                        { value: 2, label: t("destination_location.nein") },
                      ]}
                    />
                  </Grid>
                </Grid>
                {destinationFields.destination_more_than_80_furniture == 1 && (
                  <Grid container>
                    <Grid item xs={12} sm={12} lg={12} md={12}>
                      <InputText
                        label={t(
                          "destination_location.what_is_the_number_of_furniture_over_80_kg"
                        )}
                        id="destination_number_of_furniture_over_80"
                        onChange={onChangeDestinationField}
                        value={
                          destinationFields.destination_number_of_furniture_over_80
                        }
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
      );
    }
};
export { DestinationLocationComp };
