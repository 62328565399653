import React, { useState } from "react";
import { DateRangePicker, DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

import i18n from "i18next";
import {
  Chip,
  Paper,
  Button,
  Tooltip,
  Grid,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  Business,
  PersonPin,
  LocalShipping,
  Coronavirus,
  EmojiPeople,
  Loop,
  Edit,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { ListView } from "../../../components/ListView";

const possibility_to_attach_warning_signs_colors = [
  "disabled",
  "warning",
  "error",
  "success",
  "secondary",
  "primary",
];
const services_list = [
  "move",
  "demontage",
  "montage",
  "entsorgung",
  "einpackservice",
  "auspackservice",
  "ausmalen",
  "reinigung",
];
const TasksFields = [
  {
    type: "refrence",
    label: i18n.t("events.id"),
    id: "id",
    onlyLink: true,
    show_list: false,
  },
  {
    type: "select",
    label: i18n.t("tasks.status"),
    id: "status",
    show_list: true,
    data: [
      { value: 1, text: i18n.t("tasks_status.created") },
      { value: 2, text: i18n.t("tasks_status.pending") },
      { value: 3, text: i18n.t("tasks_status.accepted") },
      { value: 4, text: i18n.t("tasks_status.doing") },
      { value: 5, text: i18n.t("tasks_status.done") },
      { value: 6, text: i18n.t("events.on_hold") },
      { value: 7, text: i18n.t("tasks_status.cancelled") },
      { value: 8, text: i18n.t("tasks_status.undefined") },
    ],
  },
  {
    type: "text",
    label: i18n.t("events_table.customer_info"),
    id: "name",
    grid: { xs: 12, sm: 12, md: 12, lg: 12 },
    show_list: true,
    format: (e) => (
      <span>
        {e.title && `${e.title} -`} {e.name} {e.phone}
      </span>
    ),
  },
  {
    type: "text",
    label: i18n.t("tasks.email"),
    id: "email",
    grid: { xs: 12, sm: 12, md: 12, lg: 12 },
    show_list: true,
  },
  {
    type: "text",
    label: i18n.t("tasks.price"),
    id: "price",
    grid: { xs: 12, sm: 12, md: 12, lg: 12 },
    show_list: true,
    format: (e) => `${e.price} €`,
  },
  {
    type: "text",
    label: i18n.t("events_table.services"),
    id: "umzugsplan_id",
    show_list: true,
    grid: { xs: 12, sm: 12, md: 12, lg: 12 },
    format: (e) => (
      <span>
        {services_list
          .map((item) => e[item] && item)
          .filter((item) => item !== false).length > 1
          ? services_list
              .map((item) => e[item] && item)
              .filter((item) => item !== false)
              .map((item, key) => {
                if (
                  key <
                  services_list
                    .map((item) => e[item] && item)
                    .filter((item) => item !== false).length -
                    1
                )
                  return (
                    i18n.t(`services.${item}`) +
                    " " +
                    i18n.t("services.and") +
                    " "
                  );
                else return item;
              })
          : "None"}
      </span>
    ),
  },
  {
    type: "text",
    id: "destination",
    grid: { xs: 12, sm: 12, md: 12, lg: 12 },
    label: i18n.t("events_table.destination_address"),
    show_list: true,
    format: (e) =>
      e.destination.map((item, key) => (
        <Grid container justifyContent="center" alignItems="center">
          <Grid item>
            <WarningAmberIcon
              color={
                possibility_to_attach_warning_signs_colors[
                  parseInt(e.origin_possibility_to_attach_warning_signs - 1)
                ]
              }
            />
          </Grid>
          <Grid item>
            {item.transportation && (
              <Chip
                icon={<Loop />}
                // onDelete={(e) => console.log("test")}
                label={item.transportation}
              />
            )}
          </Grid>
          <Grid item>
            {item.total_truck && (
              <Chip icon={<LocalShipping />} label={item.total_truck} />
            )}
          </Grid>
          <Grid item>
            {item.destination_plaque} - {item.destination_floor} {item.address}{" "}
            {key < e.destination.length - 1 && i18n.t("services.and")}{" "}
          </Grid>
        </Grid>
      )),
  },
  {
    type: "text",
    id: "origin_address",
    grid: { xs: 12, sm: 12, md: 12, lg: 12 },
    label: i18n.t("events_table.origin_address"),
    show_list: true,
    format: (e) => (
      <span style={{ display: "flex", alignItems: "center" }}>
        <WarningAmberIcon
          color={
            possibility_to_attach_warning_signs_colors[
              parseInt(e.origin_possibility_to_attach_warning_signs - 1)
            ]
          }
        />
        {e.origin_plaque && e.origin_floor
          ? `${e.origin_plaque}  - ${e.origin_floor} `
          : ""}
        {e.origin_address}
      </span>
    ),
  },

  {
    type: "text",
    id: "services",
    grid: { xs: 12, sm: 12, md: 12, lg: 12 },
    label: i18n.t("events_table.origin_address"),
    show_list: true,
    format: (e) => <div>eee</div>,
  },
];
const EventsTable = (props) => {
  const { t } = useTranslation();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [descriptions, setDescriptions] = useState([]);
  const [dateRange, setDateRang] = useState([null, null]);
  const { meetings } = props;
  const [enableSetRang, setEnableSetRang] = useState(false);

  const status_vals = [
    t("tasks_status.created"),
    t("tasks_status.pending"),
    t("tasks_status.accepted"),
    t("tasks_status.doing"),
    t("tasks_status.done"),
    t("tasks_status.on_hold"),
    t("tasks_status.cancelled"),
  ];

  function dataFormat(result) {
    const data = {
      id: result.id,
      services: Object.entries(result)
        .filter((item) => item[1] === true)
        .map((item) => t(`services.${item[0]}`)),
      time: {
        status: result.status,
        meeting_time: result.meeting_time,
        start_time: result.start_time,
        end_time: result.end_time,
      },
      customer_info: {
        contract_type: result.contract_type,
        title: result.title,
        name: result.name,
        company_name: result.company_name,
        company_title: result.company_title,
        phone: result.phone,
      },
      price: result.price,
      createdAt: result.createdAt,
      origin_address: {
        origin_address: result.origin_address,
        possibility_to_attach_warning_signs:
          result.origin_possibility_to_attach_warning_signs,
      },
      destination: result.destination.map((item) => {
        return {
          address: item.address,
          possibility_to_attach_warning_signs:
            item.possibility_to_attach_warning_signs,
        };
      }),
      status: result.status,
    };
    const density = 12;
    return { ...data };
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const onChangeDescriptionSwitch = (e) => {
    if (descriptions.filter((item) => item === e).length < 1) {
      setDescriptions((prevState) => [...prevState, e]);
    } else {
      const temp = descriptions.filter((item) => item !== e);
      setDescriptions(temp);
    }
  };
  return (
    <Paper sx={{ width: "100%", overflow: "hidden", padding: "5px" }}>
      <Grid container>
        <Grid item xs={12} md={12} lg={12} sm={12}>
          <Button onClick={() => setEnableSetRang(!enableSetRang)}>
            {enableSetRang ? "Hidden" : "Show"} Date Rang
          </Button>
        </Grid>
        <Grid item xs={12} md={12} lg={12} sm={12}>
          {enableSetRang && (
            <DateRange
              editableDateInputs={true}
              onChange={(item) => props.onChangeDateRange([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={[
                {
                  startDate: new Date(props.dateRange[0].startDate),
                  endDate: new Date(props.dateRange[0].endDate),
                  key: "selection",
                },
              ]}
            />
          )}
        </Grid>
      </Grid>
      {meetings.length < 1 && <Grid item>{t("not_found")}</Grid>}
      <ListView
        localData={meetings}
        notFoundTitle="global_not_found"
        headerContent={(e) => {
          const start = moment(new Date(e.start_time));
          const end = moment(new Date(e.end_time));
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <span
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  paddingBottom: 5,
                }}
              >
                <Tooltip
                  title={`${moment(e.start_time).fromNow()} - ${end.from(
                    start
                  )}`}
                  arrow
                >
                  <Chip
                    label={
                      <b>
                        {moment(e.start_time).format("DD/MM/YYYY")} -{" "}
                        {moment(e.start_time).format("HH:mm")}
                      </b>
                    }
                  />
                </Tooltip>
              </span>
              <span
                style={{
                  display: "flex",
                  marginLeft: 4,
                  marginRight: 4,
                  borderRadius: 5,
                  border: "1px solid gray",
                  alignItems: "center",
                  padding: 8,
                }}
              >
                {e.company == 2 ? "Herzog" : "Umzugs"}
              </span>
              {e.workers_id.length > 0 && (
                <span>
                  {e.workers_id.map((item) => (
                    <React.Fragment>{item.full_name}, </React.Fragment>
                  ))}
                </span>
              )}
            </div>
          );
        }}
        footerContent={(e) => (
          <Grid justifyContent="flex-end" container spacing={2}>
            {descriptions.filter((item) => item === e.id).length > 0 && (
              <Grid item style={{ margin: 12 }} xs={12} md={12} sm={12} lg={12}>
                <Chip label={e.description} />
              </Grid>
            )}
            {e.description && e.description.length > 0 && (
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      value={
                        descriptions.filter((item) => item === e.id).length > 0
                          ? true
                          : false
                      }
                      onChange={(w) => onChangeDescriptionSwitch(e.id)}
                    />
                  }
                  label="Description"
                />
              </Grid>
            )}
            <Grid item>
              <Chip
                avatar={
                  <React.Fragment>
                    {e.contract_type === 1 ? <PersonPin /> : <Business />}
                    {e.pcr_test === 1 && <Coronavirus />}
                  </React.Fragment>
                }
              />
            </Grid>
            <Grid item>
              {e.people && <Chip icon={<EmojiPeople />} label={e.people} />}
            </Grid>
            <Grid item>
              {e.transportation && (
                <Chip icon={<Loop />} label={e.transportation} />
              )}
            </Grid>
            <Grid item>
              {e.total_truck && e.total_truck.length > 0 && (
                <Chip icon={<LocalShipping />} label={e.total_truck} />
              )}
            </Grid>
            <Grid item>
              <Link to={`./tasks/view/${e.id}`}>
                <Button variant="outlined" startIcon={<Edit />}>
                  More
                </Button>
              </Link>
            </Grid>
          </Grid>
        )}
        fields={TasksFields}
        // pagination
      />
    </Paper>
  );
};
export { EventsTable };
