import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {FormControl} from "../../../../components/Forms"
import { ListViewPagination } from "../../../../components/ListView/ListViewPagination";
import { MessageFields } from "../../../../context/Forms";
import { Reply } from "./components";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import { Button, Grid } from "@material-ui/core";
import { GridTable } from "../../../../components/GridView";
import { MessageRight, MessageLeft,TaskSelector } from './components';
import { useSelector } from 'react-redux';
import List from '@material-ui/core/List';
import { InputText } from '../../../../components/Forms';
import { Link } from 'react-router-dom';
import moment from "moment"
import { useTranslation } from "react-i18next";
import { ListView } from "../../../../components/ListView";
import { api } from '../../../../services/api';
const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  table: {
    minWidth: 650,
  },
  cellWidth: {
    width: "30%",
  },
});
const ReplyFooter = ({ message, ticket_id }) => {
  const classes = useStyles();
  const onSubmitReply = (e) => {
      api.create({
          controller: 'ticket/reply',
          data: { message: message, ticket_id: ticket_id },
      }).then((res) => {
          // console.log(res);
      });
  };
  return (
      <Grid container justifyContent="space-between">
          <Grid item>
              <div className={classes.StyleReplyFooterContent}>
                  ddd
              </div>
          </Grid>
          <Grid item>
              <div className={classes.StyleReplyFooterContent}>
                  <Button
                      disabled={message.length < 3}
                      onClick={() => onSubmitReply()}
                  >
                      Send
                  </Button>
              </div>
          </Grid>
      </Grid>
  );
};

const Messages = ({match}) => {
  // const data = useSelector((state) => state.ticket.active);
  const [listViewPage, setListViewPage] = useState(1);
  const [sendMail,setSendMail] = useState(false)
  const [count, setCount] = useState(false);
  const [refreshMessage,setRefreshMessage]= useState(false)
  const [messages, setMessages] = useState({});
  const [taskId,setTaskId] = useState()
  const [messageList,setMessageList]=useState([])
  const [loadingMessage,setLoadingMessage]= useState(true)
  const [replyMessage, setReplyMessage] = useState('');
  const [dataTaskList,setDataTaskList] = useState([])
  const { t } = useTranslation();
  const classes = useStyles();
  const [data, setData] = useState([]);
  useEffect(()=>{
    setTimeout(()=>{
        api
        .getList({
          controller: `messages/messages_by_task/${match.params.id}`,
          page: listViewPage,
        }).then((result=>{
            setRefreshMessage(false)
            setMessageList(result.list)
            setCount(result.count);
            setLoadingMessage(false)
        }))

    },300)
},[refreshMessage,listViewPage])
if (!messages || messages.length<1) return <div>Not Found</div>;

  return (
    <React.Fragment>
            {loadingMessage || !match.params.id ? (<div>Loading</div>):            
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Button onClick={()=>setSendMail(true)}>Send Mail</Button>
                {sendMail && (
                  <Reply {...match} />
                )}
              </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    {messageList.length<1 && !loadingMessage && (<div>Not Found</div>)}
                    {messageList && messageList.length > 0 && (
                        <List className={classes.messageArea}>
                            {messageList.map((item, key) => {
                                // if (item.from == "test_imap@dimit.host") {
                                    return (
                                        <MessageRight
                                            key={key}
                                            incoming={item}
                                            setRefreshMessage={setRefreshMessage}
                                            task_selected={taskId} 
                                            task_list={dataTaskList}
                                            message={item.body}
                                            // sendToOtherTask={e=>messageSendToOtherTask({message_id:item.id,new_task:e})}
                                            timestamp={`Updated at ${moment(
                                                item.createdAt,
                                            ).format("YYYY-MM-DD HH:mm")} and start at ${moment(
                                              item.date,
                                          ).fromNow()}`}
                                            photoURL="https://lh3.googleusercontent.com/a-/AOh14Gi4vkKYlfrbJ0QLJTg_DLjcYyyK7fYoWRpz2r4s=s96-c"
                                            displayName={`${item.subject}`}
                                            avatarDisp={true}
                                        />
                                    );
                            })}
                        </List>
                    )}
                    {!loadingMessage && (
                      <ListViewPagination
                        handleOnChange={(e) => {
                          setListViewPage(e);
                        }}
                        total={count}
                        page={listViewPage}
                      />
                    )}
                </Grid>
            </Grid>
            }
    </React.Fragment>
  );
};
export default Messages;
