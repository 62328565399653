import React from "react";
import axios from "../axios-orders";
import { login as loginStore } from "./../redux/features/user";
import { useDispatch as reduxDispatch } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import { ErrorHandler } from "../components/ErrorHandler/ErrorHandler";
import i18n from "../i18n";
import { requestForToken } from "../firebase";
import { getMessaging, getToken } from "firebase/messaging";
import { api } from "../services/api";
var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    case "LOGIN_FAILURE":
      return { ...state, isAuthenticated: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem("id_token"),
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export {
  signOut,
  loginUser,
  UserProvider,
  useUserState,
  registerUser,
  useUserDispatch,
};

// ###########################################################
const loginUser = (
  { dispatch, login, password, history, loginPage },
  callback
) => {
  const data = loginPage
    ? {
        username: login,
        password: password,
        firebase: localStorage.getItem("firebase"),
      }
    : { username: login };
  !!login &&
    !!password &&
    axios
      .post("user/login", data)
      .then((res) => {
        const token = res.data.code;
        localStorage.setItem("id_token", token);
        token && dispatch({ type: "LOGIN_SUCCESS" });
        history.push("/app/dashboard");
        toast.success(i18n.t(`toast.successLogin`), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        callback(res.data);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 604) {
            callback("user_not_found");
          } else if (!loginPage && err.response.status === 614) {
            callback("enter_your_password");
          } else {
            dispatch({ type: "LOGIN_FAILURE" });
            callback(false);
            if (err.response === undefined) {
              ErrorHandler(1000);
            } else {
              ErrorHandler(err.response.status);
            }
          }
        }
      });
};

function registerUser(
  {
    dispatch,
    loginValue,
    nameValue,
    passwordValue,
    verificationCode,
    setEnableVerifCode,
    history,
    registerComplete,
  },
  callback
) {
  axios
    .post("user/register", {
      username: loginValue,
      password: passwordValue,
    })
    .then((res) => {
      const token = res.data.code;
      localStorage.setItem("id_token", token);
      token && dispatch({ type: "LOGIN_SUCCESS" });
      history.push("/app/dashboard");
      toast.success(i18n.t(`toast.successRegister`), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      callback(res.data);
      // axios
      //   .post("user/confirm_code", {
      //     email: loginValue,
      //   })
      //   .then((res) => console.log(res.data, "mail verify code"));
    })
    .catch((err) => {
      if (err.response) {
        const statusCode = err.response;
        dispatch({ type: "LOGIN_FAILURE" });
        callback(false);
        ErrorHandler(statusCode.status);
      }
    });
}

function signOut(dispatch, history) {
  // api.get();
  localStorage.removeItem("id_token");
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  history.push("/login");
}
