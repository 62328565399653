import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Button } from "@material-ui/core";
import { Location } from "../../../../components";
import { TasksFields, TasksSteps } from "../../steps";
import {
  InputRadio,
  InputText,
  FormControl,
} from "../../../../components/Forms";
import { useSelector, useDispatch } from "react-redux";
import { AddNewTask } from "../../../../redux/actions/action";
import { task } from "../../../../redux/store";
import { add, onChangeMarker } from "../../../../redux/features/tasks";
import Dialog from "../Dialog";
import { GridTable } from "../../../../components/GridView";
import { Edit, Add } from "@material-ui/icons";
const InventoryComp = (prop) => {
  // const { t } = useTranslation();
  // const Fields = [
  //   {
  //     type: "hidden",
  //     label: t("inventory.id"),
  //     id: "id",
  //     name: "122323",
  //   },
  //   {
  //     type: "text",
  //     label: t("inventory.title"),
  //     id: "title",
  //     show_list: true,
  //     name: "122323",
  //   },
  //   {
  //     type: "text",
  //     label: t("inventory.amount"),
  //     id: "amount",
  //     disable_link: true,
  //     show_list: true,
  //     name: "122323",
  //   },
  //   {
  //     type: "text",
  //     label: t("inventory.room"),
  //     show_list: true,
  //     id: "room",
  //   },
  //   {
  //     type: "action",
  //     label: t("inventory.action"),
  //     show_list: true,
  //     render: (e) => (
  //       <div>
  //         <Edit
  //           onClick={() => {
  //             setData(e[0]);
  //             setAddNewModal(true);
  //           }}
  //         />
  //       </div>
  //     ),
  //   },
  // ];
  // const dispatch = useDispatch();
  // const [Add_new_modal, setAddNewModal] = useState(false);
  // const [data, setData] = useState(null);
  // const view = useSelector((state) => state.task.view);
  // if (view.length > 0) {
  //   return (
  //     <form
  //       style={{ margin: 10, width: "100%" }}
  //       onSubmit={(e) => console.log(e)}
  //     >
  //       <GridTable
  //         title={"eee"}
  //         localData={view[0].inventory}
  //         controller="tasks"
  //         fields={Fields}
  //       />
  //       {prop.globalEditable && (
  //         <Button onClick={() => setAddNewModal(true)}>
  //           <Add />
  //         </Button>
  //       )}
  //       <Dialog
  //         onSave={() => prop.onSave()}
  //         view_id={prop.match.params.id}
  //         status={Add_new_modal}
  //         globalEditable={prop.globalEditable}
  //         data={data}
  //         handleClose={() => {
  //           setAddNewModal(false);
  //           setData(null);
  //         }}
  //       />
  //     </form>
  //   );
  // } else {
  //   return <div></div>;
  // }
};
export default InventoryComp;
