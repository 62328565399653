import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {FormControl} from "../../components/Forms"
import { UsersFields } from "../../context/Forms";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Button, Grid } from "@material-ui/core";
import { GridTable } from "../../components/GridView";
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { ListView } from "../../components/ListView";
const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  table: {
    minWidth: 650,
  },
  cellWidth: {
    width: "30%",
  },
});

const UsersList = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <React.Fragment>
        <PageTitle title={t("user_fields.user_list")} button={
          <Button
            component={Link}
            to="/app/users/create" 
            variant="contained"
            size="medium"
            color="secondary"
          >
            {t("user_fields.create")}
          </Button>
          } 
        />
        <div className={classes.root}>
          <ListView notFoundTitle="global_not_found" withPagination withShow controller="user" fields={UsersFields} />
        </div>
    </React.Fragment>
  );
};
export default UsersList;
