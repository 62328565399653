import React, { useState } from "react";
// import { makeStyles } from "@material-ui/core/styles";
// import { makeStyles } from '@material-ui/styles';
import { makeStyles } from "@mui/styles";

import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: 16,
  },
  selectEmpty: {
    marginTop: 16,
  },
}));

export default function NativeSelects() {
  const classes = useStyles();
  const { i18n } = useTranslation();

  const handleChange = (event) => {
    const lang = event.target.value;
    i18n.changeLanguage(lang);
    window.localStorage.setItem("language", lang);
  };

  return (
    <FormControl className={classes.formControl}>
      <NativeSelect
        onChange={handleChange}
        value={localStorage.getItem("language")?localStorage.getItem("language"):"en"}
        // defaultValue="en"
        // inputProps={{
        //   name: "name",
        //   id: "uncontrolled-native",
        // }}
      >
        {/* <option value={"en"}>En</option> */}
        <option value={"en"}>En</option>
        <option value={"fa"}>fa</option>
      </NativeSelect>
    </FormControl>
  );
}
