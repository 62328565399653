import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import {
  Home as HomeIcon,
  Event as EventIcon,
  Person as PersonIcon,
  List as AddTaskIcon,
  Calculate as CalculateIcon,
  ArrowBack as ArrowBackIcon,
  Message as MessageIcon,
  FormatListNumbered as CasesIcon,
} from "@mui/icons-material";
// import { useTheme } from "@material-ui/styles";
import { useTheme } from "@material-ui/core/styles";

import { withRouter } from "react-router-dom";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";
// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { useSelector } from "react-redux";
import i18n from "../../i18n";
const admin_menus = [
  {
    id: 0,
    label: i18n.t("menus.dashboard"),
    link: "/app/dashboard",
    icon: <HomeIcon />,
  },
  {
    id: 1,
    label: i18n.t("menus.tasks"),
    link: "/app/tasks/list",
    icon: <AddTaskIcon />,
    children: [
      { label: i18n.t("menus.list"), link: "/app/tasks/list" },
      { label: i18n.t("menus.create"), link: "/app/tasks/create" },
    ],
  },
  {
    id: 2,
    label: "Messages",
    link: "/app/messages/incoming",
    icon: <MessageIcon />,
    children: [
      {
        label: i18n.t("menus.messages.customer_list"),
        link: "/app/messages/list",
      },
      {
        label: i18n.t("menus.messages.incoming_emails"),
        link: "/app/messages/incoming",
      },
      {
        label: i18n.t("menus.messages.archived_messages"),
        link: "/app/messages/archived",
      },
    ],
  },
  {
    id: 3,
    label: i18n.t("menus.cases.cases"),
    link: "/app/cases/all",
    icon: <CasesIcon />,
    children: [
      {
        label: i18n.t("menus.cases.all_cases"),
        link: "/app/cases/all",
      },
      {
        label: i18n.t("menus.cases.my_cases"),
        link: "/app/cases/my",
      },
      {
        label: i18n.t("menus.cases.completed_case"),
        link: "/app/cases/compeleted",
      },
      {
        label: i18n.t("menus.cases.pending_cases"),
        link: "/app/cases/pending",
      },
      {
        label: i18n.t("menus.cases.cancelled"),
        link: "/app/cases/cancelled",
      },
    ],
  },

  {
    id: 4,
    label: i18n.t("menus.accounting"),
    link: "/app/accounting/list",
    icon: <CalculateIcon />,
    children: [
      // {
      //   label: i18n.t("menus.orders_by_dating_range"),
      //   link: "/app/accounting/dating",
      // },
      {
        label: i18n.t("menus.accounting"),
        link: "/app/accounting",
      },
      { label: i18n.t("menus.costs"), link: "/app/costs/list" },
      {
        label: i18n.t("menus.costs_and_benefits"),
        link: "/app/costs_and_benefits",
      },
    ],
  },
  {
    id: 5,
    label: i18n.t("menus.events"),
    link: "/app/events",
    icon: <EventIcon />,
    children: [
      // {
      //   label: i18n.t("menus.orders_by_dating_range"),
      //   link: "/app/accounting/dating",
      // },
      {
        label: "Events List",
        link: "/app/meetings",
      },
      { label: "Event Table", link: "/app/events_table" },
    ],
  },
  {
    id: 6,
    label: i18n.t("menus.users"),
    link: "/app/users/list",
    icon: <PersonIcon />,
    children: [
      { label: i18n.t("menus.list"), link: "/app/users/list" },
      { label: i18n.t("menus.create"), link: "/app/users/create" },
    ],
  },
];
const secreter_menu = [
  {
    id: 0,
    label: i18n.t("menus.dashboard"),
    link: "/app/dashboard",
    icon: <HomeIcon />,
  },
  {
    id: 2,
    label: "Messages",
    link: "/app/messages/incoming",
    icon: <MessageIcon />,
    children: [
      {
        label: i18n.t("menus.messages.customer_list"),
        link: "/app/messages/list",
      },
      {
        label: i18n.t("menus.messages.incoming_emails"),
        link: "/app/messages/incoming",
      },
      {
        label: i18n.t("menus.messages.archived_messages"),
        link: "/app/messages/archived",
      },
    ],
  },
  {
    id: 3,
    label: i18n.t("menus.cases.cases"),
    link: "/app/cases/all",
    icon: <CasesIcon />,
    children: [
      {
        label: i18n.t("menus.cases.all_cases"),
        link: "/app/cases/all",
      },
      {
        label: i18n.t("menus.cases.my_cases"),
        link: "/app/cases/my",
      },
      {
        label: i18n.t("menus.cases.completed_case"),
        link: "/app/cases/all",
      },
      {
        label: i18n.t("menus.cases.pending_cases"),
        link: "/app/cases/pending",
      },
    ],
  },

  {
    id: 1,
    label: i18n.t("menus.tasks"),
    link: "/app/tasks/list",
    icon: <AddTaskIcon />,
    children: [
      { label: i18n.t("menus.list"), link: "/app/tasks/list" },
      { label: i18n.t("menus.create"), link: "/app/tasks/create" },
    ],
  },
  {
    id: 3,
    label: i18n.t("menus.events"),
    link: "/app/events",
    icon: <EventIcon />,
  },
  {
    id: 17,
    label: i18n.t("menus.users"),
    link: "/app/users/list",
    icon: <PersonIcon />,
    children: [
      { label: i18n.t("menus.list"), link: "/app/users/list" },
      { label: i18n.t("menus.create"), link: "/app/users/create" },
    ],
  },
];
const worker_routes = [
  {
    id: 0,
    label: i18n.t("menus.dashboard"),
    link: "/app/dashboard",
    icon: <HomeIcon />,
  },
  {
    id: 1,
    label: i18n.t("menus.tasks_list"),
    link: "/app/tasks/list",
    icon: <EventIcon />,
  },
];
function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();
  const user_data = useSelector((state) => state.user.value);
  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  const structure =
    user_data.role === 4
      ? admin_menus
      : user_data.role === 3
      ? secreter_menu
      : user_data.role === 2
      ? worker_routes
      : [];
  return (
    <Drawer
      id="Drawer"
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
      onClose={() => toggleSidebar(layoutDispatch)}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List id="Menu" className={classes.sidebarList}>
        {structure.map((link) => (
          <SidebarLink
            key={link.id}
            id="Menu"
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
