import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Button } from "@material-ui/core";
import {
  InputRadio,
  InputText,
  InputSelect,
} from "../../../../components/Forms";
import { useSelector, useDispatch } from "react-redux";
import { task } from "../../../../redux/store";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { updateField, onChangeMarker } from "../../../../redux/features/tasks";
import TitleComponent from "../TitleComponent";
const OriginLocationComp = (prop) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.value);
  const view = useSelector((state) => state.task.view);
  const [locationValue, setLocationValue] = useState();
  const [values, setValues] = useState([]);
  const [editable, setEditable] = useState(false);
  const [address, setAddress] = useState("");
  const [data, setData] = useState({});
  useEffect(() => {
    setValues(view.filter((item) => item.id == prop.match.params.id));
    if (values.length > 0) {
      setData({
        latitude: values[0].origin_lat,
        longitude: values[0].origin_long,
        address: values[0].origin_address,
        postcode: values[0].origin_postcode,
        stock: values[0].origin_stock,
        hnr: values[0].origin_hnr,
        city: values[0].origin_city,
        province: values[0].origin_province,
      });
      setAddress(values[0].origin_address);
    }
  }, [view, address]);
  const onChange = (e) => {
    // const data = {id:e.target.id,data:e.target.value}
    // dispatch(add(data))
    // // dispatch(AddNewTask(data))
  };
  const onChangeMarkerTmp = (e) => {
    setAddress(e.label);
    dispatch(
      onChangeMarker({
        type: "view",
        id: prop.match.params.id,
        origin_address: e.label,
      })
    );
  };
  const onChangeViewport = (e) => {};
  const onChangeOtherFields = (e) => {
    const tmp = {
      task_id: prop.match.params.id,
      id: e.target.id,
      data: e.target.value,
    };
    dispatch(updateField(tmp));
  };
  if (values.length > 0) {
    return (
      <Grid container>
        <TitleComponent
          title={t("events.origin_location")}
          setEditable={() => setEditable(!editable)}
          editable={editable}
        />
        <Grid item xs={11} md={11} lg={11}>
          <Grid item xs={12} lg={12} sm={12} md={12}>
            <Grid container justifyContent="center">
              <Grid
                item
                style={{ zIndex: 2, marginLeft: 10 }}
                xs={12}
                sm={12}
                lg={12}
                md={12}
              >
                {!prop.globalEditable && (
                  <div style={{ padding: 10 }}>{values[0].origin_address}</div>
                )}
                {prop.globalEditable && (
                  <GooglePlacesAutocomplete
                    style={{ width: "100%" }}
                    apiKey="AIzaSyDUb_he0UVZUDzi0LPT8zrTDi1G34DVKLk"
                    autocompletionRequest={{
                      componentRestrictions: {
                        country: [
                          "at",
                          "br",
                          "de",
                          "ch",
                          "dk",
                          "it",
                          "nl",
                          "se",
                        ],
                      },
                    }}
                    selectProps={{
                      address,
                      onChange: onChangeMarkerTmp,
                      // value:values[0].origin_address,
                      label: t("origin_location.address"),
                      id: "origin_address",
                      styles: {
                        input: (provided) => ({
                          ...provided,
                          height: "100%",
                          color: "blue",
                        }),
                        option: (provided) => ({
                          ...provided,
                          color: "blue",
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          color: "blue",
                        }),
                      },
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={11} sm={4} lg={4} md={4}>
                <InputText
                  disabled={!prop.globalEditable}
                  label={t("origin_location.hnr")}
                  onChange={onChangeOtherFields}
                  id="origin_hnr"
                  value={values[0].origin_hnr}
                />
              </Grid>
              <Grid item xs={6} sm={4} lg={4} md={4}>
                <InputText
                  disabled={!prop.globalEditable}
                  label={t("origin_location.siege")}
                  onChange={onChangeOtherFields}
                  id="origin_siege"
                  value={values[0].origin_siege}
                />
              </Grid>
              <Grid item xs={6} sm={4} lg={4} md={4}>
                <InputText
                  disabled={!prop.globalEditable}
                  label={t("origin_location.tur_nr")}
                  onChange={onChangeOtherFields}
                  id="origin_tur_nr"
                  value={values[0].origin_tur_nr}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={4} lg={3} md={3}>
                <InputText
                  disabled={!prop.globalEditable}
                  label={t("origin_location.plz")}
                  onChange={onChangeOtherFields}
                  id="origin_postcode"
                  value={values[0].origin_postcode}
                />
              </Grid>
              <Grid item xs={12} sm={4} lg={6} md={6}>
                <InputText
                  disabled={!prop.globalEditable}
                  label={t("origin_location.bundesland")}
                  onChange={onChangeOtherFields}
                  id="origin_city"
                  value={values[0].origin_city}
                />
              </Grid>
              <Grid item xs={12} sm={4} lg={3} md={3}>
                <InputText
                  disabled={!prop.globalEditable}
                  label={t("origin_location.stock")}
                  onChange={onChangeOtherFields}
                  id="origin_stock"
                  value={values[0].origin_stock}
                />
              </Grid>
              <Grid item xs={4} sm={5} lg={3} md={4}>
                <InputText
                  disabled={!prop.globalEditable}
                  label={t("origin_location.plaque")}
                  onChange={onChangeOtherFields}
                  id="origin_plaque"
                  value={values[0].origin_plaque}
                />
              </Grid>
              <Grid item xs={4} sm={5} lg={3} md={4}>
                <InputText
                  label={t("origin_location.floor")}
                  onChange={onChangeOtherFields}
                  disabled={!prop.globalEditable}
                  id="origin_floor"
                  value={values[0].origin_floor}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} lg={12} md={12}>
                <InputSelect
                  disabled={!prop.globalEditable}
                  label={t(
                    "tasks.origin_location.possibility_to_attach_warning_signs"
                  )}
                  id="origin_possibility_to_attach_warning_signs"
                  onChange={onChangeOtherFields}
                  value={values[0].origin_possibility_to_attach_warning_signs}
                  data={[
                    {
                      value: 1,
                      text: t(
                        "tasks.origin_location.possibility_to_attach_warning_signs_list.no_need"
                      ),
                    },
                    {
                      value: 2,
                      text: t(
                        "tasks.origin_location.possibility_to_attach_warning_signs_list.it_must"
                      ),
                    },
                    {
                      value: 3,
                      text: t(
                        "tasks.origin_location.possibility_to_attach_warning_signs_list.requested"
                      ),
                    },
                    {
                      value: 4,
                      text: t(
                        "tasks.origin_location.possibility_to_attach_warning_signs_list.request_accepted"
                      ),
                    },
                  ]}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} lg={12} md={12}>
                <InputRadio
                  disabled={!prop.globalEditable}
                  label={t("origin_location.lift")}
                  id="origin_lift"
                  onChange={onChangeOtherFields}
                  value={values[0].origin_lift}
                  data={[
                    { value: 1, label: "Ja" },
                    { value: 2, label: "Nein" },
                  ]}
                />
              </Grid>
            </Grid>
            <Grid container>
              {values[0].origin_lift == 1 && (
                <Grid item xs={12} sm={12} lg={6} md={6}>
                  <InputText
                    disabled={!prop.globalEditable}
                    label={t("origin_location.lift_load")}
                    value={values[0].origin_lift_load}
                    id="origin_lift_load"
                    onChange={onChangeOtherFields}
                  />
                </Grid>
              )}
              <Grid
                item
                xs={12}
                sm={12}
                lg={values[0].origin_lift == 1 ? 6 : 12}
                md={values[0].origin_lift == 1 ? 6 : 12}
              >
                <InputText
                  disabled={!prop.globalEditable}
                  label={t("origin_location.apartment_area")}
                  id={"origin_apartment_area"}
                  value={values[0].origin_apartment_area}
                  onChange={onChangeOtherFields}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} lg={12} md={12}>
                <InputRadio
                  disabled={!prop.globalEditable}
                  label={t("origin_location.access_to_parking")}
                  id="origin_access_to_parking"
                  onChange={onChangeOtherFields}
                  value={values[0].origin_access_to_parking}
                  data={[
                    { value: 1, label: "Ja" },
                    { value: 2, label: "Nein" },
                  ]}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} lg={12} md={12}>
                <InputText
                  disabled={!prop.globalEditable}
                  label={t("origin_location.distance_from_parking_to_home")}
                  id="origin_distance_parking_to_home"
                  onChange={onChangeOtherFields}
                  value={values[0].origin_distance_parking_to_home}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={11} sm={11} lg={11} md={11}>
                <Grid container>
                  <Grid item xs={11} sm={4} md={4} lg={4}>
                    <InputText
                      label={t("global_fields.total_truck")}
                      onChange={onChangeOtherFields}
                      disabled={!prop.globalEditable}
                      id="total_truck"
                      value={values[0].total_truck}
                    />
                  </Grid>
                  <Grid item xs={11} sm={4} md={4} lg={4}>
                    <InputText
                      label={t("global_fields.people")}
                      onChange={onChangeOtherFields}
                      disabled={!prop.globalEditable}
                      id="people"
                      value={values[0].people}
                    />
                  </Grid>
                  <Grid item xs={11} sm={4} md={4} lg={4}>
                    <InputText
                      label={t("global_fields.transportation")}
                      onChange={onChangeOtherFields}
                      disabled={!prop.globalEditable}
                      id="transportation"
                      value={values[0].transportation}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} lg={12} md={12}>
                <InputRadio
                  disabled={!prop.globalEditable}
                  label={t(
                    "origin_location.is_there_more_than_80_kg_of_furniture"
                  )}
                  id="origin_more_than_80_furniture"
                  onChange={onChangeOtherFields}
                  value={values[0].origin_more_than_80_furniture}
                  data={[
                    { value: 1, label: "Ja" },
                    { value: 2, label: "Nein" },
                  ]}
                />
              </Grid>
            </Grid>

            {values[0].origin_more_than_80_furniture == 1 && (
              <Grid container>
                <Grid item xs={12} sm={12} lg={12} md={12}>
                  <InputText
                    disabled={!prop.globalEditable}
                    label={t(
                      "origin_location.what_is_the_number_of_furniture_over_80_kg"
                    )}
                    id="origin_number_of_furniture_over_80"
                    onChange={onChangeOtherFields}
                    value={values[0].origin_number_of_furniture_over_80}
                  />
                </Grid>
              </Grid>
            )}
            <Grid item xs={11} sm={11} lg={11} md={11}>
              <InputRadio
                label={t("origin_location.trash")}
                id="trash"
                disabled={!prop.globalEditable}
                onChange={onChangeOtherFields}
                value={values[0].trash}
                data={[
                  { value: 0, label: t("origin_location.nein") },
                  { value: 1, label: t("origin_location.ja") },
                ]}
              />{" "}
            </Grid>
          </Grid>
          {editable && (
            <Grid item xs={12} sm={12} lg={12} md={12}>
              <Button
                onClick={() => {
                  setEditable(false);
                  prop.onSave();
                }}
              >
                {t("origin_location.save")}{" "}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  } else {
    return <div>Loading</div>;
  }
};
export default OriginLocationComp;
