import React from "react";
import { Grid, Button } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { useSelector } from "react-redux";

const TitleComponent = ({ title, setEditable, editable, enableTitle }) => {
  const user = useSelector((state) => state.user.value);
  return (
    <Grid
      container
      direction="row-reverse"
      justifyContent="flex-end"
      alignItems="center"
    >
      <Grid style={{ background: "#ff9300" }} container>
        {enableTitle && (
          <Grid item xs={8} md={8} sm={8} lg={8}>
            <div
              style={{
                color: "white",
                fontSize: 18,
                minHeight: 56,
                display: "flex",
                alignItems: "center",
              }}
            >
              {title}
            </div>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
export default TitleComponent;
