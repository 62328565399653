import defaultTheme from "./default";
import { createTheme, adaptV4Theme } from '@mui/material/styles';
const primary = "#ff9300";
const secondary = "#FF5C93";
const warning = "#FFC260";
const success = "#3CD4A0";
const info = "#9013FE";

const overrides = {
  palette: {
    primary: {
      main: primary
    },
    secondary: {
      main: "#ffcc80"
    }
  },
  typography: {
    h1: {
      fontSize: "3rem",
    },
    h2: {
      fontSize: "2rem",
    },
    h3: {
      fontSize: "1.64rem",
    },
    h4: {
      fontSize: "1.5rem",
    },
    h5: {
      fontSize: "1.285rem",
    },
    h6: {
      fontSize: "1.142rem",
    },
  },
};

const themes = {
  default: createTheme({...defaultTheme,...overrides})
  // default: createTheme(adaptV4Theme({ ...defaultTheme, ...overrides })),
};

export default themes;
