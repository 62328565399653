import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { styled, alpha } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import { CostsFields, CalculateCosts } from "../../../context/Forms";
import PageTitle from "../../../components/PageTitle/PageTitle";
import {
  Button,
  Grid,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Chip,
} from "@mui/material";
import moment from "moment";
import ViewOne from "../../../components/ViewOne/ViewOne";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { api } from "../../../services/api";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import {
  Business,
  PersonPin,
  SearchIcon as Search,
  LocalShipping,
  Coronavirus,
  EmojiPeople,
  Loop,
  Edit,
} from "@mui/icons-material";
import { DateRangePicker, DateRange } from "react-date-range";

const filter = createFilterOptions();

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  table: {
    minWidth: 650,
  },
  cellWidth: {
    width: "30%",
  },
});

const CostsBenefits = (props) => {
  const classes = useStyles();
  const user = useSelector((state) => state.user.value);
  const { t } = useTranslation();
  const [localData, setLocalData] = React.useState({});
  const [loading, setLoading] = useState(true);
  const [dateRangeData, setDateRangeData] = useState({
    startDate: new Date(moment()),
    endDate: new Date(moment().add(10, "days").calendar()),
    key: "selection",
  });
  useEffect(() => {
    console.log(
      moment(dateRangeData.endDate).diff(dateRangeData.startDate, "days")
    );
    if (
      dateRangeData &&
      moment(dateRangeData.endDate).diff(dateRangeData.startDate, "days") > 0
    ) {
      api
        .getAll(
          `costs/calculate_by_date_range/${moment(
            dateRangeData.startDate
          ).format("MM-DD-YYYY")}/${moment(dateRangeData.endDate).format(
            "MM-DD-YYYY"
          )}`
        )
        .then((result) => {
          setLocalData(result);
          setLoading(false);
        });
    }
  }, [dateRangeData]);
  return (
    <React.Fragment>
      <PageTitle
        title={t("menus.costs")}
        button={
          user.role > 2 && (
            <Button
              component={Link}
              to="/app/costs/create"
              variant="contained"
              size="medium"
              color="primary"
            >
              {t("events.create")}
            </Button>
          )
        }
      />
      <Grid container>
        <Grid item>
          <DateRange
            editableDateInputs={true}
            onChange={(item) =>
              setDateRangeData({
                startDate: item.selection.startDate,
                endDate: item.selection.endDate,
              })
            }
            // onChange={(e) => console.log(e.selection)}
            moveRangeOnFirstSelection={false}
            ranges={[
              {
                startDate: dateRangeData.startDate,
                endDate: dateRangeData.endDate,
                key: "selection",
              },
            ]}
          />
        </Grid>
      </Grid>
      <div className={classes.root}>
        {dateRangeData && !loading && (
          <ViewOne
            loading={loading}
            localData={localData}
            title="title"
            // manualWithController
            autoTranslation
            // id={props.match.params.id}
            fields={CalculateCosts}
            // controller="costs"
          />
        )}
      </div>
    </React.Fragment>
  );
};
export default CostsBenefits;
