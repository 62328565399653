import React, { useState } from "react";
import { StyledTableCell, StyledTableRow } from "./../StyledComponents"
import { Link } from "react-router-dom";
const TableCell = ({ txt, q, field, rows, row }) => {
    const field_check = field[q]
    const content = () => {
        if (field_check.type == "select" || field_check.type == "radio") {
            if (field_check.data.filter(l => l.value == txt)[0]) { return field_check.data.filter(l => l.value == txt)[0].text }
            else { return "undefined" + txt }
        }
        else if (field_check.type == "action") { return field_check.render(row) }
        else { return txt }
    }
    if (field_check.type == "refrence") return null
    else if (q == 1) return (<StyledTableCell key={q}>{field_check.disable_link ? content() : (<Link to={row[0]}>xx{JSON.stringify(row)}{content()}</Link>)}</StyledTableCell>)
    return (
        <StyledTableCell key={q}>{content()}</StyledTableCell>
    )
}
const RowsView = (prop) => {
    const { rows, keys } = prop;
    return rows.map((item, ind) => {
        const data = {}
        keys.map(item => item.id).map((key, j) => {
            if (Array.isArray(key)) {
                const tmpKey = item[key[0]].length > 0 ? key[0] : key[1]
                data[tmpKey] = item[tmpKey]
            } else {
                data[key] = item[key]
            }
        })
        return Object.values(data)
    }).map((row, i) => {
        const field = keys[i];
        return (<StyledTableRow key={i}>{row.map((txt, q) => (<TableCell key={q} row={row} txt={txt} rows={rows} field={keys} q={q} />))}</StyledTableRow>)
    })
}
export default RowsView;