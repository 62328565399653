import React from "react";
import PropTypes from 'prop-types';
import { alpha } from "@mui/material/styles";
import { InputAdornment, Tooltip } from "@material-ui/core";
import {
  StyledFormControl,
  StyledInputText,
  StyledInputLabel,
} from "../StyledComponents";
import { IMaskInput } from 'react-imask';
import NumberFormat from 'react-number-format';
import useStyles from "./styles";
import { HelpOutline } from "@material-ui/icons";
import i18n from "../../../i18n";
import { withStyles } from "@mui/styles";
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(#00) 000-0000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { id: props.id[0], value } })}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            id: props.id,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
});

NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};


const MobileInput = ({
  id,
  label,
  variant,
  className,
  style,
  defaultValue,
  onChange,
  otherOptions,
  value,
}) => {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    textmask: '00000000000',
    numberformat: '1320',
  });
  return (
    <StyledFormControl className={classes.margin} >
      <StyledInputLabel variant="outlined" htmlFor="bootstrap-input">
        {label}
      </StyledInputLabel>
      <StyledInputText
        {...otherOptions}
        endAdornment={
          i18n.exists(`helper_tooltip.${id}`) && <HelperInput id={id} />
        }
        defaultValue={defaultValue?defaultValue:values.textmask}
        id={id}
        value={value}
        onChange={onChange}
        inputComponent={TextMaskCustom}
      />
    </StyledFormControl>
  );
};

const StyledInputAdornment = withStyles({
  positionEnd: {
    paddingRight: "0",
  },
})(InputAdornment);

const HelperInput = ({ id }) => {
  return (
    <StyledInputAdornment position="end">
      <Tooltip title={i18n.t(`helper_tooltip.${id}`)} placement="bottom">
        <HelpOutline />
      </Tooltip>
    </StyledInputAdornment>
  );
};
export { MobileInput };
