import { FormControl } from "@material-ui/core";
// import { withStyles } from "@material-ui/styles";
import { withStyles,alpha } from '@material-ui/core/styles';
import { DropzoneArea } from 'material-ui-dropzone';

const StyledFileUpload = withStyles((theme) => ({
    previewChip: {
        minWidth: 160,
        maxWidth: 210
    }
}))(DropzoneArea);
export { StyledFileUpload };
