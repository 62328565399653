import React, { useState } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

import i18n from "i18next";
import {
  Chip,
  Paper,
  Button,
  Tooltip,
  Grid,
  FormControlLabel,
  Switch,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  Business,
  PersonPin,
  LocalShipping,
  Coronavirus,
  EmojiPeople,
  Loop,
  Edit,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import EditIcon from "@mui/icons-material/Edit";
import { ListView } from "../../../components/ListView";
const possibility_to_attach_warning_signs_colors = [
  "disabled",
  "warning",
  "error",
  "success",
  "secondary",
  "primary",
];
const services_list = [
  "move",
  "demontage",
  "montage",
  "entsorgung",
  "einpackservice",
  "auspackservice",
  "ausmalen",
  "reinigung",
];
const TasksFields = [
  {
    type: "refrence",
    label: i18n.t("events.id"),
    id: "id",
    onlyLink: true,
    show_list: false,
  },
  {
    type: "select",
    label: i18n.t("tasks.status"),
    id: "status",
    show_list: true,
    data: [
      { value: 1, text: i18n.t("tasks_status.created") },
      { value: 2, text: i18n.t("tasks_status.pending") },
      { value: 3, text: i18n.t("tasks_status.accepted") },
      { value: 4, text: i18n.t("tasks_status.doing") },
      { value: 5, text: i18n.t("tasks_status.done") },
      { value: 6, text: i18n.t("events.on_hold") },
      { value: 7, text: i18n.t("tasks_status.cancelled") },
      { value: 8, text: i18n.t("tasks_status.undefined") },
    ],
  },
  {
    type: "text",
    label: i18n.t("events_table.customer_info"),
    id: "name",
    grid: { xs: 12, sm: 12, md: 12, lg: 12 },
    show_list: true,
    format: (e) => (
      <span>
        {e.title && `${e.title} -`} {e.name} {e.phone}
      </span>
    ),
  },
  {
    type: "text",
    label: i18n.t("tasks.email"),
    id: "email",
    grid: { xs: 12, sm: 12, md: 12, lg: 12 },
    show_list: true,
  },
  {
    type: "text",
    label: i18n.t("tasks.price"),
    id: "price",
    grid: { xs: 12, sm: 12, md: 12, lg: 12 },
    show_list: true,
    format: (e) => `${e.price} €`,
  },
  {
    type: "text",
    label: i18n.t("events_table.services"),
    id: "umzugsplan_id",
    show_list: true,
    grid: { xs: 12, sm: 12, md: 12, lg: 12 },
    format: (e) => (
      <span>
        {services_list
          .map((item) => e[item] && item)
          .filter((item) => item !== false).length > 1
          ? services_list
              .map((item) => e[item] && item)
              .filter((item) => item !== false)
              .map((item, key) => {
                if (
                  key <
                  services_list
                    .map((item) => e[item] && item)
                    .filter((item) => item !== false).length -
                    1
                )
                  return (
                    i18n.t(`services.${item}`) +
                    " " +
                    i18n.t("services.and") +
                    " "
                  );
                else return item;
              })
          : "None"}
      </span>
    ),
  },
  {
    type: "text",
    id: "destination",
    grid: { xs: 12, sm: 12, md: 12, lg: 12 },
    label: i18n.t("events_table.destination_address"),
    show_list: true,
    format: (e) =>
      e.destination.map((item, key) => (
        <Grid container justifyContent="center" alignItems="center">
          <Grid item>
            <WarningAmberIcon
              color={
                possibility_to_attach_warning_signs_colors[
                  parseInt(e.origin_possibility_to_attach_warning_signs - 1)
                ]
              }
            />
          </Grid>
          <Grid item>
            {item.transportation && (
              <Chip icon={<Loop />} label={item.transportation} />
            )}
          </Grid>
          <Grid item>
            {item.total_truck && (
              <Chip icon={<LocalShipping />} label={item.total_truck} />
            )}
          </Grid>
          <Grid item>
            {item.address}{" "}
            {key < e.destination.length - 1 && i18n.t("services.and")}{" "}
          </Grid>
        </Grid>
      )),
  },
  {
    type: "text",
    id: "origin_address",
    grid: { xs: 12, sm: 12, md: 12, lg: 12 },
    label: i18n.t("events_table.origin_address"),
    show_list: true,
    format: (e) => (
      <span style={{ display: "flex", alignItems: "center" }}>
        <WarningAmberIcon
          color={
            possibility_to_attach_warning_signs_colors[
              parseInt(e.origin_possibility_to_attach_warning_signs - 1)
            ]
          }
        />
        {e.origin_address}
      </span>
    ),
  },

  {
    type: "text",
    id: "services",
    grid: { xs: 12, sm: 12, md: 12, lg: 12 },
    label: i18n.t("events_table.origin_address"),
    show_list: true,
    format: (e) => <div>eee</div>,
  },
];
const EventsTable = (props) => {
  const { t } = useTranslation();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [descriptions, setDescriptions] = useState([]);
  const [dateRange, setDateRang] = useState([null, null]);
  const { meetings } = props;
  const [enableSetRang, setEnableSetRang] = useState(false);

  const status_vals = [
    t("tasks_status.created"),
    t("tasks_status.pending"),
    t("tasks_status.accepted"),
    t("tasks_status.doing"),
    t("tasks_status.done"),
    t("tasks_status.on_hold"),
    t("tasks_status.cancelled"),
  ];

  function dataFormat(result) {
    const data = {
      id: result.id,
      services: Object.entries(result)
        .filter((item) => item[1] === true)
        .map((item) => t(`services.${item[0]}`)),
      time: {
        status: result.status,
        meeting_time: result.meeting_time,
        start_time: result.start_time,
        end_time: result.end_time,
      },
      customer_info: {
        contract_type: result.contract_type,
        title: result.title,
        name: result.name,
        company_name: result.company_name,
        company_title: result.company_title,
        phone: result.phone,
      },
      price: result.price,
      createdAt: result.createdAt,
      origin_address: {
        origin_address: result.origin_address,
        possibility_to_attach_warning_signs:
          result.origin_possibility_to_attach_warning_signs,
      },
      destination: result.destination.map((item) => {
        return {
          address: item.address,
          possibility_to_attach_warning_signs:
            item.possibility_to_attach_warning_signs,
        };
      }),
      status: result.status,
    };
    return { ...data };
  }
  // const columns = [
  //   {
  //     id: "customer_info",
  //     label: t("events_table.customer_info"),
  //     format: (val) =>
  //       val.contract_type === 1 ? (
  //         <div>
  //           {val.title} - {val.name}
  //           <br />
  //           {val.phone}
  //         </div>
  //       ) : (
  //         <div>
  //           {val.company_title} - {val.company_name}
  //           <br />
  //           {val.phone}
  //         </div>
  //       ),
  //     // format: (val) =>(<div>{JSON.stringify(val.contract_type)}</div>),
  //     minWidth: 120,
  //   },
  //   {
  //     id: "time",
  //     label: t("events_table.time"),
  //     minWidth: 195,
  //     format: (val) =>
  //       val.status === 1 ? (
  //         <div>
  //           <Tooltip title={moment(val.meeting_time).fromNow()} arrow>
  //             <div>
  //               {t("events.meeting_time")}:
  //               {moment(val.meeting_time).format("l")}
  //             </div>
  //           </Tooltip>
  //         </div>
  //       ) : (
  //         <div>
  //           <Tooltip title={moment(val.start_time).fromNow()} arrow>
  //             <div>
  //               {t("events.start_time")}: {moment(val.start_time).format("l")}
  //             </div>
  //           </Tooltip>
  //           <br />
  //           <Tooltip title={moment(val.end_time).fromNow()} arrow>
  //             <div>
  //               {" "}
  //               {t("events.end_time")}: {moment(val.end_time).format("l")}
  //             </div>
  //           </Tooltip>
  //         </div>
  //       ),
  //   },
  //   {
  //     id: "origin_address",
  //     label: t("events_table.origin_address"),
  //     minWidth: 200,
  //     format: (val) => (
  //       <div>
  //         {
  //           possibility_to_attach_warning_signs_colors[
  //             parseInt(val.possibility_to_attach_warning_signs)
  //           ]
  //         }
  //         <WarningAmberIcon
  //           color={
  //             possibility_to_attach_warning_signs_colors[
  //               parseInt(val.possibility_to_attach_warning_signs)
  //             ]
  //           }
  //         />
  //         {val.origin_address}
  //       </div>
  //     ),
  //   },
  //   {
  //     id: "price",
  //     label: t("events_table.price"),
  //     minWidth: 50,
  //     format: (val) => `${val} €`,
  //   },
  //   {
  //     id: "status",
  //     label: "Status",
  //     format: (val) => status_vals[val - 1],
  //   },
  //   {
  //     id: "destination",
  //     label: t("events_table.destination_address"),
  //     minWidth: 200,
  //     align: "right",
  //     format: (value) =>
  //       value.map((item, key) => (
  //         <span key={key}>
  //           <WarningAmberIcon
  //             color={
  //               possibility_to_attach_warning_signs_colors[
  //                 parseInt(item.origin_possibility_to_attach_warning_signs - 1)
  //               ]
  //             }
  //           />
  //           {item.address} {key < value.length - 1 && t("services.and")}{" "}
  //         </span>
  //       )),
  //   },
  //   {
  //     id: "services",
  //     label: t("events_table.services"),
  //     align: "right",
  //     format: (value) =>
  //       value.map((item, key) => (
  //         <span key={key}>
  //           {item} {key < value.length - 1 && t("services.and")}{" "}
  //         </span>
  //       )),
  //   },
  //   {
  //     id: "id",
  //     label: t("events_table.actions"),
  //     align: "left",
  //     format: (value) => (
  //       <Button
  //         onClick={() => props.history.push(`/app/tasks/view/${value}`)}
  //         component="span"
  //       >
  //         <EditIcon />
  //       </Button>
  //     ),
  //   },
  // ];

  const onChangeDescriptionSwitch = (e) => {
    if (descriptions.filter((item) => item === e).length < 1) {
      setDescriptions((prevState) => [...prevState, e]);
    } else {
      const temp = descriptions.filter((item) => item !== e);
      setDescriptions(temp);
    }
  };
  return (
    <Paper sx={{ width: "100%", overflow: "hidden", padding: "5px" }}>
      <Grid container>
        <Grid item xs={12} md={12} lg={12} sm={12}>
          <Button onClick={() => setEnableSetRang(!enableSetRang)}>
            {enableSetRang ? "Hidden" : "Show"} Date Rang
          </Button>
        </Grid>
        <Grid item xs={12} md={12} lg={12} sm={12}>
          {enableSetRang && (
            <DateRange
              editableDateInputs={true}
              onChange={(item) => props.onChangeDateRange([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={[
                {
                  startDate: new Date(props.dateRange[0].startDate),
                  endDate: new Date(props.dateRange[0].endDate),
                  key: "selection",
                },
              ]}
              // ranges={props.dateRange}
            />
          )}
        </Grid>
      </Grid>
      {meetings.length < 1 && <Grid item>{t("not_found")}</Grid>}
      <ListView
        localData={meetings}
        notFoundTitle="global_not_found"
        // controller={"tasks"}
        headerContent={(e) => {
          const start = moment(new Date(e.start_time));
          const end = moment(new Date(e.end_time));
          return (
            <span
              style={{
                display: "flex",
                justifyContent: "flex-start",
                paddingBottom: 5,
              }}
            >
              <Tooltip
                title={`${moment(e.start_time).fromNow()} - ${end.from(start)}`}
                arrow
              >
                <Chip
                  label={
                    <b>
                      {moment(e.start_time).format("DD/MM/YYYY")} -{" "}
                      {moment(e.start_time).format("HH:mm")}
                    </b>
                  }
                />
              </Tooltip>
            </span>
          );
        }}
        footerContent={(e) => (
          <Grid justifyContent="flex-end" container spacing={2}>
            {descriptions.filter((item) => item === e.id).length > 0 && (
              <Grid item style={{ margin: 12 }} xs={12} md={12} sm={12} lg={12}>
                <Chip label={e.description} />
              </Grid>
            )}
            {e.description && e.description.length > 0 && (
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      value={
                        descriptions.filter((item) => item === e.id).length > 0
                          ? true
                          : false
                      }
                      onChange={(w) => onChangeDescriptionSwitch(e.id)}
                    />
                  }
                  label="Description"
                />
              </Grid>
            )}
            <Grid item>
              <Chip
                avatar={
                  <React.Fragment>
                    {e.contract_type === 1 ? <PersonPin /> : <Business />}
                    {e.pcr_test === 1 && <Coronavirus />}
                  </React.Fragment>
                }
              />
            </Grid>
            <Grid item>
              {e.people && <Chip icon={<EmojiPeople />} label={e.people} />}
            </Grid>
            <Grid item>
              {e.transportation && (
                <Chip icon={<Loop />} label={e.transportation} />
              )}
            </Grid>
            <Grid item>
              {e.total_truck && e.total_truck.length > 0 && (
                <Chip icon={<LocalShipping />} label={e.total_truck} />
              )}
            </Grid>
            <Grid item>
              <Link to={`./tasks/view/${e.id}`}>
                <Button variant="outlined" startIcon={<Edit />}>
                  More
                </Button>
              </Link>
            </Grid>
          </Grid>
        )}
        fields={TasksFields}
        // pagination
      />
    </Paper>
  );
};
export { EventsTable };
