import React from "react";
import { Typography, Grid, Button } from "@mui/material";
import { StyledGrid } from "./StyledGrid";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
const Box = ({
  grid,
  edit,
  title,
  style,
  onClick,
  classes,
  subTitle,
  children,
  onClickEdit,
}) => {
  return (
    <StyledGrid
      className={classes}
      onClick={onClick}
      item
      {...grid}
      style={style}
    >
      {title && (
        <Grid container justifyContent={"space-between"}>
          <Grid item>
            <Typography variant="h6" component="div" gutterBottom>
              {title}
            </Typography>
          </Grid>
          {subTitle === true && (
            <Grid item>
              {/* <Button
                                onClick={onClickEdit}
                                style={{
                                    marginTop: 10,
                                    minWidth: 56,
                                    borderRadius: 0,
                                }}
                                variant="outlined"
                            >
                                {!edit ? <EditIcon /> : <CheckIcon />}
                            </Button> */}

              {/* <Typography
                                variant="h6"
                                component="div"
                                gutterBottom
                            >
                                ویرایش
                            </Typography> */}
            </Grid>
          )}
          {subTitle && subTitle !== true && subTitle()}
        </Grid>
      )}
      {children}
    </StyledGrid>
  );
};
export { Box };
