import Table from "@mui/material/Table";
import { withStyles } from "@mui/styles";

const StyledTable = withStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "",
  },
}))(Table);
export { StyledTable };
