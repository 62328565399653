import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import { makeStyles } from "@mui/styles";
import { Grid } from "@material-ui/core";
import { api } from "../../../services/api";
import { useSelector } from "react-redux"
import { InputCheck } from "../InputCheck";
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import moment from "moment"
import {
  InputText,
  InputSelect,
  DatePicker,
  InputRadio,
  InputButton,
  MobileInput,
  FileUploadInput
} from "..";
import toast from 'react-hot-toast';
import dotenv from "react-dotenv"
const axios = require('axios')

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: 8,
    },
  },
}));

const FormControl = ({default_data,requirement,create,read_only, refreshForm, disabled, column, onClickSubmit, withSubmit, subList, data, model, form, onChange, sendAfterComplete, fields, singleForm, localStore, afterSave, afterSaveNotify, subform,redirectTo, }) => {
  const {t} = useTranslation();
  const [created] = useState(false);
  const [disableCreateBTN, setDisableCreateBTN] = useState(false);
  const [localFields,setLocalFields] = useState([])
  const models = useSelector((state) => state.models)
  const [submitValue, setSubmitValue] = useState({});

  useEffect(() => {
    if(default_data){
      setSubmitValue(prevState=>({...prevState,...default_data}))
    }
  }, [default_data])
  useEffect(() => {
    if (
        requirement == 'all' &&
        (Object.values(submitValue).filter(
            (item) => item.length > 0 || moment(item).isValid()
        ).length <
            fields.filter((item) => !item.formDisable || item.id!=="id").length  ) && localFields.length<1
    ) {
        setDisableCreateBTN(true);
    } else if( localFields.length>0 &&
      requirement == 'all' &&
      (Object.values(submitValue).filter(
          (item) => item.length > 0 || moment(item).isValid()
      ).length <
          localFields.filter((item) => !item.formDisable).filter(item=>item.id!=="id").length )
    ){
      setDisableCreateBTN(true);
    }
    else {
      setDisableCreateBTN(false);
    }
}, [submitValue]);
  useEffect(() => {
    if (singleForm) {
      fields.map(item => {
        if (models[localStore] && models[localStore][item.id]) {
          const tmp = { target: { id: [item.id], value: models[localStore][item.id] } }
          handleOnchange(tmp)
        }
      })
    } else if (subList) {
      setSubmitValue(data)
    }
  }, [models, fields])
  useEffect(()=>{
    setLocalFields(fields)
  },[fields,refreshForm])

  const handleOnchange = (event) => {
    console.log("onChange")
    if (event.target) {
      const tmp = { [event.target.id]: event.target.value };
      setSubmitValue((prevState) => {
        return { ...prevState, ...tmp };
      });
      if(refreshForm) refreshForm({...submitValue,...tmp})
    } else {
    }

  };

  const onClick = () => {
    api
      .create({ controller: model, data: submitValue })
      .then((res) => {
        if (afterSaveNotify) {
          toast.success(afterSaveNotify)
        } else {
          toast.success("Created")
        }
        if (afterSave) {
          afterSave()
        }
      });
  };
  const AutoAddress = ({value,label,handleOnchange,onChange,id})=>{
    return(
      <GooglePlacesAutocomplete
      style={{ width: "100%" }}
      apiKey="AIzaSyDUb_he0UVZUDzi0LPT8zrTDi1G34DVKLk"
      autocompletionRequest={{
        componentRestrictions: {
          country: [
            "at",
            "br",
            "de",
            "ch",
            "dk",
            "it",
            "nl",
            "se",
          ],
        },
      }}
      selectProps={{
        value,
        onChange: console.log("ok"),
        // value:values[0].origin_address,
        label: label,
        id: id,
        // styles: {
        //   input: (provided) => ({
        //     ...provided,
        //     height: "100%",
        //     color: "blue",
        //   }),
        //   option: (provided) => ({
        //     ...provided,
        //     color: "blue",
        //   }),
        //   singleValue: (provided) => ({
        //     ...provided,
        //     color: "blue",
        //   }),
        // },
      }}
    />
    )
  }
  const FieldsComponent = (item, key) => {
    // if(item.id==="id") return(<div>{item.id}</div>)
    // else 
    if (item.hidden_form && item.FormComponent) {
      return (
        <Grid key={key} style={{ width: '100%' }} item xs={12} lg={12} sm={12}>
          {item.FormComponent()}
        </Grid>
      )
    } else {
      if (item.type == "hidden") return (
        <InputText
          id={item.id}
          customType="hidden"
          name={item.name}
          label={item.label}
          defaultValue={submitValue[item.id]}
          value={submitValue[item.id]}
          onChange={onChange || handleOnchange}
        />
      )
      return (
        <Grid key={key} style={{ width: '100%',paddingRight:item.type=="text" || item.type=="price"|| item.type=="password" || item.type=="email"?17:0 }} item xs={12} lg={column ? 12 / column : 12} sm={12}>
          {item.type === "select" && (
            <InputSelect
              id={item.id}
              label={item.label}
              controller={item.controller}
              value={submitValue[item.id]}
              onChange={onChange || handleOnchange}
              data={item.data}
              {...item}
            />
          )}
          {(item.type === "text" || item.type=="price" || item.type === "password" || item.type === "email") && (
            <InputText
              id={item.id}
              label={item.label}
              defaultValue={submitValue[item.id]}
              value={submitValue[item.id]}
              style={{paddingRight:33}}
              disabled={item.disabled || disabled}
              customType={item.customType}
              onChange={onChange || handleOnchange}
            />
          )}
          {item.type === "check" && (
            <InputCheck
              id={item.id}
              label={item.label}
              defaultValue={submitValue[item.id]}
              value={submitValue[item.id] || item.value}
              disabled={item.disabled || disabled}
              checked={submitValue[item.id] || item.value}
              onChange={onChange || handleOnchange}
            />
          )}
          {item.type == "phone" && (
            <MobileInput
              labelId="demo-simple-select-filled-label"
              id={item.id}
              label={item.label}
              value={submitValue[item.id]}
              onChange={onChange || handleOnchange}
            />
          )}
          {item.type == "datepicker" && (
            <DatePicker
              labelId="demo-simple-select-filled-label"
              id={item.id}
              label={item.label}
              value={submitValue[item.id]}
              onChange={onChange || handleOnchange}
            />
          )}
          {item.type == "radio" && (
            <InputRadio
              data={item.data}
              labelId="input radio"
              id={item.id}
              label={item.label}
              value={submitValue[item.id]}
              onChange={onChange || handleOnchange}
            />
          )}
          {item.type == "fileupload" && (
            <FileUploadInput
              labelId="demo-simple-select-filled-label"
              id={item.id}
              filesLimit={item.filesLimit}
              label={item.label}
              value={submitValue[item.id]}
              onChange={onChange || handleOnchange}
            />
          )}
          {item.type == "auto_address" && (
            <AutoAddress onChange={onChange} label={item.label} value={submitValue[item.id]} handleOnchange={handleOnchange} />
          )
          }
        </Grid>
      )
    }
  }
  return (
    <Grid container direction={!column ? "column" : "row"} justifyContent="center" alignItems="center" style={{ paddingRight: 18 }}>
      {refreshForm ? localFields.map((item,key)=>FieldsComponent(item, key)) :fields.map((item, key) => FieldsComponent(item, key))}
      {!read_only && (
        <Grid item xs={12} lg={12} sm={12}>
          <InputButton
            disabled={disableCreateBTN}
            onClick={onClick}
          >
            {create? t(`global.create`): t(`global.saveChange`)}
          </InputButton>
        </Grid>
      )}
      {created && redirectTo && <Redirect to={redirectTo} />}
    </Grid>
  );
};

export default FormControl