import React, { useEffect, useState } from "react";
import { Grid, IconButton, FormControl, OutlinedInput } from "@mui/material";
// import 'moment/locale/fa';
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import { ArrowBackIos } from "@mui/icons-material";
import { Link, useHistory } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { ListViewPagination } from "./ListViewPagination";
import i18n from "../../i18n";
import { api } from "../../services/api";
import ListViewLoading from "./ListViewLoading";
import { Box } from "./StyledComponents/BoxComponent";
import { InputUnstyledButton, InputText } from "../Forms";
import ValueRenderer from "../ValueRenderer/ValueRenderer";
import PageTitle from "../../components/PageTitle/PageTitle";
import EmptyResult from "../../pages/EmptyResult/EmptyResult";
const GridHandler = ({ fields, field, type }) => {
  const default_grid = { xs: 12, md: 4, lg: 3, sm: 4 };
  const fields_tmp = fields
    .map((item) => {
      return { id: item.id, grid: item.grid };
    })
    .filter((item) => item.id === field);
  if (fields_tmp[0] && fields_tmp[0].grid && fields_tmp[0].grid[type]) {
    return fields_tmp[0].grid[type];
  } else {
    const default_data = default_grid[type] ? default_grid[type] : 4;
    return default_data;
  }
};

const FieldContent = ({
  fields,
  field_key,
  field_value,
  autoTranslation,
  format,
}) => {
  const { t } = useTranslation();
  if (
    fields.filter((item) => item.id === field_key).length > 0 &&
    fields.filter((item) => item.id === field_key && item.show_list).length > 0
  ) {
    return (
      <Grid container key={field_key}>
        <Grid item>
          {autoTranslation &&
          fields.filter((item) => item.id === field_key && item.show_list)
            .length > 0
            ? t(`global_fields.${field_key}`)
            : fields
                .filter((item) => item.show_list)
                .filter((field) => field.id === field_key).length > 0 &&
              field_key !== "id"
            ? fields
                .filter((item) => item.show_list)
                .filter((field) => field.id === field_key)[0].label
            : "Label Not Found"}
          :&nbsp;
        </Grid>
        <Grid item>
          {format ? (
            <div>{format}</div>
          ) : (
            <ValueRenderer
              fields={fields}
              field_key={field_key}
              field_value={field_value}
            />
          )}
        </Grid>
      </Grid>
    );
  }
  return <React.Fragment />;
};

const ListView = ({
  page,
  small,
  title,
  where,
  style,
  fields,
  loading,
  withShow,
  dashboard,
  localData,
  itemAction,
  withSearch,
  withCreate,
  controller,
  notFoundTitle,
  avatar,
  footerContent,
  withPagination,
  autoTranslation,
  headerContent,
  searchable_fields,
  autoRefresh,
  external_path,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [contentLoading, setContentLoading] = useState(loading || true);
  const [listViewPage, setListViewPage] = useState(1);
  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(false);
  const [view, setView] = useState(false);
  const [userInfo, setuserInfo] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [showSearchInput, setShowSearchInput] = useState(false);
  const showFields = small
    ? fields.filter((item) => item.show_list).filter((item) => item.small)
    : fields.filter((item) => item.show_list);
  const onChangeSerachInput = (e) => {
    setSearchInput(e.target.value);
  };
  const onclickShowInput = () => {
    setShowSearchInput(!showSearchInput);
  };
  useEffect(() => {
    if (!localData && controller) {
      console.log("125");
      api
        .getList({
          controller: controller,
          page: page || listViewPage,
          select: showFields
            .filter((item) => item.show_list)
            .map((item) => item.id)
            .toString(),
          where:
            where !== "" || where !== undefined || where !== null ? where : "",
        })
        .then((res) => {
          setTimeout(() => {
            if (res.list) {
              setRows(res.list);
              setCount(res.count);
            } else {
              setRows(res);
            }
            setContentLoading(false);
          }, 300);
        });
    } else if (!loading) {
      console.log("149");

      if (localData.list) {
        setRows(localData.list);
        setCount(localData.count);
      } else {
        setRows(localData);
      }
      setTimeout(() => {
        setContentLoading(false);
      }, 400);
    }
  }, [page, listViewPage, localData, autoRefresh, loading]);

  const _onClickShow = (id) => {
    const path = external_path
      ? `./${external_path}/view/${id}`
      : `./view/${id}`;
    history.push(path);
  };

  const onClickSearch = (e) => {
    console.log("ok");
    setContentLoading(true);
    api
      .getList({
        controller: `${controller}`,
        page: page,
        where: searchInput,
        select: showFields
          .filter(
            (item) =>
              item.show_list &&
              item.fetch !== true &&
              item.type !== "select" &&
              item.type !== "radio" &&
              item.type !== "price" &&
              item.type !== "number"
          )
          .map((item) => item.id)
          .toString(),
        showFields: searchable_fields,
      })
      .then((res) => {
        setTimeout(() => {
          if (res.list) {
            setRows(res.list);
            setCount(res.count);
          } else {
            console.log("Not Found");
          }
          setContentLoading(false);
        }, 300);
      });
  };

  return (
    <Grid container style={style} justifyContent="flex-start">
      {title && (
        <PageTitle
          title={title && i18n.t(`global.${title}`)}
          controller={controller}
          withCreate={withCreate}
          searchField={() =>
            withSearch && (
              <React.Fragment>
                {showSearchInput ? (
                  <Grid item>
                    <Grid container direction="row" alignItems="center">
                      <Grid item>
                        <InputText
                          value={searchInput}
                          onChange={onChangeSerachInput}
                          variant="outlined"
                          type={"text"}
                          id={"first_name"}
                          searchIcon
                          onClickSearch={onClickSearch}
                          placeholder={t(`global_fields.search`)}
                        />
                      </Grid>
                      <Grid item>
                        <CloseIcon
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={onclickShowInput}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item>
                    <SearchIcon
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={onclickShowInput}
                    />
                  </Grid>
                )}
              </React.Fragment>
            )
          }
        />
      )}
      {searchable_fields && (
        <Grid item xs={12} md={12} lg={12} sm={12}>
          <FormControl sx={{ width: "45ch", marginBottom: 5 }}>
            <OutlinedInput
              disabled={loading}
              onChange={(e) => setSearchInput(e.target.value)}
              style={{ paddingLeft: 5 }}
              endAdornment={
                <IconButton onClick={onClickSearch}>
                  <SearchIcon />
                </IconButton>
              }
              value={searchInput}
              placeholder="Please enter text"
            />
          </FormControl>
        </Grid>
      )}
      {!contentLoading && rows.length < 1 && (
        <EmptyResult
          dashboard={dashboard}
          title={title}
          notFoundTitle={notFoundTitle}
        />
      )}
      {contentLoading ? (
        <ListViewLoading />
      ) : (
        rows &&
        rows.length > 0 &&
        rows.map((item, key) => (
          <Box
            component={Link}
            to={"#"}
            key={key}
            endBox={
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid item>{footerContent && footerContent(item)}</Grid>
                <Grid item>
                  {withShow && (
                    <InputUnstyledButton
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                      onClick={() => _onClickShow(item.id)}
                    >
                      {t(`global.view`)}
                    </InputUnstyledButton>
                  )}
                </Grid>
              </Grid>
            }
            title={item[title]}
            grid={{ xs: 12, md: 12, lg: 12 }}
            onClick={() => setView(!view)}
          >
            <Grid container>
              {headerContent && (
                <Grid item xs={12} sm={12} lg={12} md={12}>
                  {headerContent(item)}
                </Grid>
              )}
              {avatar && (
                <Grid item xs={12} md={1} lg={1} sm={1}>
                  {avatar(item)}
                </Grid>
              )}
              <Grid item xs={12} sm={10} md={10} lg={10}>
                <Grid container>
                  {Object.entries(item)
                    .filter((item) => item[1])
                    .filter((fld_itm) =>
                      showFields.map((fld) => fld.id).includes(fld_itm[0])
                    )
                    .map((vals, key) => (
                      <Grid
                        item
                        key={key}
                        xs={GridHandler({
                          fields: showFields,
                          field: vals[0],
                          type: "xs",
                        })}
                        md={GridHandler({
                          fields: showFields,
                          field: vals[0],
                          type: "md",
                        })}
                        lg={GridHandler({
                          fields: showFields,
                          field: vals[0],
                          type: "lg",
                        })}
                        sm={GridHandler({
                          fields: showFields,
                          field: vals[0],
                          type: "sm",
                        })}
                      >
                        <FieldContent
                          autoTranslation={autoTranslation}
                          field_value={vals[1]}
                          field_key={vals[0]}
                          format={
                            fields.filter((it) => it.id === vals[0])[0]
                              .format &&
                            fields
                              .filter((it) => it.id === vals[0])[0]
                              .format(item)
                          }
                          fields={showFields}
                        />
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            </Grid>
            {itemAction && itemAction(item)}
          </Box>
        ))
      )}
      {!contentLoading && withPagination && (
        <ListViewPagination
          handleOnChange={(e) => {
            setListViewPage(e);
          }}
          total={count}
          page={page || listViewPage}
        />
      )}
    </Grid>
  );
};
export { ListView };
