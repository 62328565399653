import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import { Box } from "@material-ui/core";

import {
  UserRoutes,
  WorkerRoutes,
  GuestRoutes,
  SecreterRoutes,
} from "./routes";
import {
  loadInitialDataSocket,
  initialItems,
  EXsocketIOClient,
} from "../../redux/actions/action";

import { setRoleStatus, setData } from "../../redux/features/user";
import { api } from "../../services/api";
// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";
import { useDispatch, useSelector } from "react-redux";
import {
  useLayoutState,
  toggleSidebar,
  useLayoutDispatch,
} from "../../context/LayoutContext";
function Layout(props) {
  const user = useSelector((state) => state.user.value);
  var classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    dispatch(loadInitialDataSocket({ history: props.history }));
    setTimeout(() => {
      // if (user.id) {
      //   console.log("is not null");
      // } else {
      //   localStorage.clear();
      //   console.log(localStorage.clear());
      // }
      setLoading(false);
    }, 500);
  }, [loading]);
  // io.socket.on("logout", function (msg) {
  //   console.log(msg);
  // });
  // io.socket.on("initialList", function (msg) {
  //   console.log(msg);
  // });
  // io.socket.on("user_logged_in", function (msg) {
  //   console.log(msg);
  // });
  // io.socket.on("logout", (res) => {
  //   console.log(res);
  //   console.log("user socket");
  // });
  // io.socket.on("initialList", (res) => {
  //   console.log(res);
  //   console.log("user socket");
  // });
  var layoutState = useLayoutState();
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  return (
    <div className={classes.root} onClick={(e) => layoutState.isSidebarOpened}>
      <Header history={props.history} />
      <Sidebar />
      <div
        className={classnames(classes.content, {
          [classes.contentShift]: layoutState.isSidebarOpened,
        })}
      >
        <div className={classes.fakeToolbar} />
        {user.role == 4 ? (
          <UserRoutes />
        ) : user.role == 3 ? (
          <SecreterRoutes />
        ) : user.role === 2 ? (
          <WorkerRoutes />
        ) : (
          <GuestRoutes />
        )}
        <Box
          mt={5}
          width={"100%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent="space-between"
        ></Box>
      </div>
    </div>
  );
}

export default withRouter(Layout);
