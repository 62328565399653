import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { api } from "../../../services/api";
import { Grid, Button } from "@mui/material";
const Uploader = ({
  body,
  url,
  uploadUrl,
  urls,
  afterUpload,
  footerContentBeforeUpload,
}) => {
  const [files, setFiles] = React.useState([]);
  const [filesSrc, setFilesSrc] = useState([]);
  let formData = new FormData();
  Object.entries(body).map((ii) => {
    formData.append(ii[0], ii[1]);
  });
  const uploadFunc = () => {
    formData.append(
      "attach",
      files.filter(
        (ii) => !filesSrc.map((qq) => qq.file_ref).includes(ii.path)
      )[0]
    );
    api
      .upload_file({
        controller: uploadUrl,
        data: formData,
      })
      .then((result) => {
        console.log(result.data);
        setFilesSrc((prevState) => [...prevState, result.data]);
        urls(result.data);
        afterUpload && afterUpload(result);
      });
  };
  const onDrop = React.useCallback((acceptedFiles) => {
    if (
      files.filter((ii) => !filesSrc.map((qq) => qq.file_ref).includes(ii.path))
        .length < 1
    )
      setFiles((prev) => [...prev, ...acceptedFiles]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const fileList = files.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes{" "}
      <Button
        onClick={() =>
          setFiles((prevState) => prevState.filter((i) => i.path != file.path))
        }
      >
        Delete
      </Button>
    </li>
  ));
  return (
    <Grid
      style={{ backgroundColor: "#eee", borderRadius: 10, padding: 15 }}
      item
      xs={12}
      md={12}
      lg={12}
      sm={12}
    >
      {files.filter(
        (ii) => !filesSrc.map((qq) => qq.file_ref).includes(ii.path)
      ).length > 0 &&
        footerContentBeforeUpload &&
        footerContentBeforeUpload()}
      <section className="container">
        <div
          {...getRootProps({
            className: "dropzone",
          })}
        >
          {files.filter(
            (ii) => !filesSrc.map((qq) => qq.file_ref).includes(ii.path)
          ).length < 1 && <input {...getInputProps()} />}
          <p
            style={{
              color:
                files.filter(
                  (ii) => !filesSrc.map((qq) => qq.file_ref).includes(ii.path)
                ).length < 1
                  ? "black"
                  : "gray",
            }}
          >
            Drag 'n' drop some files here, or click to select files
          </p>
        </div>

        <aside>
          <h4>Files</h4>
          <ul>{fileList}</ul>
        </aside>
      </section>
      <Button
        disabled={filesSrc.length >= files.length}
        onClick={() => uploadFunc()}
      >
        Upload
      </Button>
    </Grid>
  );
};
export { Uploader };
