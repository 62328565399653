import { createSlice } from "@reduxjs/toolkit";
import uniqid from "uniqid";
const defaultNew = {
  contract_type: 1,
  title: "",
  name: "",
  company_name: "",
  email: "",
  review: false,
  price: 0,
  company: 1,
  meeting_time: "",
  contract_price_type: 2,
  description: "",
  car_tag: "",
  status: 1,
  payment_status: 1,
  pcr_test: 1,
  trash: 0,
  origin_address: "",
  origin_hnr: "",
  origin_siege: "",
  origin_tur_nr: "",
  origin_lat: 48.208176,
  origin_long: 16.373819,
  origin_postcode: "",
  origin_city: "",
  origin_province: "",
  origin_stock: "",
  origin_floor: "",
  origin_plaque: "",
  origin_lift: 1,
  origin_apartment_area: "",
  origin_lift_load: "",
  origin_access_to_parking: 1,
  origin_distance_parking_to_home: 0,
  origin_more_than_80_furniture: 2,
  origin_number_of_furniture_over_80: "",
  origin_possibility_to_attach_warning_signs: 1,
  total_truck: 1,
  people: 2,
  transportation: 1,
  destination: [],
  destination_address: "",
  destination_lat: 48.208176,
  destination_long: 16.373819,
  destination_hnr: "",
  destination_siege: "",
  destination_tur_nr: "",
  destination_ort: "",
  destination_postcode: "",
  destination_city: "",
  destination_stock: "",
  destination_lift: 1,
  destination_lift_load: "",
  destination_apartment_area: "",
  destination_access_to_parking: 1,
  destination_distance_parking_to_home: "",
  destination_more_than_80_furniture: 2,
  destination_number_of_furniture_over_80: "",
  move: false,
  demontage: false,
  montage: false,
  entsorgung: false,
  einpackservice: false,
  auspackservice: false,
  nur_transport_von_a_nach_b: false,
  ausmalen: false,
  reinigung: false,
  help_service: false,
  repairing: false,
  distance: "",
  cargo_area: "",
  start_time: "",
  end_time: "",
  workers_id: [],
  inventory: [
    {
      id: 1,
      title: "Umzug",
      amount: 1,
      room: "",
      pictures: [],
      action: "",
    },
    {
      id: 2,
      title: "Entrümpelung",
      amount: 2,
      room: "",
      pictures: [],
      action: "",
    },
    {
      id: 3,
      title: "Demontage & Montage",
      amount: 0,
      room: "",
      pictures: [],
      action: "",
    },
    {
      id: 4,
      title: "Deponie",
      amount: 0,
      room: "",
      pictures: [],
      action: "",
    },
  ],
  status: 1,
};
const initialState = {
  new: defaultNew,
  list: [],
  view: [],
};

export const taskSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    refreshNew: (state, action) => {
      state.new = defaultNew;
    },
    addView: (state, action) => {
      const index = state.view.findIndex(
        (post) => post.id === action.payload.id
      );
      if (index > -1) {
        return {
          ...state,
          view: [
            ...state.view.slice(0, index),
            action.payload.data,
            ...state.view.slice(index + 1),
          ],
        };
      } else {
        return {
          ...state,
          view: [...state.view, action.payload.data],
        };
      }
    },
    list: (state, action) => {
      state.list = action.payload;
    },
    add: (state, action) => {
      state.new[action.payload.id] = action.payload.data;
    },
    edit_destination_in_view: (state, action) => {
      const index = state.view.findIndex(
        (item) => item.id == action.payload.task_id
      );
      if (index > -1) {
        let destinations = state.view[index].destination.map((item) => {
          if (item.id == action.payload.id) {
            delete action.payload.task_id;
            return action.payload;
          } else {
            return item;
          }
        });
        return {
          ...state,
          view: [
            ...state.view.slice(0, index),
            { ...state.view[index], destination: destinations },
            ...state.view.slice(index + 1),
          ],
        };
      }
    },
    delete_destination_in_view: (state, action) => {
      const index = state.view.findIndex(
        (item) => item.id == action.payload.task_id
      );
      const temp_data = action.payload;
      const task_id = temp_data.task_id;
      // console.log(state.list)
      // console.log("ewwew")
      // console.log(state)
      if (index > -1) {
        const destination = state.view[index].destination.filter(
          (item) => item.id !== action.payload.id
        );
        return {
          ...state,
          view: [
            ...state.view.slice(0, index),
            { ...state.view[index], destination: destination },
            ...state.view.slice(index + 1),
          ],
        };
      } else {
        console.log("view not found for delete");
      }
    },
    add_destination_in_view: (state, action) => {
      const temp_data = action.payload;
      const task_id = temp_data.task_id;
      // delete temp_data.task_id;
      // action.payload.id = randomstring.generate();
      action.payload.id = uniqid();
      const index = state.view.findIndex(
        (item) => item.id == action.payload.task_id
      );
      console.log(temp_data.task_id);
      console.log(state.view);
      console.log(state.view.slice(0, index));
      console.log(state.view[index]);
      state.view[index].destination = [
        ...state.view[index].destination,
        action.payload,
      ];
      return {
        ...state,
        view: [
          // ...state.view
          ...state.view.slice(0, index),
          {
            ...state.view[index],
            destination:
              state.view[index].destination.length > 0 &&
              state.view[index].destination !== null
                ? [...state.view[index].destination, action.payload]
                : [action.payload],
          },
          ...state.view.slice(index + 1),
        ],
      };
    },
    add_destination: (state, action) => {
      action.payload.id = uniqid();
      state.new.destination = [...state.new.destination, action.payload];
    },
    delete_destination: (state, action) => {
      const deletedDestination = state.new.destination.filter(
        (item) => item.id !== action.payload.id
      );
      state.new.destination = deletedDestination;
    },
    send_review: (state, action) => {
      const index = state.view.findIndex(
        (item) => item.id == action.payload.task_id
      );
      return {
        ...state,
        view: [
          ...state.view.slice(0, index),
          { ...state.view[index], [action.payload.id]: action.payload.data },
          ...state.view.slice(index + 1),
        ],
      };
    },
    updateField: (state, action) => {
      const index = state.view.findIndex(
        (item) => item.id == action.payload.task_id
      );
      return {
        ...state,
        view: [
          ...state.view.slice(0, index),
          { ...state.view[index], [action.payload.id]: action.payload.data },
          ...state.view.slice(index + 1),
        ],
      };
    },
    onChangeMarker: (state, action) => {
      if (action.payload.type === "view") {
        const index = state.view.findIndex(
          (post) => post.id === action.payload.id
        );
        state.view[index].origin_hnr = action.payload.origin_hnr;
        state.view[index].origin_city = action.payload.origin_city;
        state.view[index].origin_address = action.payload.origin_address;
        state.view[index].origin_postcode = action.payload.origin_postcode;
        state.view[index].origin_lat = action.payload.origin_lat;
        state.view[index].origin_long = action.payload.origin_long;
      } else {
        state.new.origin_address = action.payload.origin_address;
        state.new.origin_postcode = action.payload.origin_postcode;
        state.new.origin_lat = action.payload.origin_lat;
        state.new.origin_long = action.payload.origin_long;
        state.new.origin_hnr = action.payload.origin_hnr;
        state.new.origin_city = action.payload.origin_city;
      }
    },
    // onAddDestination:(state,action)=>{
    //   if(action.payload.view_id){
    //     const index = state.view.findIndex(post => post.id === action.payload.view_id)
    //     if(index>-1){
    //       console.log("Task Not Found")
    //     }else{
    //       if()
    //       return {
    //         ...state,
    //         view:
    //       }
    //     }
    //   }
    // },
    onAddInventory: (state, action) => {
      const index = state.new.inventory.findIndex(
        (post) => post.title === action.payload.title
      );
      if (index > -1) {
        return {
          ...state,
          new: {
            ...state.new,
            inventory: [
              ...state.new.inventory.slice(0, index),
              {
                title: action.payload.title,
                amount: action.payload.amount,
                room: action.payload.room,
                pictures: action.payload.pictures,
              },
              ...state.new.inventory.slice(index + 1),
            ],
          },
        };
      } else {
        return {
          ...state,
          new: {
            ...state.new,
            inventory: [
              ...state.new.inventory,
              {
                title: action.payload.title,
                amount: action.payload.amount,
                room: action.payload.room,
              },
            ],
          },
        };
      }
    },
    onUpdateInventoryNew: (state, action) => {
      const index = this.state.new.inventory.findIndex(
        (post) => post.title === action.payload.title
      );

      return {
        ...state,
        new: {
          ...state.new,
          inventory: [
            ...state.new.inventory.slice(0, index),
            {
              title: action.payload.title,
              amount: action.payload.amount,
              room: action.payload.room,
            },
            ...state.new.inventory.slice(index + 1),
          ],
        },
      };
    },
    onUpdateInventoryView: (state, action) => {
      const index = state.view.findIndex(
        (post) => post.id === action.payload.view_id
      );
      return {
        ...state,
        view: [
          ...state.view.slice(0, index),
          action.payload.data,
          ...state.view.slice(index + 1),
        ],
      };
    },
    onChangeMarkerDestination: (state, action) => {
      if (action.payload.type === "view") {
        const index = state.view.findIndex(
          (post) => post.id === action.payload.id
        );
        state.view[index].destination_hnr = action.payload.destination_hnr;
        state.view[index].destination_city = action.payload.destination_city;
        state.view[index].destination_address =
          action.payload.destination_address;
        state.view[index].destination_postcode =
          action.payload.destination_postcode;
        state.view[index].destination_lat = action.payload.destination_lat;
        state.view[index].destination_long = action.payload.destination_long;
      } else {
        state.new.destination_hnr = action.payload.destination_hnr;
        state.new.destination_city = action.payload.destination_city;
        state.new.destination_address = action.payload.destination_address;
        state.new.destination_postcode = action.payload.destination_postcode;
        state.new.destination_lat = action.payload.destination_lat;
        state.new.destination_long = action.payload.destination_long;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  list,
  add,
  onChangeMarker,
  add_destination_in_view,
  add_destination,
  delete_destination,
  delete_destination_in_view,
  edit_destination_in_view,
  updateField,
  send_review,
  onAddInventory,
  onChangeMarkerDestination,
  addView,
  onUpdateInventoryView,
  refreshNew,
} = taskSlice.actions;

export default taskSlice.reducer;
