import React, { useEffect, useState } from "react";
import { Grid, Button, Select } from "@material-ui/core";
import { InputText, InputSelect } from "../../../../components/Forms";
import { useSelector, useDispatch } from "react-redux";
import { setRoleStatus, setData } from "../../../../redux/features/user";
import { updateField } from "../../../../redux/features/tasks";
import { api } from "../../../../services/api";
import TitleComponent from "../TitleComponent";
import { useTranslation } from "react-i18next";
const role_text = ["General User", ""];
const contract_type = {
  type: "radio",
  label: "Contract Type",
  id: "contract_type",
  data: [
    { value: 1, label: "Personal" },
    { value: 2, label: "Company" },
  ],
};
// const status_data = [
//   { value: 1, text: "Created" },
//   { value: 2, text: "Pending" },
//   { value: 3, text: "Accepted" },
//   { value: 4, text: "Doing" },
//   { value: 5, text: "Done" },
//   { value: 6, text: "On Hold" },
//   { value: 7, text: "Cancelled" },
// ];
const role_data = [
  { value: 1, text: "General User" },
  { value: 2, text: "Worker" },
  { value: 3, text: "Secreter" },
  { value: 4, text: "Admin" },
];
const ProfileInformation = (prop) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.value);
  const [values, setValues] = useState({});
  const [editable, setEditable] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    const user_tmp = {
      email: user.email,
      mobile: user.mobile,
      first_name: user.first_name,
      last_name: user.last_name,
      role: user.role,
      status: user.status,
    };
    setValues(user_tmp);
    setEditable(false);
  }, [user]);
  const onSave = () => {
    api.updateOne(
      { controller: "user", id: user.user_id, data: values },
      (res) => {
        dispatch(
          setData({
            first_name: res.data.first_name,
            last_name: res.data.last_name,
            role: res.data.role,
            email: res.data.email,
            mobile: res.data.mobile,
          })
        );
      }
    );
  };
  const onChangeOtherFields = ({ id, value }) => {
    setValues((prevState) => {
      return { ...prevState, [id]: value };
    });
    // if(e.target.id){

    // }else{
    //     console.log(e.target)
    // }
  };
  return (
    <Grid container>
      {user.role > 2 && (
        <TitleComponent
          title={t("profile_page.personal_info")}
          setEditable={() => setEditable(!editable)}
          editable={editable}
        />
      )}
      <Grid item xs={10} lg={10} sm={10} md={10}>
        <Grid container>
          <Grid item xs={12} sm={12} lg={6} md={6}>
            <InputText
              disabled={user.role < 3 ? true : !editable}
              label={t("user_fields.first_name")}
              onChange={(e) => onChangeOtherFields(e.target)}
              id="first_name"
              value={values.first_name}
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={6} md={6}>
            <InputText
              disabled={user.role < 3 ? true : !editable}
              label={t("user_fields.last_name")}
              onChange={(e) => onChangeOtherFields(e.target)}
              id="last_name"
              value={values.last_name}
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={6} md={6}>
            <InputText
              disabled={user.role < 3 ? true : !editable}
              label={t("user_fields.email")}
              onChange={(e) => onChangeOtherFields(e.target)}
              id="email"
              value={values.email}
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={6} md={6}>
            <InputText
              disabled={user.role < 3 ? true : !editable}
              label={t("user_fields.mobile")}
              onChange={(e) => onChangeOtherFields(e.target)}
              id="mobile"
              value={values.mobile}
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={12} md={12}>
            <InputSelect
              disabled={user.role < 3 ? true : !editable}
              data={role_data}
              id="role"
              value={values.role}
              onChange={(e) => onChangeOtherFields(e.target)}
              label={t("user_fields.role")}
            />
          </Grid>
        </Grid>
      </Grid>
      {editable && (
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Button onClick={() => onSave()}>{t("user_fields.save")}</Button>
        </Grid>
      )}
    </Grid>
  );
};
export default ProfileInformation;
