import React from "react";
import toast from 'react-hot-toast';
import i18n from "../../i18n"

const ErrorHandler = (status) => {
  return toast.error(i18n.t(`err.${status}`))
};
const MessageHandler = (message)=>{

  const {type} = message;
  switch(type){
    case 'info':
      return toast.info("info")
  }
  // return ()=>{
  //   switch (type){
  //     case 'info':
  //       toast.info("check")
  //   }
  // }
}
export {MessageHandler,ErrorHandler};
