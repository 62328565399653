import { InputLabel } from "@material-ui/core";
import { withStyles } from "@mui/styles";

const StyledInputLabel = withStyles((theme) => ({
  root: {
    background: "#fff",
    padding: "8px !important",
  },
  focused: {
    borderRadius: "none",
    border: "1px solid",
    padding: "8px",
  },
  shrink: {
    borderRadius: "none",
    border: "1px solid",
    padding: "5px !imortant",
    marginLeft: "-4px",
  },
}))(InputLabel);
const StyledInputLabelActive = withStyles((theme) => ({
  root: {
    background: "#fff",
    padding: "5px !important",
    left: 0,
    fontSize: '12px !important',
    border: '1px solid #536DFE'
  },
  focused: {
    borderRadius: "none",
    padding: "8px",
  },
  outlined:{
    marginTop: '-28px'
  },
  shrink: {
    borderRadius: "none",
    border: "1px solid",
    padding: "5px !imortant",
    marginLeft: "-4px",
    transform:'none',
    marginLeft: '10px',
    marginTop: '0px'
  },
}))(InputLabel);
export { StyledInputLabel,StyledInputLabelActive };
