import * as React from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { InputText } from '../InputText';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimePicker from '@mui/lab/TimePicker';
import DateTimePicker from '@mui/lab/DateTimePicker';
import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
// import DateTimePicker from '@mui/x-date-pickers/DateTimePicker';
// import { withStyles } from "@mui/styles";
import { StyledInputText,StyledInputLabel,StyledFormControl } from '../StyledComponents';
import { makeStyles, withStyles } from "@material-ui/core/styles";
// import { makeStyles } from "@mui/styles";
// import StaticTimePicker from '@mui/x-date-pickers/StaticTimePicker';

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  datePicker:{
    margin:10
  },
  textField: {
    marginLeft: 8,
    marginRight: 8,
    width: 200,
  },
}));

const CssTextField = withStyles({
  root: {
    // margin:10,
    paddingRight: 3,
    width: "100%",
    margin: 10,
    position: "relative",
    backgroundColor: "#fcfcfb",
    borderRadius: "none",
    "& label.Mui-focused": {
      border: "1px solid #b9b9b8",
      padding: 5,
      borderRadius: "none",
    },
    "& label.MuiFormLabel-root": {
      padding: 5,
      background: "#fff",
      borderRadius: "none",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      border: "1px solid",
      marginLeft: "-4px",
      minWidth: 65,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "blue",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 0,
      "& fieldset": {
        borderColor: "light-dark",
      },
      "&:hover fieldset": {
        borderColor: "black",
      },
      "&.Mui-focused fieldset": {
        borderColor: "blue",
      },
    },
  },
})(TextField);
const DatePicker = ({
  disableToolbar,
  variant,
  format,
  margin,
  id,
  label,
  value,
  disabled,
  onChange,
}) => {
  const classes = useStyles();
  return (
    <LocalizationProvider  dateAdapter={AdapterDateFns}>
        {/* <StaticTimePicker
          displayStaticWrapperAs="mobile"
          value={value}
          onChange={(e)=>{
            const tmp = {target:{id:id,value:e}}
            onChange(tmp)
          }}
          renderInput={(params) => <TextField {...params} />}
        /> */}

      <Stack spacing={3}>
        <div style={{margin: '10px 0px 10px 10px'}}>
          <DateTimePicker
            label={label}
            id={id}
            // style={{margin:10}}
            ampm={false}
            disabled={disabled}
            value={value}
            className={classes.datePicker}
            onChange={(e)=>{
              console.log(e)
              const tmp = {target:{id:id,value:e}}
              onChange(tmp)
            }}
            renderInput={(params) => <CssTextField fullWidth {...params} />}
          />
        </div>
      </Stack>
    </LocalizationProvider>



    // <CssTextField
    //   onChange={onChange}
    //   id={id}
    //   label={label}
    //   type="datetime"
    //   defaultValue="2021-03-01 07:30"
    //   className={classes.textField}
    //   InputLabelProps={{
    //     shrink: true,
    //   }}
    //   variant="outlined"
    //   inputProps={{
    //     step: 300, // 5 min
    //   }}
    // />
  );
};

export { DatePicker };
