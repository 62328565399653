import * as React from "react";
import {
  Grid,
  ImageList,
  Chip,
  ImageListItem,
  ImageListItemBar,
  ListSubheader,
  Stack,
  IconButton,
  Button,
  CardMedia,
} from "@mui/material";
import { Link } from "react-router-dom";

import InfoIcon from "@mui/icons-material/Info";
import { api } from "../../services/api";
const FullSizeItem = ({ data, toBack }) => {
  return (
    <Grid container>
      <Grid item xs={12} lg={12} sm={12} md={12}>
        <Button onClick={toBack}>Back</Button>
      </Grid>
      <Grid item xs={12} lg={12} sm={12} md={12}>
        <img
          sx={{ height: "100%", width: "100%" }}
          src={data.full_size}
          title={data.title}
        />
      </Grid>
    </Grid>
  );
};
const Gallery = (props) => {
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [fullScreen, setFullScreen] = React.useState(false);
  const [category, setCategory] = React.useState([
    ...new Set(props.data.map((ii) => ii.category)),
  ]);
  const [enabledCategory, setEnabledCategory] = React.useState([
    ...new Set(props.data.map((ii) => ii.category)),
  ]);
  React.useEffect(() => {
    setEnabledCategory([...new Set(props.data.map((ii) => ii.category))]);
  }, [props.data]);
  React.useEffect(() => {
    setCategory([...new Set(props.data.map((ii) => ii.category))]);
  }, [props.data]);
  React.useEffect(() => {}, []);
  const syncAttached = () => {
    api
      .create({
        controller: "messages/attached_files",
        data: { email: props.email, id: props.imap },
      })
      .then((qq) => {
        setData(qq.data);
        setLoading(false);
      });
  };
  const handleClick = () => {
    console.info("You clicked the Chip.");
  };
  const thumbnailIcon = [
    {
      name: "pdf",
      address: "",
    },
  ];
  if (props.loading)
    return (
      <React.Fragment>
        <Button onClick={() => syncAttached()}>Show Attached</Button>
      </React.Fragment>
    );
  else if (fullScreen)
    return (
      <FullSizeItem
        toBack={() => setFullScreen(false)}
        data={props.data.filter((ii) => ii.id == fullScreen)[0]}
      />
    );
  else
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12} sm={12}>
          <Stack direction="row" spacing={1}>
            {[...new Set(props.data.map((ii) => ii.category))].map((qq) => (
              <Chip
                key={qq}
                label={qq}
                variant={
                  enabledCategory.filter((ii) => ii == qq).length < 1
                    ? "outlined"
                    : "filled"
                }
                onClick={(e) => {
                  enabledCategory.filter((ii) => ii == qq).length > 0
                    ? setEnabledCategory((prevState) =>
                        prevState.filter((ii) => ii != qq)
                      )
                    : setEnabledCategory((prevState) => [...prevState, qq]);
                }}
              />
            ))}
          </Stack>
        </Grid>
        <Grid item xs={12} md={12} lg={9} sm={12}>
          {/* {JSON.stringify(props.data.filter((ii) => ii.id == fullScreen)[0])} */}

          <ImageList
            variant="masonry"
            sx={{ minHeight: 450 }}
            cols={5}
            // rowHeight={164}
          >
            {props.data &&
              props.data
                .filter((ii) => enabledCategory.includes(ii.category))
                .map((item, key) => (
                  <ImageListItem
                    style={{ border: "1px solid #bdbdbd", padding: 5 }}
                    // sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                    key={item.file_name}
                    onClick={() =>
                      item.thumbnail.split("//").length > 1
                        ? setFullScreen(item.id)
                        : console.log("file")
                    }
                  >
                    {item.thumbnail.split("//").length > 1 ? (
                      <img
                        src={`${item.thumbnail}`}
                        srcSet={`${item.thumbnail}`}
                        alt={item.title}
                        height={200}
                        loading="lazy"
                      />
                    ) : (
                      <img
                        src={`${"https://gallery.umzugsritter.at/icons/files.png"}`}
                        srcSet={`${"https://gallery.umzugsritter.at/icons/files.png"}`}
                        alt={"item.title"}
                        height={200}
                        loading="lazy"
                      />
                    )}
                    <ImageListItemBar
                      title={item.title}
                      subtitle={
                        <span>
                          @{item.category} (
                          {`${
                            item.thumbnail.split("//").length < 2 &&
                            item.thumbnail
                          }`}
                          )
                        </span>
                      }
                      position="below"
                      actionIcon={
                        item.thumbnail.split("//").length < 2 ? (
                          <IconButton
                            color="secondary"
                            onClick={() => window.open(item.full_size)}
                            aria-label={`info about ${item.title}`}
                          >
                            <InfoIcon />
                          </IconButton>
                        ) : (
                          ""
                        )
                      }
                    />
                  </ImageListItem>
                ))}
          </ImageList>
        </Grid>
        <Grid item xs={12} md={12} sm={12} lg={3}>
          {props.header && props.header()}
        </Grid>
      </Grid>
    );
};
export { Gallery };
