import { FormControl } from "@material-ui/core";
// import { withStyles } from "@material-ui/styles";
import { withStyles, alpha } from "@material-ui/core/styles";

const StyledFormControl = withStyles((theme) => ({
  root: {
    paddingRight: 3,
    // width: "100%",
    margin: 10,
    border: "1px solid #ced4da",
    position: "relative",
    backgroundColor: "#fcfcfb",
    borderRadius: 0,
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    padding: "10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:hover": {
      backgroundColor: "#fff",
    },
    // "&$focused": {
    //   backgroundColor: "#fff",
    //   boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
    //   borderColor: theme.palette.primary.main,
    // },
  },
}))(FormControl);
export { StyledFormControl };
