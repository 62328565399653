import i18n from "i18next";
const MessageFields = [
  {
    type: "refrence",
    label: "id",
    id: "id",
    onlyLink: true,
    hidden_form: true,
    show_list: false,
  },
  {
    type: "text",
    label: "Customer",
    id: "email",
    show_list: true,
  },
  // {
  //   type: "text",
  //   label: i18n.t("user_fields.last_name"),
  //   id: "last_name",
  //   show_list: true,
  // },
  // {
  //   type: "password",
  //   label: i18n.t("user_fields.password"),
  //   id: "password",
  //   show_list: false,
  // },
  // {
  //   type: "text",
  //   label: i18n.t("user_fields.mobile"),
  //   id: "mobile",
  //   show_list: true,
  // },
  // {
  //   type: "select",
  //   label: i18n.t("user_fields.role"),
  //   show_list: true,
  //   id: "role",
  //   defaultsTo: 1,
  //   data: [
  //     { value: 1, text: i18n.t("user_fields.role_types.general") },
  //     { value: 2, text: i18n.t("user_fields.role_types.worker") },
  //     { value: 3, text: i18n.t("user_fields.role_types.secreter") },
  //     { value: 4, text: i18n.t("user_fields.role_types.admin") },
  //   ],
  // },
  // {
  //   type: "select",
  //   label: i18n.t("user_fields.status"),
  //   id: "status",
  //   show_list: true,
  //   defaultsTo: 1,
  //   data: [
  //     { value: 1, text: i18n.t("user_fields.status_types.inactive") },
  //     { value: 2, text: i18n.t("user_fields.status_types.active") },
  //     { value: 3, text: i18n.t("user_fields.status_types.suspended") },
  //   ],
  // },
];
export { MessageFields };
