import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Button, Dialog } from "@material-ui/core";
import { Location } from "../../../../components";
import { CostsFields } from "../../../../context/Forms";
import { TasksFields, TasksSteps } from "../../steps";
import CostsCreate from "../../../Costs/Create";
import {
  InputRadio,
  InputText,
  FormControl,
} from "../../../../components/Forms";
import { useSelector, useDispatch } from "react-redux";
import { AddNewTask } from "../../../../redux/actions/action";
import { task } from "../../../../redux/store";
import { add, onChangeMarker } from "../../../../redux/features/tasks";
import { GridTable } from "../../../../components/GridView";
import { Edit, Add, Close } from "@material-ui/icons";
import { ListView } from "../../../../components/ListView";
const CostsForm = (prop) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [Add_new_modal, setAddNewModal] = useState(false);
  const [data, setData] = useState(null);
  const [autoRefresh, setautoRefresh] = useState(false);
  const view = useSelector((state) => state.task.view);
  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12} md={12} lg={12} sm={12}>
          <Button onClick={() => setAddNewModal(true)}>
            <Add />
          </Button>
        </Grid>
        <Grid item xs={12} md={12} lg={12} sm={12}>
          <ListView
            withPagination
            controller={`costs/by_task/${prop.match.params.id}`}
            fields={CostsFields}
            autoRefresh={autoRefresh}
          />
        </Grid>
      </Grid>
      <Dialog fullScreen open={Add_new_modal}>
        <Grid container>
          <Grid item xs={1} sm={1} lg={1} md={1}>
            <Button onClick={() => setAddNewModal(false)}>
              <Close />
            </Button>
          </Grid>
          <Grid item xs={10} sm={10} lg={10} md={10}>
            <CostsCreate
              inTask
              id={prop.match.params.id}
              afterSave={() => setautoRefresh(!autoRefresh)}
            />
          </Grid>
        </Grid>
      </Dialog>
    </React.Fragment>
  );
  //   if (view.length > 0) {
  //     return (
  //       <form
  //         style={{ margin: 10, width: "100%" }}
  //         onSubmit={(e) => console.log(e)}
  //       >
  //         <GridTable
  //           title={"eee"}
  //           localData={view[0].inventory}
  //           controller="tasks"
  //           fields={Fields}
  //         />
  //         {prop.globalEditable && (
  //           <Button onClick={() => setAddNewModal(true)}>
  //             <Add />
  //           </Button>
  //         )}
  //         <Dialog
  //           onSave={() => prop.onSave()}
  //           view_id={prop.match.params.id}
  //           status={Add_new_modal}
  //           globalEditable={prop.globalEditable}
  //           data={data}
  //           handleClose={() => {
  //             setAddNewModal(false);
  //             setData(null);
  //           }}
  //         />
  //       </form>
  //     );
  //   } else {
  //     return <div></div>;
  //   }
};
export default CostsForm;
