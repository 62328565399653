import React, { useState, useEffect } from "react";
import ViewOne from "../../components/ViewOne/ViewOne";
import GalleryModal from "./components/GalleryModal";
import EditOne from "../../components/ViewOne/EditOne";
import { IncomingTasksFields } from "../../context/Forms";
import { Grid, Button, Box } from "@material-ui/core";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { InputText } from "../../components/Forms";
import TimeLine from "./components/TimeLine";
import { api } from "../../services/api";
import i18n from "../../i18n";
const IncomingTasksView = (props) => {
  const address_fields = [
    {
      type: "text",
      label: i18n.t("global_fields.origin_location"),
      id: "origin_address",
      show_list: true,
    },
    {
      type: "text",
      label: i18n.t("global_fields.origin_postal_code"),
      id: "origin_postal_code",
      show_list: true,
    },
    {
      type: "text",
      label: i18n.t("global_fields.destination"),
      id: "destination_address",
      show_list: true,
    },
    {
      type: "text",
      label: i18n.t("global_fields.destination_postal_code"),
      id: "destination_postal_code",
      show_list: true,
    },
  ];
  const [enableAttachment, setEnableAttachment] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editLocation, setEditLocation] = useState(false);
  const [editInfo, setEditInfo] = useState(false);
  const [caseForm, setCaseForm] = useState({});
  const [isRefresh, setIsRefresh] = useState(false);
  const [data, setData] = useState({});
  useEffect(() => {
    api
      .getOne({ controller: `incoming_task`, id: props.match.params.id })
      .then((res) => setData(res.data));
  }, [editLocation, editInfo]);

  const locationFields = IncomingTasksFields.filter(
    (item) =>
      item.id.split("_")[0] == "origin" ||
      item.id.split("_")[0] == "destination"
  );
  const infoFields = IncomingTasksFields.filter(
    (item) =>
      item.id.split("_")[0] !== "origin" &&
      item.id.split("_")[0] !== "destination"
  );
  const { origin_address, destination_address } = caseForm;
  const onChangeLocation = (e) =>
    setCaseForm((prevState) => ({ ...prevState, [e.id]: e.label }));
  return (
    <Grid container>
      <ViewOne
        loading={true}
        localData={data}
        title="title"
        // manualWithController
        autoTranslation
        // id={props.match.params.id}
        fields={IncomingTasksFields.concat(address_fields)}
        // controller="costs"
      />
      <Grid item xs={12} sm={12} lg={12} md={12}>
        <Button
          disabled={
            (data.send_to_task_date && data.send_to_task_date.length > 0) ||
            data.status == 3
          }
          onClick={() =>
            api
              .create({
                controller: `incoming_task/to_task/${props.match.params.id}`,
                data: {},
              })
              .then((res) => {
                window.location.replace(
                  `https://dash.umzugsritter.at/#/app/tasks/view/${res.data.id}`
                );
              })
          }
        >
          {i18n.t("incoming_tasks.to_task")}
        </Button>
        <Button
          disabled={data.status == 3}
          onClick={() =>
            api
              .create({
                controller: `incoming_task/to_cancel/${props.match.params.id}`,
                data: {},
              })
              .then((res) => {
                setIsRefresh(!isRefresh);
              })
          }
        >
          {i18n.t("incoming_tasks.to_cancel")}
        </Button>

        <Button onClick={() => setEditInfo(!editInfo)}>
          {i18n.t("global_fields.update_information")}
        </Button>
        <Button onClick={() => setEditLocation(!editLocation)}>
          {i18n.t("global_fields.update_location")}
        </Button>
        <Button onClick={() => setEnableAttachment(!enableAttachment)}>
          Attachments
        </Button>
        <GalleryModal
          {...props}
          open={enableAttachment}
          onClose={() => setEnableAttachment(false)}
        />
      </Grid>
      {editInfo && (
        <EditOne
          loading={false}
          title={i18n.t("global_fields.update_information")}
          data={data}
          // manualWithController
          autoTranslation
          id={props.match.params.id}
          fields={infoFields}
          after_save={() => setEditInfo(false)}
          controller="incoming_task/update_info"
        />
      )}
      {editLocation && (
        <Grid
          container
          // spacing={4}
          style={{ border: "1px solid rgb(192, 204, 218)" }}
        >
          <Grid item xs={10} md={10} lg={10} sm={10}>
            <Box style={{ padding: 10 }}>
              {i18n.t("global_fields.origin_address")}
              <GooglePlacesAutocomplete
                style={{ width: "100%" }}
                apiKey="AIzaSyDUb_he0UVZUDzi0LPT8zrTDi1G34DVKLk"
                autocompletionRequest={{
                  componentRestrictions: {
                    country: [
                      "at",
                      "br",
                      "de",
                      "ch",
                      "dk",
                      "it",
                      "nl",
                      "nld",
                      "the",
                      "se",
                    ],
                  },
                }}
                selectProps={{
                  origin_address,
                  onChange: (e) =>
                    setCaseForm((prevState) => ({
                      ...prevState,
                      origin_address: e.label,
                    })),
                  styles: {
                    input: (provided) => ({
                      ...provided,
                      height: "100%",
                      color: "blue",
                    }),
                    option: (provided) => ({
                      ...provided,
                      color: "blue",
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: "blue",
                    }),
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={10} md={10} lg={10} sm={10}>
            <InputText
              id={"origin_postal_code"}
              label={i18n.t("global_fields.postal_code")}
              value={caseForm.origin_postal_code || ""}
              onChange={(e) =>
                setCaseForm((prevState) => ({
                  ...prevState,
                  origin_postal_code: e.target.value,
                }))
              }
            />
          </Grid>
          <Grid item xs={10} md={10} lg={10} sm={10}>
            <Box style={{ padding: 10 }}>
              {i18n.t("global_fields.destination")}
              <GooglePlacesAutocomplete
                style={{ width: "100%" }}
                apiKey="AIzaSyDUb_he0UVZUDzi0LPT8zrTDi1G34DVKLk"
                autocompletionRequest={{
                  componentRestrictions: {
                    country: [
                      "at",
                      "br",
                      "de",
                      "ch",
                      "dk",
                      "it",
                      "nl",
                      "nld",
                      "the",
                      "se",
                    ],
                  },
                }}
                selectProps={{
                  origin_address,
                  onChange: (e) =>
                    setCaseForm((prevState) => ({
                      ...prevState,
                      origin_address: e.label,
                    })),
                  styles: {
                    input: (provided) => ({
                      ...provided,
                      height: "100%",
                      color: "blue",
                    }),
                    option: (provided) => ({
                      ...provided,
                      color: "blue",
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: "blue",
                    }),
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={10} md={10} lg={10} sm={10}>
            <InputText
              id={"destination_postal_code"}
              label={i18n.t("global_fields.postal_code")}
              value={caseForm.destination_postal_code || ""}
              onChange={(e) =>
                setCaseForm((prevState) => ({
                  ...prevState,
                  destination_postal_code: e.target.value,
                }))
              }
            />
          </Grid>
          {}
          <Grid item xs={10} sm={10} md={10} lg={10}>
            <Button
              onClick={() =>
                api
                  .create({
                    controller: `incoming_task/update_location/${props.match.params.id}`,
                    data: caseForm,
                  })
                  .then((res) => {
                    setEditLocation(false);
                  })
              }
            >
              {i18n.t("global_fields.update_location")}
            </Button>
          </Grid>
        </Grid>
      )}
      <TimeLine {...props} autoTranslation id={props.match.params.id} />
    </Grid>
  );
};
export default IncomingTasksView;
