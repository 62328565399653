import React from "react";
import {Dashboard,UsersList,UsersCreate,TasksList,TasksCreate,TasksView,ProfileComponent} from "../../../pages";
import { Route,Switch } from "react-router-dom";
import ClientProfile from "../../../pages/clientProfile/ClientProfile";
const guest_routes = [
    {
        path:"/app/dashboard",
        component: Dashboard
    },
]
const GuestRoutes = ()=>{
    // return([])
    return(
        <Switch>
            {guest_routes.map((item,key)=><Route path={item.path} key={key} component={item.component} />)}
        </Switch>
    )
}
export {GuestRoutes,guest_routes}