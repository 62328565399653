import React from "react";
import {
  StyledFormControl,
  StyledInputText,
  StyledInputLabel
} from "../StyledComponents";
import useStyles from "./styles";
import i18n from "../../../i18n";
import { HelperInputForm } from "..";
const InputText = ({
  id,
  label,
  variant,
  className,
  disabled,
  autoTranslate,
  style,
  defaultValue,
  onChange,
  otherOptions,
  value,
  type,
  customType,
  endAdornmentText,
  multiline
}) => {
  const classes = useStyles();
  if (customType === "hidden") {
    return (
      <StyledInputText
        endAdornment={
          i18n.exists(`helper_tooltip.${id}`) && <HelperInputForm id={id} />
        }
        value={value ? value : ""}
        id={id}
        multiline={multiline}
        type={customType}
      />
    );
  } else {
    return (
      <StyledFormControl fullWidth={true} className={classes.margin}>
        <StyledInputLabel variant="outlined" htmlFor="bootstrap-input">
          {i18n.exists(`global_fields.${id}`) && autoTranslate ?i18n.t(`global_fields.${id}`):label}
        </StyledInputLabel>
        <StyledInputText
          fullWidth
          multiline={multiline}
          endAdornment={
            i18n.exists(`helper_tooltip.${id}`) ? <HelperInputForm id={id} />:
            endAdornmentText &&  endAdornmentText
          }
          disabled={disabled}
          value={value ? value : ""}
          id={id}
          onChange={onChange}
          type={customType || type || "text"}
        />
      </StyledFormControl>
    );
  }
};
export { InputText };
