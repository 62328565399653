import React from 'react';
import { Grid, Paper, Typography, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { Box } from '../../components/ViewOne/StyledComponents/BoxComponent';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';

// styles
import useStyles from './styles';

// logo

const EmptyResult = ({ border, dashboard, title, notFoundTitle }) => {
    var classes = useStyles();
    const { t } = useTranslation();
    return (
        // <Box style={{ width: '100%', height: '100px' }}>
        <Grid
            container
            display="flex"
            justifyContent="center"
            flexdirection="column"
            alignItems="center"
        >
            <Grid item>
                <Typography
                    variant="h5"
                    color="primary"
                    // className={classes.textRow}
                >
                    {dashboard &&
                        notFoundTitle &&
                        i18n.t(`global_fields.${notFoundTitle}`)}
                    {title === 'invoices' &&
                        !notFoundTitle &&
                        i18n.t(
                            `global_fields.invoice_list_not_found`,
                        )}
                    {title === 'transactions' &&
                        !notFoundTitle &&
                        i18n.t(
                            `global_fields.transactions_list_not_found`,
                        )}
                    {title === 'loans' &&
                        !notFoundTitle &&
                        i18n.t(`global_fields.loans_list_not_found`)}
                    {title === 'ticket' &&
                        !notFoundTitle &&
                        i18n.t(`global_fields.ticket_list_not_found`)}
                    {title === 'purchases' &&
                        !notFoundTitle &&
                        i18n.t(
                            `global_fields.purchases_list_not_found`,
                        )}
                    {title === 'sales' &&
                        !notFoundTitle &&
                        i18n.t(`global_fields.sales_list_not_found`)}
                </Typography>
            </Grid>
        </Grid>
        // </Box>
    );
};
export default EmptyResult;
