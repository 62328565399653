import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import { makeStyles } from '@mui/styles';
import { Typography, Button, Grid } from "@material-ui/core";
import {InputText} from "../Forms";
import { useTranslation } from "react-i18next";
import {ErrorHandler} from "../../components/ErrorHandler/ErrorHandler";
import toast from 'react-hot-toast';

import axios from "../../axios-orders";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: 8,
      width: "25ch",
    },
  },
}));

const ForgetPassword = () => {
  const [emailValue, setEmailValue] = useState("");
  const [validateEmail, setValidateEmail] = useState(false);

  const classes = useStyles();
  const { t } = useTranslation();

  const EmailCheck = (emailValue) => {
    let mailformat =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailValue.match(mailformat)) {
      setValidateEmail(false);
    } else {
      setValidateEmail(true);
    }
  };

  useEffect(() => {
    EmailCheck(emailValue);
  }, [emailValue]);

  const sendMailToForget = () => {
    axios
      .post("user/forget", { email: emailValue })
      .then((res) => {
        const response = res;
        window.localStorage.setItem("forgetPasswordCode", response.data);
        response.status === 200 && toast.error("Reset Password Maile Sent");
      })
      .catch((err) => {
        ErrorHandler(err.response.status);
      });
  };

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography>{t("enterMailOrMob")}</Typography>
        <InputText
          value={emailValue}
          onChange={(e) => setEmailValue(e.target.value)}
          variant="outlined"
          type="email"
        />
        <Button disabled={validateEmail} onClick={sendMailToForget}>
          {t("send")}
        </Button>
      </Grid>
    </form>
  );
};
export default ForgetPassword;
