import React from "react";
import { Grid } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
// import socketIOClient from "socket.io-client";
// import sailsIOClient from "sails.io.js";
// let io;
// if (sailsIOClient.sails) {
//   io = socketIOClient;
// } else {
//   io = sailsIOClient(socketIOClient);
// }
// // io.sails.url = "https://sapi.umzugsritter.at";

const TitleComponent = ({ title, setEditable, editable }) => {
  // socketIOClient.socket.on("funSockets", (res) => {
  //   console.log(res);
  //   console.log("user socket");
  // });
  // socketIOClient.socket.on("logout", (res) => {
  //   console.log(res);
  //   console.log("user socket");
  // });
  return (
    <Grid
      container
      direction="row-reverse"
      justifyContent="flex-end"
      alignItems="center"
    >
      {/* <Grid style={{background:'#3152e3'}} container>
                <Grid item xs={8} md={8} sm={8} lg={8}>
                    <div style={{color:"white",fontSize:18,minHeight:56,display:'flex',alignItems:"center"}}>{title}</div>
                </Grid> */}
      <Grid item xs={1} sm={1} md={1} lg={1} onClick={() => setEditable()}>
        <Edit color={"success"} />
      </Grid>
      {/* </Grid> */}
    </Grid>
  );
};
export default TitleComponent;
