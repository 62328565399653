import i18n from "i18next";
const IncomingTasksFields = [
  {
    type: "refrence",
    label: "id",
    id: "id",
    onlyLink: true,
    hidden_form: true,
    show_list: false,
  },
  {
    type: "text",
    label: i18n.t("global_fields.title"),
    id: "title",
    show_list: true,
  },

  {
    type: "text",
    label: i18n.t("global_fields.name"),
    id: "name",
    show_list: true,
  },
  {
    type: "text",
    label: i18n.t("global_fields.company_name"),
    id: "company_name",
    show_list: true,
  },

  {
    type: "text",
    label: i18n.t("global_fields.phone"),
    id: "phone",
    show_list: true,
  },
  {
    type: "text",
    label: i18n.t("global_fields.email"),
    id: "email",
    show_list: true,
  },
  {
    type: "select",
    label: i18n.t("global_fields.status"),
    id: "status",
    data: [
      { value: 1, text: i18n.t("incoming_tasks.status.pending") },
      { value: 2, text: i18n.t("incoming_tasks.status.completed") },
      { value: 3, text: i18n.t("incoming_tasks.status.cancelled") },
    ],
  },
  //   type: "text",
  //   label: "Origin Postal Code",
  //   id: "origin_postal_code",
  //   show_list: true,
  // },
  // {
  //   type: "select",
  //   data: [
  //     { text: "Male", value: 1 },
  //     { text: "Female", value: 2 },
  //     { text: "other", value: 3 },
  //   ],
  //   label: "Gender",
  //   id: "gender",

  //   show_list: true,
  // },
  // {
  //   type: "text",
  //   label: "Origin Country",
  //   id: "origin_country",
  //   show_list: true,
  // },
  // {
  //   type: "text",
  //   label: "Origin City",
  //   id: "origin_city",
  //   show_list: true,
  // },
  // {
  //   type: "text",
  //   label: "Origin Street",
  //   id: "origin_street",
  //   show_list: true,
  // },
  // {

  // {
  //   type: "text",
  //   label: "Destination Country",
  //   id: "destination_country",
  //   show_list: true,
  // },
  // {
  //   type: "text",
  //   label: "Destination City",
  //   id: "destination_city",
  //   show_list: true,
  // },
  // {
  //   type: "text",
  //   label: "Destination Street",
  //   id: "destination_street",
  //   show_list: true,
  // },
  // {
  //   type: "text",
  //   label: "Destination Postal Code",
  //   id: "destination_postal_code",
  //   show_list: true,
  // },
];
export { IncomingTasksFields };
