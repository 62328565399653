import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from '@material-ui/core/styles';
import { CostsFields } from '../../../context/Forms'
import PageTitle from "../../../components/PageTitle/PageTitle";
import { Button, Grid } from "@mui/material";
import { ListView } from "../../../components/ListView";
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  table: {
    minWidth: 650,
  },
  cellWidth: {
    width: "30%",
  },
});

const CostsView = (props) => {
  const classes = useStyles();
  const user = useSelector(state => state.user.value)
  const {t} = useTranslation();
  const [searchedList, setSearchedList] = useState([])
  const [searchField, setSearchField] = useState('name')
  const [blackList,setBlackList] = useState(false)
  const [where,setWhere]=useState('{}')
  // const onChangeSearchInput = (e) => {
  //   if (e.key==="Enter") {
  //     if(e.target.value==="Black:List:Working"){
  //       setBlackList(true)
  //     }else{
  //       api.getSearchedList({ controller: "tasks", where: { field: searchField, method: "startsWith", value: e.target.value } })
  //         .then(res => {
  //           const {list} = res
  //           setSearchedList(list.map(item => { return { id: item.id, title: item.name + " " + item.phone } }))
  //         })
  //     }
  //   }
  // }
  const other_fields = [
    {
      type: "select",
      linked: true,
      label: "Task",
      noneExist: true,
      id: "task",
      fetch: true,
      show_field: ["name"],
      controller: "tasks",
      searchable: false,
      show_list: true,
    },
  ]
  return (
    <React.Fragment>
      <PageTitle title={t("tasks.title")} button={user.role > 2 &&
        (
          <Button component={Link} to="/app/costs/create" variant="contained" size="medium" color="primary">
            {t("events.create")}
          </Button>
        )}
      />
      <div className={classes.root}>
        <ListView autoTranslation where={where} footerContent={(e) => (
            <Grid item>
            </Grid>
          )} notFoundTitle="global_not_found" withShow withPagination controller={"costs"} fields={CostsFields.concat(other_fields)} />
      </div>
    </React.Fragment>
  );
};
export default CostsView;
