import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FormControl } from "../../components/Forms";
// import { TasksFields } from "../../context/Forms";
// import {TasksSteps,TasksFields} from "./steps"
import PageTitle from "../../components/PageTitle/PageTitle";
import {
  Button,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FormStepper } from "../../components/Forms";
import { PersonalInfo, ChangePasswordComp } from "./components/Form";
import { useDispatch, useSelector } from "react-redux";
import { task } from "../../redux/store";
import { add, addView } from "../../redux/features/tasks";
import { useTranslation } from "react-i18next";
import { api } from "../../services/api";
import toast, { Toaster } from "react-hot-toast";
const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  table: {
    minWidth: 650,
  },
  cellWidth: { width: "30%" },
});

const ProfileComponent = (prop) => {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState(false);
  const { t } = useTranslation();
  const onSave = () => {
    // new Promise((resolve, reject) => {
    //   api.updateOne({controller:"tasks",id:prop.match.params.id,data:viewTasks.filter(item=>item.id==prop.match.params.id)[0]}).then(res=>{
    //     resolve('Success!');
    //   })
    // })
  };
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div>
      <Accordion
        expanded={expanded === "personal"}
        onChange={handleChange("personal")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            {t("profile_page.personal_info")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PersonalInfo />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "change_password"}
        onChange={handleChange("change_password")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            {t("profile_page.change_password")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ChangePasswordComp />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
export default ProfileComponent;
