import React from "react";
import i18n from "i18next";
const CalculateCosts = [
  {
    type: "price",
    label: i18n.t("costs.task_price"),
    id: "task",
    show_view: true,
    customType: "number",
  },
  {
    type: "price",
    label: i18n.t("costs.unpaid_costs"),
    id: "unpaid_costs",
    show_view: true,
    customType: "number",
  },
  {
    type: "price",
    label: i18n.t("costs.paid_costs"),
    id: "paid_costs",
    show_view: true,
    customType: "number",
  },
  {
    type: "price",
    label: i18n.t("costs.tasks_cost"),
    id: "tasks_cost",
    show_view: true,
    customType: "number",
  },
  {
    type: "price",
    label: i18n.t("costs.office_cost"),
    id: "office_cost",
    show_view: true,
    customType: "number",
  },
  {
    type: "price",
    label: i18n.t("costs.employees_cost"),
    id: "employees_cost",
    show_view: true,
    customType: "number",
  },
  {
    type: "datepicker",
    label: i18n.t("costs.start_date"),
    id: "start",
    show_view: true,
  },
  {
    type: "datepicker",
    label: i18n.t("costs.end_date"),
    id: "end",
    show_view: true,
  },
];
export { CalculateCosts };
