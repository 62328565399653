import { List } from "immutable";

let id = 0;
const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "update": {
      return state;
    }
    case "Add_NEW_TASK": {
      const data = { [action.id]: action.value };
      const new_task = state.new_task;
      return {
        ...state,
        new_task: { ...new_task, [action.id]: action.value },
      };
    }
    case "UPDATE_MODEL": {
      if (action.model_type === "single") {
        return {
          ...state,
          [action.model_name]: action.data,
        };
      } else {
      }
    }
    case "ADD_ITEM":
      return {
        ...state,
        items: state.items.push({
          id: action.itemId,
          item: action.item,
          completed: action.completed,
        }),
      };
    case "COMPLETED_ITEM":
      return {
        ...state,
        items: state.items.update(action.itemId - 1, (value) => {
          return { ...value, completed: action.completed };
        }),
      };
    case "INITIAL_ITEMS": {
      return {
        ...state,
        // profile:List(action.items)
        [action.model_name]: action.data,
        ["new_task"]: {},
      };
    }
    // return {
    //     ...state,
    //     items:state.items.push({id:action.items.itemId,item:action.items.item,completed:action.items.completed})
    //   }
    default:
      return state;
  }
};
export default reducer;
