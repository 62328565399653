import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FormControl } from "../../components/Forms";
import { UsersFields } from "../../context/Forms";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Button, Grid } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  table: {
    minWidth: 650,
  },
  cellWidth: { width: "30%" },
});

const CreateUser = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <PageTitle title="Create User" />
      <div className={classes.root}>
        <FormControl
          requirement="all"
          model="user"
          afterCreate={() => console.log("Created")}
          fields={UsersFields}
        />
      </div>
    </React.Fragment>
  );
};
export default CreateUser;
