// import { withStyles } from "@material-ui/styles";
import { InputBase } from "@material-ui/core";
// import { withStyles } from "@mui/styles";
import { withStyles } from "@material-ui/core/styles";

const StyledInputText = withStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    "label + &": {
      marginTop: 8,
    },
    "&$input": {
      width: "100% !important",
    },
  },
  focused: {
    backgroundColor: theme.palette.common.white,
  },
  input: {
    backgroundColor: theme.palette.common.white,
    fontSize: 16,
    // width: "100% !important",
  },
}))(InputBase);
export { StyledInputText };
