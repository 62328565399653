import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import EnglishLang from "./locales/en.json";
import PersianLang from "./locales/de.json";
const resources = {
  en: {
    translation: EnglishLang,
  },
  de: {
    translation: PersianLang,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: "de",
    fallbackLng: "de",
    interpolation: { escapeValue: false },
  });

export default i18n;
