import React, { useState } from "react";

import { Typography, Button, Grid } from "@material-ui/core";
import {InputText} from "../Forms";
import { useTranslation } from "react-i18next";
import {ErrorHandler} from "../../components/ErrorHandler/ErrorHandler";
import toast from 'react-hot-toast';

import axios from "../../axios-orders";
import logo from "../../pages/login/logo.svg";
import useStyles from "../../pages/login/styles";
import { useUserDispatch } from "../../context/UserContext";

const UpdatePassword = () => {
  const classes = useStyles();
  const dispatch = useUserDispatch();

  const { t } = useTranslation();
  const [newPss, setNewPss] = useState("");
  const [confirmPss, setConfirmPss] = useState("");

  const resetPassHandler = () => {
    const forgetPassCodeValue =
      window.localStorage.getItem("forgetPasswordCode");

    axios
      .post(`user/forget/${forgetPassCodeValue}`, { password: newPss })
      .then((res) => {
        const response = res;
        response.status === 200 &&
          toast.success("ypur Password has been changed");

        const token = response.data.code;
        localStorage.setItem("id_token", token);
        token && dispatch({ type: "LOGIN_SUCCESS" });
        window.history.push("/app/dashboard");
      }, window.localStorage.removeItem("forgetPasswordCode"))
      .catch((err) => {
        ErrorHandler(err.response.status);
      });
  };

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <img src={logo} alt="logo" className={classes.logotypeImage} />
        <Typography className={classes.logotypeText}>Material Admin</Typography>
      </div>
      <div className={classes.formContainer}>
        <Typography> {t("insertNewpassword")}</Typography>
        <Grid item xs={12} sm={6}>
          <InputText
            variant="outlined"
            id="name"
            InputProps={{
              classes: {
                underline: classes.textFieldUnderline,
                input: classes.textField,
              },
            }}
            value={confirmPss}
            onChange={(e) => setConfirmPss(e.target.value)}
            margin="normal"
            placeholder={t("newPass")}
            type="password"
            fullWidth
          />
          <InputText
            variant="outlined"
            id="name"
            InputProps={{
              classes: {
                underline: classes.textFieldUnderline,
                input: classes.textField,
              },
            }}
            value={newPss}
            onChange={(e) => setNewPss(e.target.value)}
            margin="normal"
            placeholder={t("confirmPass")}
            type="password"
            fullWidth
          />
          <Button
            onClick={resetPassHandler}
            disabled={newPss !== confirmPss || (newPss && confirmPss) === null}
            size="large"
            variant="contained"
            color="primary"
            className={classes.createAccountButton}
          >
            {t("changePass")}
          </Button>
        </Grid>
      </div>
    </Grid>
  );
};
export default UpdatePassword;
