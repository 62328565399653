import React, { useEffect, useState, useRef, useMemo } from "react";
import dotenv from "react-dotenv";
import JoditEditor from "jodit-react";
import { useDropzone } from "react-dropzone";
import { InputSelect, InputText, FormControl } from "../../../components/Forms";
import { api } from "../../../services/api";
import Skeleton from "@mui/material/Skeleton";
import { Redirect } from "react-router-dom";
import {
  Grid,
  Button,
  FormControlLabel,
  FormGroup,
  IconButton,
  Box,
} from "@mui/material";

import { IncomingTasksFields } from "../../../context/Forms";
import { SettingsSuggest } from "@mui/icons-material";
import { List as ListIcon, Search as SearchIcon } from "@mui/icons-material";
import InputIcon from "@mui/icons-material/Input";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import StyledSwitch from "./../../../components/Forms/StyledComponents/StyledSwitch";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import "./style.css";
const axios = require("axios");

const ReplyComponent = (props) => {
  const [enableReply, setEnableReply] = useState(false);
  const [loading, setLoading] = useState(true);
  const [task, setTask] = useState();
  const [searchInput, setSearchInput] = useState("");
  const [taskList, setTaskList] = useState([]);
  const [casesList, setCasesList] = useState([]);
  const [caseId, setCaseId] = useState();
  const [incomingForm, setIncomingForm] = useState({});
  const [message, setMessage] = useState("");
  const [taskType, setTaskType] = React.useState("incoming");
  const [ownerType, setOwnerType] = useState("owner");
  const [newTask, setNewTask] = useState(false);
  const [withoutReply, setWithoutReply] = useState(false);
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [redirect, setRedirect] = useState(null);
  const [files, setFiles] = React.useState([]);
  const [filesSrc, setFilesSrc] = useState([]);
  const onDrop = React.useCallback((acceptedFiles) => {
    if (
      files.filter((ii) => !filesSrc.map((qq) => qq.filename).includes(ii.path))
        .length < 1
    )
      setFiles((prev) => [...prev, ...acceptedFiles]);
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleChange = (event, nextView) => {
    setTaskType(nextView);
  };

  useEffect(() => {
    setLoading(true);
    if (enableReply) {
      setTimeout(() => {
        api
          .getAll(`messages/incoming_message_tasks/${props.incoming.id}`)
          .then((result) => {
            // setRefreshMessage(false);
            setTaskList(result.tasks);
            if (result.tasks.length > 0) {
              setTask(result.tasks[0].id);
            }
            setCasesList(result.cases);
            if (result.cases.length > 0) {
              setCaseId(result.cases[0].id);
            }
            setLoading(false);
          });
      }, 300);
    }
  }, [enableReply]);
  useEffect(() => {
    if (newTask && ownerType == "owner") {
      api
        .create({
          controller: `messages/reply_data/${props.incoming.id}`,
          data: { owner_type: ownerType },
        })
        .then((result) => console.log(result.data));
    }
  }, [newTask]);
  useEffect(() => {
    if (withoutReply) {
      setMessage("");
    }
  }, [withoutReply]);
  const submitReply = (e) => {
    api
      .create({
        controller: `messages/submit_reply_incoming/${e.id}`,
        data: {
          task_type: taskType,
          new_task: newTask,
          case: incomingForm,
          message: message,
          case_id: caseId,
          task: task,
          attach: filesSrc,
        },
      })
      .then((result) => {
        setMessage("");
        if (result && result.data) {
          if (newTask && taskType == "incoming") {
            setRedirect("/app/cases/view/" + result.data.message.incoming);
          } else if (newTask && taskType == "task") {
            setRedirect("/tasks/view/" + result.data.message.task);
          } else {
            props.setRefreshMessage(true);
          }
        } else {
        }
      });
  };
  const onSearchSubmit = (e) => {
    api
      .create({
        controller: `messages/reply_data/${props.incoming.id}`,
        data: { owner_type: ownerType, search: searchInput },
      })
      .then((result) => {
        setTaskList(result.data);
        setLoading(false);

        if (result.data[0]) {
          Object.keys(result.data[0]).map((ii) => {
            setIncomingForm((prevState) => ({
              ...prevState,
              [ii]: result.data[0][ii],
            }));
          });
        }
      });
  };
  const onHandleAddress = (e, id) =>
    setIncomingForm((prevState) => ({ [id]: e.label }));
  const { origin_address, destination_address } = incomingForm;

  const fileList = files.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes{" "}
      <Button
        onClick={() =>
          setFiles((prevState) => prevState.filter((i) => i.path != file.path))
        }
      >
        Delete
      </Button>
    </li>
  ));
  const uploadFunc = () => {
    let formData = new FormData();
    formData.append(
      "attach",
      files.filter(
        (ii) => !filesSrc.map((qq) => qq.filename).includes(ii.path)
      )[0]
    );
    api
      .upload_file({
        controller: `messages/upload`,
        data: formData,
      })
      .then((result) => {
        setFilesSrc((prevState) => [...prevState, ...result.data.files]);
      });
  };
  return (
    <Grid
      container
      style={{ borderTop: "1px dotted #9b9e57", marginTop: 5, paddingTop: 5 }}
    >
      <Grid item md={10} lg={10} sm={10} xs={10}>
        <Grid container justifyContent="space-between">
          <Grid item xs={6} sm={6} md={6} lg={6}>
            {enableReply && "Reply"}
            <Button onClick={() => setEnableReply(!enableReply)}>
              {enableReply ? "Close" : "Reply"}
            </Button>
          </Grid>
          {enableReply && (
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <FormGroup>
                <FormControlLabel
                  checked={withoutReply}
                  onChange={(e) => {
                    setWithoutReply(e.target.checked);
                  }}
                  control={<StyledSwitch />}
                  label="without Reply"
                />
              </FormGroup>
            </Grid>
          )}
        </Grid>
      </Grid>
      {enableReply && (
        <Grid item md={12} lg={12} sm={12} xs={12}>
          {loading ? (
            <Skeleton height={150} />
          ) : (
            <Grid container>
              {!withoutReply && (
                <Grid
                  item
                  style={{
                    border: "1px solid #80808069",
                    margin: 5,
                    padding: 5,
                  }}
                  xs={12}
                  md={12}
                  lg={12}
                  sm={12}
                >
                  <JoditEditor
                    // ref={editor}
                    value={message}
                    // config={config}
                    tabIndex={1} // tabIndex of textarea
                    onBlur={(newContent) => setMessage(newContent)} // preferred to use only this option to update the content for performance reasons
                  />
                </Grid>
              )}
              {!withoutReply && (
                <Grid xs={12} md={12} lg={12} sm={12}>
                  <section className="container">
                    <div
                      {...getRootProps({
                        className: "dropzone",
                      })}
                    >
                      {files.filter(
                        (ii) =>
                          !filesSrc.map((qq) => qq.filename).includes(ii.path)
                      ).length < 1 && <input {...getInputProps()} />}

                      <p
                        style={{
                          color:
                            files.filter(
                              (ii) =>
                                !filesSrc
                                  .map((qq) => qq.filename)
                                  .includes(ii.path)
                            ).length < 1
                              ? "black"
                              : "gray",
                        }}
                      >
                        Drag 'n' drop some files here, or click to select files
                      </p>
                    </div>
                    <aside>
                      <h4>Files</h4>
                      <ul>{fileList}</ul>
                    </aside>
                  </section>
                  <Button
                    disabled={filesSrc.length >= files.length}
                    onClick={() => uploadFunc()}
                  >
                    Upload
                  </Button>
                </Grid>
              )}

              <Grid item xs={12} md={12} lg={12} sm={12}>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item xs={12} md={6} sm={6} lg={3}>
                    <ToggleButtonGroup
                      value={ownerType}
                      exclusive
                      onChange={(event, nextView) => setOwnerType(nextView)}
                    >
                      <ToggleButton value="owner" aria-label="list">
                        <InputIcon />
                        Owner
                      </ToggleButton>
                      <ToggleButton value="another" aria-label="module">
                        <ListIcon />
                        Another
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                  <Grid item xs={12} md={6} sm={6} lg={3}>
                    <ToggleButtonGroup
                      value={taskType}
                      exclusive
                      onChange={(event, nextView) => setTaskType(nextView)}
                    >
                      <ToggleButton value="incoming" aria-label="list">
                        <InputIcon />
                        Incoming
                      </ToggleButton>
                      <ToggleButton value="task" aria-label="module">
                        <ListIcon />
                        Task
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                  <Grid item xs={12} md={6} sm={6} lg={3}>
                    <FormGroup>
                      <FormControlLabel
                        checked={newTask}
                        onChange={(e) => setNewTask(!newTask)}
                        control={<StyledSwitch />}
                        label="New Task?"
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              </Grid>
              {ownerType == "another" && (
                <Grid item xs={10} md={10} lg={10} sm={10}>
                  <InputText
                    id="search"
                    value={searchInput}
                    fullWidth={false}
                    onChange={(e) => setSearchInput(e.target.value)}
                    endAdornmentText={
                      <IconButton
                        aria-label="search"
                        onClick={onSearchSubmit}
                        color="primary"
                      >
                        <SearchIcon />
                      </IconButton>
                    }
                    label="Search"
                  />
                </Grid>
              )}
              {!newTask && (
                <Grid item xs={12} lg={12} sm={12} md={12}>
                  <InputSelect
                    id="task"
                    value={taskType == "task" ? task : caseId}
                    data={
                      taskType == "task"
                        ? taskList.map((item) => ({
                            text: item.umzugsplan_id,
                            value: item.id,
                          }))
                        : casesList.map((item) => ({
                            text: item.case_id,
                            value: item.id,
                          }))
                    }
                    label="Umzugs Plan"
                    onChange={(e) =>
                      taskType == "task"
                        ? setTask(e.target.value)
                        : setCaseId(e.target.value)
                    }
                  />
                </Grid>
              )}
              {newTask && (
                <Grid item xs={12} lg={12} sm={12} md={12}>
                  <FormControl
                    default_data={incomingForm}
                    onChange={(e) =>
                      setIncomingForm((prevState) => ({
                        ...prevState,
                        [e.target.id]: e.target.value,
                      }))
                    }
                    read_only
                    fields={IncomingTasksFields}
                  />
                  <Box style={{ padding: 10 }}>
                    Origin Address
                    <GooglePlacesAutocomplete
                      style={{ width: "100%" }}
                      apiKey="AIzaSyDUb_he0UVZUDzi0LPT8zrTDi1G34DVKLk"
                      autocompletionRequest={{
                        componentRestrictions: {
                          country: [
                            "at",
                            "br",
                            "de",
                            "ch",
                            "dk",
                            "it",
                            "nl",
                            "nld",
                            "the",
                            "se",
                          ],
                        },
                      }}
                      selectProps={{
                        origin_address,
                        onChange: (e) =>
                          setIncomingForm((prevState) => ({
                            ...prevState,
                            origin_address: e.label,
                          })),
                        styles: {
                          input: (provided) => ({
                            ...provided,
                            height: "100%",
                            color: "blue",
                          }),
                          option: (provided) => ({
                            ...provided,
                            color: "blue",
                          }),
                          singleValue: (provided) => ({
                            ...provided,
                            color: "blue",
                          }),
                        },
                      }}
                    />
                  </Box>
                </Grid>
              )}
              <Grid item xs={12} md={12} lg={12} sm={12}>
                <Button
                  onClick={() => {
                    submitReply(props.incoming);
                  }}
                >
                  {withoutReply ? "Submit" : "Submit & Reply"}
                </Button>
              </Grid>
            </Grid>
          )}
          {/* {JSON.stringify(redirect)} */}
          {redirect && <Redirect to={redirect} />}
        </Grid>
      )}
    </Grid>
  );
};
export default ReplyComponent;
