import React from "react";
import PropTypes from "prop-types";
import MaskedInput from "react-text-mask";
import NumberFormat from "react-number-format";
import { makeStyles } from "@mui/styles";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import MuiAlert from "@mui/material/Alert";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  alertRoot: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, "-", /\d/, "-", /\d/, "-", /\d/, "-", /\d/]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const MailVerifCode = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
};

MailVerifCode.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const MailVerifCodeInput = ({ values, setValues }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {true ? (
        <FormControl>
          <Input
            autoFocus
            value={values}
            onChange={(e) => setValues(e.target.value)}
            name="textmask"
            id="formatted-text-mask-input"
            // disabled={values.length > 4}
            // inputComponent={TextMaskCustom}
          />
        </FormControl>
      ) : (
        <Alert severity="success">Code Verified!</Alert>
      )}
    </div>
  );
};
export default MailVerifCodeInput;
