import * as React from "react";
import Typography from "@mui/material/Typography";
import {
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { FormControl } from "../../../components/Forms";
import { NotesFields } from "../../../context/Forms/Notes";
export default function AddNote(props) {
  const [openModal, setOpenModal] = React.useState(false);
  const [defaultData, setDefaultData] = React.useState({
    incoming: props.id,
    type: "call",
  });
  return (
    <React.Fragment>
      <Button onClick={() => setOpenModal(true)}>Add New Note</Button>
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Add Note</DialogTitle>
        <DialogContent>
          <FormControl
            model={`notes`}
            default_data={defaultData}
            onChange={(e) =>
              setDefaultData((prevState) => ({
                ...prevState,
                [e.target.id]: e.target.value,
              }))
            }
            fields={
              defaultData.type == "notification"
                ? NotesFields
                : NotesFields.filter((i) => i.id != "to")
            }
          />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
