import React from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
const GoogleAutocomplete = ({ onChangeAddress, address, selectProps }) => {
  return (
    <GooglePlacesAutocomplete
      apiKey="AIzaSyDUb_he0UVZUDzi0LPT8zrTDi1G34DVKLk"
      autocompletionRequest={{
        componentRestrictions: {
          country: ["at", "br", "de", "ch", "dk", "it", "nl", "se"],
        },
      }}
      selectProps={{
        address,
        onChange: onChangeAddress,
        styles: {
          input: (provided) => ({
            ...provided,
            height: "100%",
            color: "blue",
          }),
          option: (provided) => ({ ...provided, color: "blue" }),
          singleValue: (provided) => ({
            ...provided,
            color: "blue",
          }),
        },
      }}
    />
  );
};
export { GoogleAutocomplete };
