import React, { useState } from "react";
import { AppBar, Toolbar, Menu } from "@material-ui/core";
import {
  Menu as MenuIcon,
  Person as AccountIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import classNames from "classnames";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  IconButton,
} from "@mui/material";

import { useSelector, useDispatch } from "react-redux";
// styles
import useStyles from "./styles";
import { Link } from "react-router-dom";
// components
import { Typography } from "../Wrappers";
import Notification from "../Notification/Notification";
import { useTranslation } from "react-i18next";
import { changeCompany } from "../../redux/features/user";
// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { useUserDispatch, signOut } from "../../context/UserContext";

const notifications = [
  { id: 0, color: "warning", message: "Check out this awesome ticket" },
  {
    id: 1,
    color: "success",
    type: "info",
    message: "What is the best way to get ...",
  },
  {
    id: 2,
    color: "secondary",
    type: "notification",
    message: "This is just a simple notification",
  },
  {
    id: 3,
    color: "primary",
    type: "e-commerce",
    message: "12 new orders has arrived today",
  },
];

export default function Header(props) {
  var classes = useStyles();
  const { t } = useTranslation();
  const user = useSelector((state) => state.user.value);
  const company = useSelector((state) => state.user.company);

  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  var userDispatch = useUserDispatch();
  const dispatch = useDispatch();
  // local
  var [notificationsMenu, setNotificationsMenu] = useState(null);
  var [profileMenu, setProfileMenu] = useState(null);
  const onChangeCompany = (e) => {
    console.log(e.target.value);
    dispatch(changeCompany(e.target.value));
  };
  return (
    <AppBar position="fixed" color="primary" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButtonSandwich,
            classes.headerMenuButtonCollapse
          )}
        >
          {layoutState.isSidebarOpened ? (
            <ArrowBackIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse
                ),
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse
                ),
              }}
            />
          )}
        </IconButton>
        <img
          src={
            company == "herzog"
              ? "https://dashboard.umzugsritter.at/assets/herzog.png"
              : "https://dashboard.umzugsritter.at/assets/logo.jpg"
          }
          style={{
            height: "43px",
            background: "white",
            borderRadius: 8,
            padding: 5,
          }}
        />

        <div className={classes.grow} />
        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={(e) => setProfileMenu(e.currentTarget)}
        >
          <AccountIcon classes={{ root: classes.headerIcon }} />
        </IconButton>
        <Menu
          id="notifications-menu"
          open={Boolean(notificationsMenu)}
          anchorEl={notificationsMenu}
          onClose={() => setNotificationsMenu(null)}
          className={classes.headerMenu}
          disableAutoFocusItem
        >
          {notifications.map((notification) => (
            <MenuItem
              key={notification.id}
              onClick={() => setNotificationsMenu(null)}
              className={classes.headerMenuItem}
            >
              <Notification {...notification} typographyVariant="inherit" />
            </MenuItem>
          ))}
        </Menu>
        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant="h4" weight="medium">
              {user.first_name + " " + user.last_name}
            </Typography>
            <Typography className={classes.profileMenuLink} color="primary">
              {user.email ? user.email : user.mobile}
            </Typography>
          </div>
          <FormControl sx={{ m: 1, minWidth: 250 }}>
            <InputLabel htmlFor="grouped-select">{t("company")}</InputLabel>
            <Select
              value={company}
              onChange={onChangeCompany}
              defaultValue="all"
              id="grouped-select"
              label={t("company")}
            >
              <MenuItem value="all">{t("company_list.all")}</MenuItem>
              <MenuItem value={"umzugsritter"}>
                {t("company_list.umzugsritter")}
              </MenuItem>
              <MenuItem value={"herzog"}>{t("company_list.herzog")}</MenuItem>
            </Select>
          </FormControl>
          <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem
            )}
            component={Link}
            to={
              user.role > 2
                ? `/app/users/${user.user_id}`
                : `/app/users/profile`
            }
          >
            <AccountIcon className={classes.profileMenuIcon} /> Profile
          </MenuItem>

          <div className={classes.profileMenuUser}>
            <Typography
              className={classes.profileMenuLink}
              color="primary"
              onClick={() => signOut(userDispatch, props.history)}
            >
              Sign Out
            </Typography>
          </div>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
