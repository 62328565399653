import React,{useState} from "react";
import ViewOne from "../../components/ViewOne/ViewOne"
import { api } from "../../services/api";
import { InputButton,InputSelect } from "../../components/Forms";
import { Box } from "../../components/ViewOne/StyledComponents/BoxComponent";
import { UsersFields } from "../../context/Forms";
import ChangePasswordComp from "./ChangePassword";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import EditInfoComp from "./EditUser";
import toast from 'react-hot-toast';
const UserView = (prop)=>{
    const {t}= useTranslation();
    const [loading,setLoading]=React.useState(false)
    const [autoRefresh,setAutoRefresh] = React.useState(false)
    const [userInfo,setUserInfo] = useState({})
    const [newRole,setNewRole] = useState()
    const onUpdateRole = (e)=>{
        api
        .create({
        //   id: prop.match.params.id,
          data: {role:newRole,id:prop.match.params.id},
          controller: "user/update",
        })
        .then((res) => {
          toast.success("Role Updated");
        });
        console.log(newRole)
    }
    const onClicktoDeactive = (e)=>{
        setLoading(!loading)
        setTimeout(() => {
            api.updateOne({controller:"user/change_status",id:e.id,data:{status:3}}).then(res=>{
                setAutoRefresh(true)
                setAutoRefresh(false)
                setTimeout(() => {
                    setLoading(false)
                }, 100);
            })
        }, 3000);
    }
    const onClicktoActive = (e)=>{
        setLoading(!loading)
        setTimeout(() => {
            api.updateOne({controller:"user/change_status",id:e.id,data:{status:2}}).then(res=>{
                setAutoRefresh(true)
                setAutoRefresh(false)
                setTimeout(() => {
                    setLoading(false)
                }, 100);
            })
        }, 100);
    }
    return(
        <React.Fragment>
            <ViewOne autoRefresh={autoRefresh} footerContent={(e)=>{
                setUserInfo(e)
                if(e.status==2 && !loading){
                    return(
                <InputButton disabled={loading || autoRefresh} loading={loading} onClick={()=>onClicktoDeactive(e)}>Deactive User</InputButton>
            )}else{
                return(
                <InputButton disabled={loading || autoRefresh} loading={loading} onClick={()=>onClicktoActive(e)}>Active User</InputButton>

            )}}} manualWithController autoTranslation title={"mobile"} controller="user" id={prop.match.params.id} fields={UsersFields.filter(item=>item.type!=="refrence")} />
            <ChangePasswordComp id={prop.match.params.id} />
            <EditInfoComp data={userInfo} {...prop} />
            <Box title={"Change Role"} grid={{ xs: 12, md: 12, lg: 12 }}>

            <Grid container>
                <Grid item xs={10} sm={10}  md={10} lg={10}>
                    <InputSelect
                        data={[
                            { value: 1, text: t("user_fields.role_types.general") },
                            { value: 2, text: t("user_fields.role_types.worker") },
                            { value: 3, text: t("user_fields.role_types.secreter") },
                            { value: 4, text: t("user_fields.role_types.admin") },
                          ]}
                        id="role"
                        value={newRole}
                        onChange={(e) => setNewRole(e.target.value)}
                        label={"Update Role"}
                    />
                </Grid>
                <Grid item xs={10} sm={10} md={10} lg={10}>
                <InputButton onClick={(e)=>onUpdateRole(e)}>Update</InputButton>

                </Grid>
            </Grid>
            </Box>

        </React.Fragment>
    )
}
export default UserView