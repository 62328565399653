import React from "react";
import {
  Dashboard,
  UsersList,
  UsersCreate,
  TasksList,
  TasksCreate,
  TasksView,
  ProfileComponent,
  EventsList,
  UserView,
  CostsList,
  CostsView,
  CostsCreate,
  CostsBenefits,
  MessagesList,
  ArchivedMessage,
  MessagesView,
  IncomingMessage,
  IncomingTasks,
  IncomingTasksView,
  CompletedCase,
  ProcessingCase,
  CancelledCase,
  MyTasks,
  PendingCases,
} from "../../../pages";
import { Route, Switch } from "react-router-dom";
import ClientProfile from "../../../pages/clientProfile/ClientProfile";
import {
  AccountingTable,
  EventsWorker,
} from "../../../pages/Events/components";
import AccountingList from "../../../pages/Accounting";
const user_routes = [
  {
    path: "/app/dashboard",
    component: Dashboard,
  },
  {
    path: "/app/messages/list",
    component: MessagesList,
  },
  {
    path: "/app/messages/view/:email",
    component: MessagesView,
  },
  {
    path: "/app/messages/incoming",
    component: IncomingMessage,
  },
  {
    path: "/app/cases/all",
    component: IncomingTasks,
  },
  {
    path: "/app/cases/compeleted",
    component: CompletedCase,
  },
  {
    path: "/app/cases/cancelled",
    component: CancelledCase,
  },
  {
    path: "/app/cases/processing",
    component: ProcessingCase,
  },

  {
    path: "/app/cases/view/:id",
    component: IncomingTasksView,
  },
  {
    path: "/app/cases/pending",
    component: PendingCases,
  },
  { path: "/app/cases/my", component: MyTasks },
  {
    path: "/app/users/list",
    component: UsersList,
  },
  {
    path: "/app/users/create",
    component: UsersCreate,
  },
  {
    path: "/app/tasks/list",
    component: TasksList,
    socket: true,
    socket_name: "tasks",
  },
  {
    path: "/app/tasks/create",
    component: TasksCreate,
    socket: true,
    socket_name: "tasks",
  },
  {
    path: "/app/calender",
    component: EventsWorker,
  },
  {
    path: "/app/events_table",
    component: (prop) => EventsList({ ...prop, type: "table" }),
  },
  {
    path: "/app/meetings",
    component: EventsList,
  },
  {
    path: "/app/messages/archived",
    component: ArchivedMessage,
  },
  {
    path: "/app/accounting",
    component: AccountingList,
  },
  {
    path: "/app/costs/list",
    component: CostsList,
  },
  {
    path: "/app/costs/view/:id",
    component: CostsView,
  },
  {
    path: "/app/ppp/list",
    component: () => <div style={{ marginTop: 150 }}>qqqq</div>,
  },
  {
    path: "/app/costs/create",
    component: CostsCreate,
  },
  {
    path: "/app/costs_and_benefits",
    component: CostsBenefits,
  },
  {
    path: "/app/tasks/view/:id",
    component: TasksView,
  },
  {
    path: "/app/users/view/:id",
    component: UserView,
  },
  {
    path: "/app/users/:id",
    component: ProfileComponent,
  },
];
const UserRoutes = () => {
  // return([])
  return (
    <Switch>
      {user_routes.map((item, key) => (
        <Route path={item.path} key={key} component={item.component} />
      ))}
    </Switch>
  );
};
export { UserRoutes, user_routes };
