import React from "react";
import { ListView } from "../../components/ListView";
import { IncomingTasksFields } from "../../context/Forms";
const IncomingTasks = () => {
  const Fields = IncomingTasksFields;

  return (
    <ListView
      notFoundTitle="global_not_found"
      withPagination
      showSearchInput
      withSearch
      title="all_cases"
      searchable_fields={["name"]}
      withShow
      controller="incoming_task"
      fields={IncomingTasksFields}
    />
  );
};
export default IncomingTasks;
