import React, { useEffect, useState } from "react";
import { InputSelect } from "../../../../../components/Forms";
import { api } from "../../../../../services/api";
import PropTypes from "prop-types";
import {
  FormControlLabel,
  RadioGroup,
  Radio,
  Box,
  Grid,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { SettingsSuggest } from "@mui/icons-material";
function ConfirmationDialogRaw(props) {
  const { onClose, value: valueProp, open, onChange, ...other } = props;
  const [value, setValue] = React.useState(valueProp);
  const radioGroupRef = React.useRef(null);

  React.useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onChange(value);
    onClose(value);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      <DialogTitle>Task</DialogTitle>
      <DialogContent dividers>
        <RadioGroup
          ref={radioGroupRef}
          aria-label="ringtone"
          name="ringtone"
          value={value}
          onChange={(e) => {
            handleChange(e);
          }}
        >
          {props.data.map((option) => (
            <FormControlLabel
              value={option.id}
              componentsProps={{
                typography: { style: { width: "100%" } },
              }}
              key={option.id}
              control={<Radio />}
              label={
                <ListItem divider aria-haspopup="true">
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item xs={10} md={10} lg={10} sm={10}>
                      <ListItemText
                        primary={option.origin_address}
                        secondary={option.destination_address}
                      />
                    </Grid>
                    <Grid item xs={2} md={2} lg={2} sm={2}>
                      {option.umzugsplan_id}
                    </Grid>
                  </Grid>
                </ListItem>
              }
            />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          Cancel
        </Button>
        <Button onClick={handleOk}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialogRaw.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
};
function ConfirmationDialog(props) {
  const { onChange, data } = props;
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(props.selected);

  const handleClickListItem = () => {
    console.log("open dialog");
    setOpen(true);
  };

  const handleClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setValue(newValue);
    }
  };
  if (props.iconStyle)
    return (
      <React.Fragment>
        <Button onClick={handleClickListItem}>
          <SettingsSuggest />
        </Button>
        <ConfirmationDialogRaw
          id="ringtone-menu"
          keepMounted
          data={data}
          onChange={onChange}
          open={open}
          onClose={handleClose}
          value={value}
        />
      </React.Fragment>
    );
  return (
    <Box sx={{ width: "100%", maxWidth: 420, bgcolor: "background.paper" }}>
      <List
        component="div"
        role="group"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <ListItem
          button
          divider
          aria-haspopup="true"
          aria-controls="ringtone-menu"
          aria-label="Selected Task"
          onClick={handleClickListItem}
        >
          <ListItemText
            primary={
              value ? data.filter((ok) => ok.id == value)[0].origin_address : ""
            }
            secondary={
              value
                ? data.filter((ok) => ok.id == value)[0].destination_address
                : ""
            }
          />
          <div>
            {value ? data.filter((ok) => ok.id == value)[0].umzugsplan_id : ""}
          </div>
        </ListItem>
        <ConfirmationDialogRaw
          id="ringtone-menu"
          keepMounted
          data={data}
          onChange={onChange}
          open={open}
          onClose={handleClose}
          value={value}
        />
      </List>
    </Box>
  );
}
const TaskSelector = (prop) => {
  useEffect(() => {
    console.log("test");
  }, []);
  return (
    <React.Fragment>
      <ConfirmationDialog
        iconStyle={prop.iconStyle}
        selected={prop.selected || prop.task_selected}
        data={prop.data_list || prop.task_list}
        onChange={prop.onChange}
      />
    </React.Fragment>
  );
};
export default TaskSelector;
