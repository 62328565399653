import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";
import { JssProvider } from "react-jss";
import { createGenerateClassName } from "@material-ui/core/styles";
import Themes from "./themes";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import { LayoutProvider } from "./context/LayoutContext";
import { UserProvider } from "./context/UserContext";
// import "react-toastify/dist/ReactToastify.css";
import "./i18n";
import { useTranslation } from "react-i18next";
import { create } from "jss";
// import rtl from "jss-rtl";
import { jssPreset, StylesProvider } from "@mui/styles";
import thunk from "redux-thunk";

import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import { store } from "./redux/store";
// import RTL from "./locales/rtl.js";
// Configure JSS
// const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
// function RTL(props) {
//   return <StylesProvider jss={jss}>{props.children}</StylesProvider>;
// }
let classNameIndex = 0;

const generateClassName = createGenerateClassName({
  productionPrefix: "c", // 'jss' by default
  disableGlobal: true,
});

function MainApp(props) {
  const { t } = useTranslation();
  // const generateId = (rule, sheet) => 'some-id'
  return (
    <Provider store={store}>
      <UserProvider>
        <StylesProvider generateClassName={generateClassName}>
          <ThemeProvider theme={Themes.default}>
            <App />
          </ThemeProvider>
        </StylesProvider>
      </UserProvider>
    </Provider>
  );
}
ReactDOM.render(
  <LayoutProvider>
    <MainApp />
  </LayoutProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
