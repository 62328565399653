import React, { useState } from 'react';
import {
    Pagination,
    List,
    Box,
    IconButton,
} from '@mui/material';
import usePagination from '@mui/material/usePagination';

import GridTablePaginationStyles from '../GridTablePaginationStyles';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const ListViewPagination = ({total,handleOnChange}) => {
  const tofixHandler = (e)=>{
    if(e/10<(e/10).toFixed()){
      return (e/10).toFixed()
    }else if(e/10==(e/10).toFixed()) {
        return e/10
    }else if(e/10>(e/10).toFixed()){
        return parseInt((e/10).toFixed())+1
    }
    else return parseInt((e/10).toFixed())
  }
  const [page, setPage] = useState(0);
  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;
  const { items } = usePagination({
    count: total/10>0?tofixHandler(total):1,
    onChange:(e,b)=>handleChangePage(e,b)
  });

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        handleOnChange(newPage);
    };
    // if(e/10<(e/10).toFixed()){
    //     return (e/10).toFixed()
    //   }else if(e/10==(e/10).toFixed()) {
    //       return e/10}else if(e/10>(e/10).toFixed()){
    //           return parseInt((e/10).toFixed()+1)
    //       }
    //   else return parseInt((e/10).toFixed())
    return (
        <List>
            {items.map(({ page, type, selected, ...item }, index) => {
                let children = null;
                if (
                    type === 'start-ellipsis' ||
                    type === 'end-ellipsis'
                ) {
                    children = '…';
                } else if (type === 'page') {
                    children = (
                        <IconButton
                            style={{
                                marginLeft: 8,
                                marginRight: 8,
                                // fontFamily: 'Yekan',
                                fontSize: 13,
                                // margin:4,
                                // padding:2,
                                fontWeight: selected
                                    ? 'bold'
                                    : undefined,
                            }}
                            {...item}
                        >
                            {page}
                        </IconButton>
                    );
                } else {
                    children =
                        parseInt(total / 10) > 1 ? (
                            <IconButton
                                type="button"
                                style={{
                                    fontFamily: 'Yekan',
                                    fontSize: 13,
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                }}
                                {...item}
                            >
                                {type==="next"?"Next":"Prev"}
                            </IconButton>
                        ) : (
                            <React.Fragment>
                                <IconButton
                                    type="button"
                                    disabled
                                    style={{
                                        fontFamily: 'Yekan',
                                        fontSize: 13,
                                        paddingLeft: 10,
                                        paddingRight: 10,
                                    }}
                                >
                                  {type==="next"?"Next":"Prev"}
                                </IconButton>
                            </React.Fragment>
                        );
                }
                return (
                    <span key={index} onChange={handleChangePage}>
                        {children}
                    </span>
                );
                // return <li key={index}>{children}</li>;
            })}
        </List>
    );
    // return (
    //   <Pagination
    //   style={{direction:"ltr"}}
    //     variant="outlined"
    //     // rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
    //     colSpan={3}
    //     count={total/10}
    //     rowsPerPage={rowsPerPage}
    //     page={page}
    //     renderItem={(item)=>
    //       (<PaginationItem
    //       component={}
    //         components={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
    //         {...item}
    //       />)
    //     }
    //     // renderItem={(item) => (<PaginationItem
    //     // >{item.type==="previous" ? "<":item.type==="next"? ">":item.type==="end-ellipsis"?"...":item.page.toLocaleString('fa-IR')}</PaginationItem>)}

    //     SelectProps={{
    //       inputProps: {
    //         "aria-label": "rows per page",
    //       },
    //       native: true,
    //     }}
    //     onPageChange={handleChangePage}
    //     shape="rounded"
    //     onRowsPerPageChange={handleChangeRowsPerPage}
    //     ActionsComponent={GridTablePaginationStyles}
    //   />
    // );
};

export { ListViewPagination };
