import React, { useState } from "react";
import Timeline from "@mui/lab/Timeline";
import { alpha, styled } from "@mui/material/styles";
import { api } from "../../../services/api";
import { Link } from "react-router-dom";
import {
  Skeleton,
  Button,
  ButtonGroup,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { FormControl } from "../../../components/Forms";
import moment from "moment";
import i18n from "i18next";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import { useSelector } from "react-redux";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";

import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";
import { Grid } from "@material-ui/core";
import {
  Email as EmailIcon,
  NoteAltOutlined as NoteAltOutlinedIcon,
  ForwardToInbox as ForwardToInboxIcon,
  List as AddTaskIcon,
  EventNote,
  Edit,
  Done,
  NotificationsNone,
  Phone as PhoneIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import HeaderNote from "./HeaderNote";
import { NotesFields } from "../../../context/Forms/Notes";
const TimelineItemComp = styled(TimelineItem)(({ theme }) => ({
  "&:before": {
    display: "none",
  },
}));
const iconRenderer = [
  { type: "note", icon: <NoteAltOutlinedIcon /> },
  { type: "notification", icon: <NotificationsNone /> },
  { type: "call", icon: <PhoneIcon /> },
  { type: "email_reply", icon: <ForwardToInboxIcon /> },
  { type: "email", icon: <EmailIcon /> },
  { type: "to_task", icon: <AddTaskIcon /> },
  { type: "", icon: <CloseIcon /> },
];
const notification_type = [
  {
    type: "radio",
    label: "Notification Type",
    id: "notification_type",
    data: [
      { value: 1, text: "Without Note" },
      { value: 2, text: "With Note" },
    ],
  },
];
const TimelineItemView = (prop) => {
  const user = useSelector((state) => state.user.value);
  const [defaultData, setDefaultData] = useState({
    type: "note",
    notification_type: 1,
    status: 1,
    status_type: 1,
  });
  // const []
  const [openModal, setOpenModal] = useState(false);
  const { first_name, last_name } = prop.data.owner ? prop.data.owner : "";
  return (
    <TimelineItemComp>
      <TimelineSeparator>
        <TimelineDot color="primary">
          {iconRenderer.filter((item) => item.type == prop.data.type).length > 0
            ? iconRenderer.filter((item) => item.type == prop.data.type)[0].icon
            : prop.data.owner
            ? iconRenderer.filter((item) => item.type == "email_reply")[0].icon
            : iconRenderer.filter((item) => item.type == "email")[0].icon}
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Typography>{prop.data.subject}</Typography>
        {prop.data.type == "email_reply" || prop.data.type == "email" ? (
          <div dangerouslySetInnerHTML={{ __html: prop.data.text }} />
        ) : prop.data.type == "to_task" ? (
          <Link to={`./../../tasks/view/${prop.data.text}`}>Show Task</Link>
        ) : (
          <Typography>{prop.data.text}</Typography>
        )}

        <div dangerouslySetInnerHTML={{ __html: prop.data.body }} />
        <Typography>
          {moment(prop.data.updatedAt).format("YY/MM/DD hh:mm")}
        </Typography>
        <Typography>{prop.data.owner && prop.data.owner}</Typography>
        <Typography>{prop.data.from && prop.data.from}</Typography>
        {user.user_id == prop.data.to && (
          <Grid container>
            <Grid item>
              <ButtonGroup size="small" aria-label="small button group">
                <Button
                  key="1"
                  onClick={() => {
                    setOpenModal(true);
                    setDefaultData((prevState) => ({
                      ...prevState,
                      status_type: "process",
                      status: 1,
                    }));
                  }}
                  disabled={prop.data.status > 0}
                >
                  to Processing
                </Button>
                <Button
                  key="2"
                  onClick={() => {
                    setOpenModal(true);
                    setDefaultData((prevState) => ({
                      ...prevState,
                      status_type: "done",
                      status: 2,
                    }));
                  }}
                  disabled={prop.data.status > 1}
                >
                  {i18n.t("incoming_tasks.to_done")}
                </Button>
              </ButtonGroup>
              <Dialog
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {defaultData.status_type == "done"
                    ? i18n.t("incoming_tasks.to_done")
                    : i18n.t("incoming_tasks.to_processing")}
                </DialogTitle>
                <DialogContent>
                  <FormControl
                    read_only
                    model={`notes/change_status/${prop.data.id}`}
                    default_data={defaultData}
                    onChange={(e) => {
                      setDefaultData((prevState) => ({
                        ...prevState,
                        [e.target.id]: e.target.value,
                      }));
                    }}
                    fields={
                      defaultData.notification_type == 1
                        ? notification_type
                        : notification_type.concat(
                            NotesFields.filter((item) => item.id != "to")
                          )
                    }
                  />
                  <Button
                    onClick={() =>
                      api
                        .create({
                          controller: `notes/change_status/${prop.data.id}`,
                          data: defaultData,
                        })
                        .then((res) => prop.autoRefresh())
                    }
                  >
                    {i18n.t("incoming_tasks.submit")}
                  </Button>
                </DialogContent>
              </Dialog>
            </Grid>
          </Grid>
        )}
      </TimelineContent>
    </TimelineItemComp>
  );
};
export default function TimeLine(props) {
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [autoRefresh, setAutoRefresh] = useState(false);
  React.useEffect(() => {
    api.getAll(`/notes/incoming/${props.id}`).then((result) => {
      setLoading(false);
      setData(result);
    });
  }, [autoRefresh]);
  return (
    <Timeline
      position="right"
      sx={{
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex: 0.2,
          ":before": { content: "none" },
        },
      }}
    >
      {loading ? (
        <Skeleton height={150} />
      ) : (
        <React.Fragment>
          <HeaderNote {...props} />
          {data.map((item) => (
            <TimelineItemView
              key={item.id}
              autoRefresh={() => setAutoRefresh(!autoRefresh)}
              data={item}
              {...props}
            />
          ))}
        </React.Fragment>
      )}
    </Timeline>
  );
}
