const GridHandler = ({ fields, field, type }) => {
    const default_grid = { xs: 12, md: 4, lg: 3, sm: 4 };
    const fields_tmp = fields
        .map((item) => {
            return { id: item.id, grid: item.grid };
        })
        .filter((item) => item.id === field);
    if (
        fields_tmp[0] &&
        fields_tmp[0].grid &&
        fields_tmp[0].grid[type]
    ) {
        return fields_tmp[0].grid[type];
    } else {
        const default_data = default_grid[type]
            ? default_grid[type]
            : 4;
        return default_data;
    }
};

export default GridHandler;
