import React, { useState, useEffect } from "react";
import moment from "moment";
// import "moment/locale/fa";
import { api } from "../../services/api";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const DatePickerRenderer = ({ fields, field_key, field_value }) => {
  const { t } = useTranslation();
  if (
    !field_value ||
    field_value === undefined ||
    field_value.length < 1 ||
    field_value === "undefined"
  )
    return <React.Fragment>-</React.Fragment>;
  return (
    <React.Fragment>
      {/* {fields.filter((field) => field.id === field_key)[0].type ===
        "datepicker" && <div>{JSON.stringify}</div>} */}
      {fields.filter((field) => field.id === field_key)[0].type ===
        "datepicker" &&
      field_value.length > 0 &&
      moment(field_value).isValid()
        ? `${moment(field_value).format("LL")} ${moment(field_value).format(
            "HH:mm"
          )}`
        : t("global.unspecifiedـtime")}
    </React.Fragment>
  );
};

const SelectRender = ({ fields, field_key, field_value }) => {
  const [remoteValue, setRemoteValue] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (
      fields.filter((item) => item.id === field_key)[0].data === undefined &&
      !(typeof field_value === "object") &&
      !Array.isArray(field_value) &&
      field_value !== null
    ) {
      api
        .getOne({
          controller: fields.filter((item) => item.id === field_key)[0]
            .controller,
          id: field_value,
          select: fields.filter((item) => item.id === field_key)[0].select,
        })
        .then((res) => {
          setRemoteValue(res.data);
          setLoading(false);
        });
    }
  }, []);
  if (
    fields.filter((item) => item.id === field_key)[0].data === undefined &&
    typeof field_value === "object" &&
    !Array.isArray(field_value) &&
    field_value !== null
  ) {
    return (
      <React.Fragment>
        {fields.filter((item) => item.id === field_key)[0].linked ? (
          <Link
            to={
              fields.filter((item) => item.id === field_key)[0].linked &&
              `/app/${
                fields.filter((item) => item.id === field_key)[0].controller
              }/view/${field_value.id}`
            }
          >
            {Array.isArray(
              fields.filter((item) => item.id === field_key)[0].show_field
            )
              ? fields
                  .filter((item) => item.id === field_key)[0]
                  .show_field.map((it, key) => (
                    <React.Fragment key={key}>
                      {field_value[it] + " "}
                    </React.Fragment>
                  ))
              : "is undefined"}
          </Link>
        ) : Array.isArray(
            fields.filter((item) => item.id === field_key)[0].show_field
          ) ? (
          fields
            .filter((item) => item.id === field_key)[0]
            .show_field.map((it, key) => (
              <React.Fragment key={key}>{field_value[it] + " "}</React.Fragment>
            ))
        ) : (
          ""
        )}
      </React.Fragment>
    );
  } else if (fields.filter((item) => item.id === field_key)[0].data) {
    return (
      <React.Fragment>
        {fields
          .filter((item) => item.id === field_key)[0]
          .data.filter((itm) => itm.value === field_value)[0] &&
          fields
            .filter((item) => item.id === field_key)[0]
            .data.filter((itm) => itm.value === field_value)[0].text}
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        {!loading &&
          fields
            .filter((item) => field_key === item.id)[0]
            .show_field.map((it, key) => (
              <React.Fragment key={key}>
                {fields.filter((item) => item.id === field_key)[0].linked ? (
                  <Link
                    to={
                      fields.filter((item) => item.id === field_key)[0]
                        .linked &&
                      `/app/${
                        fields.filter((item) => item.id === field_key)[0]
                          .controller
                      }/view/${remoteValue.id}`
                    }
                  >
                    {remoteValue[it] + " "}
                  </Link>
                ) : (
                  remoteValue[it] + " "
                )}
              </React.Fragment>
            ))}
      </React.Fragment>
    );
    // fields
    // .filter((item) => item.id === field_key)[0]
    // .show_field.map((it) => it + ' ')
    // );
  }
};

const ValueRenderer = ({ fields, field_key, field_value }) => {
  // new Date(2022,2,21)
  // new Intl.DateTimeFormat('fa-IR').format(d))
  return (
    <React.Fragment>
      {fields.filter((field) => field.id === field_key).length > 0 && (
        <React.Fragment>
          {fields &&
            fields.filter((field) => field.id === field_key)[0].type ===
              "time_from_now" &&
            moment(field_value).fromNow()}
          {fields &&
            fields.filter((field) => field.id === field_key)[0].type ===
              "datepicker" && (
              <DatePickerRenderer
                field_value={field_value}
                fields={fields}
                field_key={field_key}
              />
            )}
          {fields &&
            fields.filter((field) => field.id === field_key)[0].type ===
              "date" &&
            new Intl.DateTimeFormat("fa-IR").format(new Date(field_value))}
          {fields &&
            fields.filter((field) => field.id === field_key)[0].type ===
              "fa_number" &&
            field_value.toLocaleString("fa-IR")}
          {fields.filter((field) => field.id === field_key)[0].type ===
            "text" && field_value}
          {fields.filter((field) => field.id === field_key)[0].type ===
            "phone" && field_value}
          {fields.filter((field) => field.id === field_key)[0].type ===
            "price" && (
            <div style={{ display: "flex" }}>
              {field_value}
              &nbsp;€
            </div>
          )}
          {fields.filter((field) => field.id === field_key)[0].type ===
            "number" && field_value}
          {fields.filter((field) => field.id === field_key)[0].type ===
            "select" && (
            <SelectRender
              fields={fields}
              field_key={field_key}
              field_value={field_value}
            />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ValueRenderer;
