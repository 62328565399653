import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
// import Autocomplete from "react-google-autocomplete";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { Location } from "../../../../components";
import {
  InputRadio,
  InputText,
  FormControl,
  InputSelect,
} from "../../../../components/Forms";
import { useSelector, useDispatch } from "react-redux";
import { AddNewTask } from "../../../../redux/actions/action";
import { task } from "../../../../redux/store";
import { add, onChangeMarker } from "../../../../redux/features/tasks";
const OriginLocationComp = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const new_task = useSelector((state) => state.task.new);
  const [locationValue, setLocationValue] = useState();
  const onChange = (e) => {
    const data = { id: e.target.id, data: e.target.value };
    dispatch(add(data));
    // dispatch(AddNewTask(data))
  };
  const onChangeMarkerTmp = (e) => {
    dispatch(
      onChangeMarker({
        origin_address: e.label,
      })
    );
    setLocationValue(e.label);
    // dispatch(onChangeMarker({
    //     origin_hnr:e.house_number,
    //     origin_lat:e.latitude,
    //     origin_long:e.longitude,
    //     origin_address:e.address,
    //     origin_postcode:e.postcode,
    //     origin_country: e.country,
    //     origin_city:e.city
    // }))
  };
  const onChangeViewport = (e) => {};
  const data = {
    latitude: new_task.origin_lat,
    longitude: new_task.origin_long,
    address: new_task.origin_address,
    postcode: new_task.origin_postcode,
    stock: new_task.origin_stock,
    city: new_task.origin_city,
    province: new_task.origin_province,
  };
  const onChangeOtherFields = (e) => {
    const tmp = { id: e.target.id, data: e.target.value };
    dispatch(add(tmp));
  };
  return (
    <Grid container>
      <Grid item xs={12} lg={12} sm={12} md={12}>
        <Grid container>
          <Grid
            item
            style={{ zIndex: 2, marginLeft: 10 }}
            xs={11}
            sm={11}
            lg={11}
            md={11}
          >
            <GooglePlacesAutocomplete
              style={{ width: "100%" }}
              apiKey="AIzaSyDUb_he0UVZUDzi0LPT8zrTDi1G34DVKLk"
              autocompletionRequest={{
                componentRestrictions: {
                  country: [
                    "at",
                    "br",
                    "de",
                    "ch",
                    "dk",
                    "it",
                    "nl",
                    "nld",
                    "the",
                    "se",
                  ],
                },
              }}
              selectProps={{
                locationValue,
                onChange: onChangeMarkerTmp,
                styles: {
                  input: (provided) => ({
                    ...provided,
                    height: "100%",
                    color: "blue",
                  }),
                  option: (provided) => ({
                    ...provided,
                    color: "blue",
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    color: "blue",
                  }),
                },
              }}
            />
          </Grid>
          <Grid item xs={11} sm={4} lg={4} md={4}>
            <InputText
              label={t("origin_location.hnr")}
              onChange={onChangeOtherFields}
              id="origin_hnr"
              value={new_task.origin_hnr}
            />
          </Grid>
          <Grid item xs={6} sm={4} lg={4} md={4}>
            <InputText
              label={t("origin_location.siege")}
              onChange={onChangeOtherFields}
              id="origin_siege"
              value={new_task.origin_siege}
            />
          </Grid>
          <Grid item xs={5} sm={3} lg={3} md={3}>
            <InputText
              label={t("origin_location.tur_nr")}
              onChange={onChangeOtherFields}
              id="origin_tur_nr"
              value={new_task.origin_tur_nr}
            />
          </Grid>
        </Grid>
        <Grid container>
          {/* <Grid item xs={12} sm={12} lg={5} md={6}>
                        <InputText label={"Ort"} onChange={onChangeOtherFields} id="origin_address" value={new_task.origin_address} />
                    </Grid> */}
          <Grid item xs={11} sm={4} lg={3} md={3}>
            <InputText
              label={t("origin_location.plz")}
              onChange={onChangeOtherFields}
              id="origin_postcode"
              value={new_task.origin_postcode}
            />
          </Grid>
          <Grid item xs={11} sm={4} lg={5} md={5}>
            <InputText
              label={t("origin_location.bundesland")}
              onChange={onChangeOtherFields}
              id="origin_city"
              value={new_task.origin_city}
            />
          </Grid>
          <Grid item xs={11} sm={3} lg={3} md={3}>
            <InputText
              label={t("origin_location.stock")}
              onChange={onChangeOtherFields}
              id="origin_stock"
              value={new_task.origin_stock}
            />
          </Grid>
          <Grid item xs={11} sm={5} lg={5} md={5}>
            <InputText
              label={t("origin_location.floor")}
              onChange={onChangeOtherFields}
              id="origin_floor"
              value={new_task.origin_floor}
            />
          </Grid>
          <Grid item xs={11} sm={5} lg={5} md={6}>
            <InputText
              label={t("origin_location.plaque")}
              onChange={onChangeOtherFields}
              id="origin_plaque"
              value={new_task.origin_plaque}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={11} sm={11} lg={11} md={11}>
            <InputSelect
              label={t(
                "tasks.origin_location.possibility_to_attach_warning_signs"
              )}
              id="origin_possibility_to_attach_warning_signs"
              onChange={onChangeOtherFields}
              value={new_task.origin_possibility_to_attach_warning_signs}
              data={[
                {
                  value: 1,
                  text: t(
                    "tasks.origin_location.possibility_to_attach_warning_signs_list.no_need"
                  ),
                },
                {
                  value: 2,
                  text: t(
                    "tasks.origin_location.possibility_to_attach_warning_signs_list.it_must"
                  ),
                },
                {
                  value: 3,
                  text: t(
                    "tasks.origin_location.possibility_to_attach_warning_signs_list.requested"
                  ),
                },
                {
                  value: 4,
                  text: t(
                    "tasks.origin_location.possibility_to_attach_warning_signs_list.request_accepted"
                  ),
                },
              ]}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={11} sm={11} lg={11} md={11}>
            <InputRadio
              label={t("origin_location.lift")}
              id="origin_lift"
              onChange={onChangeOtherFields}
              value={new_task.origin_lift}
              data={[
                { value: 1, label: t("origin_location.ja") },
                { value: 2, label: t("origin_location.nein") },
              ]}
            />
          </Grid>
        </Grid>
        <Grid container>
          {new_task.origin_lift == 1 && (
            <Grid item xs={11} sm={4} lg={6} md={6}>
              <InputText
                label={t("origin_location.lift_load")}
                value={new_task.origin_lift_load}
                id="origin_lift_load"
                onChange={onChangeOtherFields}
              />
            </Grid>
          )}
          <Grid
            item
            xs={11}
            sm={11}
            lg={new_task.origin_lift == 1 ? 5 : 11}
            md={new_task.origin_lift == 1 ? 5 : 11}
          >
            <InputText
              label={t("origin_location.apartment_area")}
              id={"origin_apartment_area"}
              value={new_task.origin_apartment_area}
              onChange={onChangeOtherFields}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={11} sm={11} lg={11} md={11}>
            <InputRadio
              label={t("origin_location.access_to_parking")}
              id="origin_access_to_parking"
              onChange={onChangeOtherFields}
              value={new_task.origin_access_to_parking}
              data={[
                { value: 1, label: t("origin_location.ja") },
                { value: 2, label: t("origin_location.nein") },
              ]}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={11} sm={11} lg={11} md={11}>
            <InputText
              label={t("origin_location.distance_from_parking_to_home")}
              id="origin_distance_parking_to_home"
              onChange={onChangeOtherFields}
              value={new_task.origin_distance_parking_to_home}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={11} sm={11} lg={11} md={11}>
            <Grid container>
              <Grid item xs={11} sm={4} md={4} lg={4}>
                <InputText
                  label={t("global_fields.total_truck")}
                  onChange={onChangeOtherFields}
                  id="total_truck"
                  value={new_task.total_truck}
                />
              </Grid>
              <Grid item xs={11} sm={4} md={4} lg={4}>
                <InputText
                  label={t("global_fields.people")}
                  onChange={onChangeOtherFields}
                  id="people"
                  value={new_task.people}
                />
              </Grid>
              <Grid item xs={11} sm={4} md={4} lg={4}>
                <InputText
                  label={t("global_fields.transportation")}
                  onChange={onChangeOtherFields}
                  id="transportation"
                  value={new_task.transportation}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={11} sm={11} lg={11} md={11}>
            <InputRadio
              label={t("origin_location.is_there_more_than_80_kg_of_furniture")}
              id="origin_more_than_80_furniture"
              onChange={onChangeOtherFields}
              value={new_task.origin_more_than_80_furniture}
              data={[
                { value: 1, label: t("origin_location.ja") },
                { value: 2, label: t("origin_location.nein") },
              ]}
            />
          </Grid>
        </Grid>
        {new_task.origin_more_than_80_furniture == 1 && (
          <Grid container>
            <Grid item xs={11} sm={11} lg={11} md={11}>
              <InputText
                label={t(
                  "origin_location.what_is_the_number_of_furniture_over_80_kg"
                )}
                id="origin_number_of_furniture_over_80"
                onChange={onChangeOtherFields}
                value={new_task.origin_number_of_furniture_over_80}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid container>
        <Grid item xs={11} sm={11} lg={11} md={11}>
          <InputRadio
            label={t("origin_location.trash")}
            id="trash"
            onChange={onChangeOtherFields}
            value={new_task.trash}
            data={[
              { value: 0, label: t("origin_location.nein") },
              { value: 1, label: t("origin_location.ja") },
            ]}
          />{" "}
        </Grid>
      </Grid>

      {/* <Grid item xs={12} lg={6} sm={12} md={6}>
                <Location onChangeMarker={onChangeMarkerTmp} {...data} onChangeViewport={onChangeViewport} latitude={new_task.origin_lat} longitude={new_task.origin_long} />
            </Grid> */}
    </Grid>
  );
};
export default OriginLocationComp;
