import React from "react";
import { Grid, Skeleton } from "@mui/material";
import { Box } from "./StyledComponents/BoxComponent";
import { withStyles } from "@mui/styles";
const StyledSkeleton = withStyles((theme) => ({
  root: {
    backgroundColor: "rgba(0, 0, 0, 0.05) !important",
    margin: "5px 5px 20px 20px",
  },
}))(Skeleton);
const ListViewLoading = () => {
  return (
    <React.Fragment>
      <Box grid={{ xs: 12, md: 12, lg: 12 }}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <StyledSkeleton
            animation="wave"
            height={20}
            width="20%"
            style={{ marginBottom: 6 }}
          />
          <StyledSkeleton
            animation="wave"
            height={20}
            width="30%"
            style={{ marginBottom: 6 }}
          />
          <StyledSkeleton
            animation="wave"
            height={20}
            width="10%"
            style={{ marginBottom: 6 }}
          />
          <StyledSkeleton
            animation="wave"
            height={20}
            width="30%"
            style={{ marginBottom: 6 }}
          />
          <StyledSkeleton
            animation="wave"
            height={20}
            width="15%"
            style={{ marginBottom: 6 }}
          />
          <StyledSkeleton
            animation="wave"
            height={20}
            width="20%"
            style={{ marginBottom: 6 }}
          />
        </Grid>
      </Box>
      <Box grid={{ xs: 12, md: 12, lg: 12 }}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <StyledSkeleton
            animation="wave"
            height={20}
            width="20%"
            style={{ marginBottom: 6 }}
          />
          <StyledSkeleton
            animation="wave"
            height={20}
            width="30%"
            style={{ marginBottom: 6 }}
          />
          <StyledSkeleton
            animation="wave"
            height={20}
            width="10%"
            style={{ marginBottom: 6 }}
          />
          <StyledSkeleton
            animation="wave"
            height={20}
            width="30%"
            style={{ marginBottom: 6 }}
          />
          <StyledSkeleton
            animation="wave"
            height={20}
            width="15%"
            style={{ marginBottom: 6 }}
          />
          <StyledSkeleton
            animation="wave"
            height={20}
            width="20%"
            style={{ marginBottom: 6 }}
          />
        </Grid>
      </Box>
      <Box grid={{ xs: 12, md: 12, lg: 12 }}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <StyledSkeleton
            animation="wave"
            height={20}
            width="20%"
            style={{ marginBottom: 6 }}
          />
          <StyledSkeleton
            animation="wave"
            height={20}
            width="30%"
            style={{ marginBottom: 6 }}
          />
          <StyledSkeleton
            animation="wave"
            height={20}
            width="10%"
            style={{ marginBottom: 6 }}
          />
          <StyledSkeleton
            animation="wave"
            height={20}
            width="30%"
            style={{ marginBottom: 6 }}
          />
          <StyledSkeleton
            animation="wave"
            height={20}
            width="15%"
            style={{ marginBottom: 6 }}
          />
          <StyledSkeleton
            animation="wave"
            height={20}
            width="20%"
            style={{ marginBottom: 6 }}
          />
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default ListViewLoading;
