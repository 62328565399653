import React from "react";
import i18n from "i18next";
import { api } from "./../../services/api";
import { StyledInputText } from "../../components/Forms/StyledComponents";
import { ContractType } from "../../pages/TasksCreate/components/Form";
const TasksFields = [
  {
    type: "refrence",
    label: i18n.t("events.id"),
    id: "id",
    onlyLink: true,
    show_list: false,
  },
  {
    type: "select",
    label: i18n.t("events.contract_type"),
    id: "contract_type",
    show_list: true,
    data: [
      { value: 1, text: i18n.t("events.personal") },
      { value: 2, text: i18n.t("events.company") },
    ],
    FormComponent: <ContractType />,
  },
  {
    type: "select",
    label: i18n.t("events.contract_type"),
    id: "status",
    show_list: true,
    data: [
      { value: 1, text: i18n.t("tasks_status.created") },
      { value: 2, text: i18n.t("tasks_status.pending") },
      { value: 3, text: i18n.t("tasks_status.accepted") },
      { value: 4, text: i18n.t("tasks_status.doing") },
      { value: 5, text: i18n.t("tasks_status.done") },
      { value: 6, text: i18n.t("events.on_hold") },
      { value: 7, text: i18n.t("tasks_status.cancelled") },
      { value: 8, text: i18n.t("tasks_status.undefined") },
    ],
    FormComponent: <ContractType />,
  },
  {
    type: "text",
    label: i18n.t("events.name"),
    id: "name",
    show_list: true,
  },
  {
    type: "text",
    label: i18n.t("events.umzug_id"),
    id: "umzugsplan_id",
    show_list: true,
  },
  {
    type: "datepicker",
    label: i18n.t("events.transfer_date"),
    label: "Transfer Date",
    id: "start_time",
    show_list: true,
  },
];
export { TasksFields };
