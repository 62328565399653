import { api } from "../../services/api";

const NotesFields = [
  {
    type: "text",
    label: "Note",
    id: "text",
  },
  {
    type: "select",
    label: "Note Type",
    id: "type",
    data: [
      { value: "call", text: "Call" },
      { value: "note", text: "Note" },
      { value: "notification", text: "Notification" },
    ],
  },
  {
    type: "select",
    label: "To",
    id: "to",
    fetch: true,
    show_field: ["first_name", "last_name"],
    data: [],
    controller: "user/find_secreters",
    searchable: false,

    // type: "select",
    // label: "To",
    // id: "to",
    // data: [
    //   { value: "call", text: "Call" },
    //   { value: "note", text: "Note" },
    //   { value: "notification", text: "Notification" },
    // ],
  },
  {
    type: "select",
    label: "Status",
    id: "status",
    data: [
      { value: 0, text: "Pending" },
      { value: 1, text: "Processing" },
      { value: 2, text: "Completed" },
    ],
  },
];
export { NotesFields };
