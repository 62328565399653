import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@mui/material';
const StyledGrid = withStyles((theme) => ({
    root: {
        background: 'white',
        boxShadow: 'rgb(28 39 60 / 10%) 0px 0px 15px',
        border: '1px solid rgb(192, 204, 218)',
        // maxWidth: '99% !important',
        padding: 10,
        marginBottom: '10px !important',
    },
}))(Grid);
export { StyledGrid };
