import React from "react";
import TableCell from "@mui/material/TableCell";
import { StyledTableHead,StyledTableRow } from "../StyledComponents";
const GridTableHeader = ({ align, data }) => {
  return(
    <StyledTableHead>
      <StyledTableRow>
        {data.filter(item=>item.show_list && item.type!=="refrence").map((item,index)=>(<TableCell style={{color:"white"}} key={index}>{item.label}</TableCell>))}
      </StyledTableRow>
    </StyledTableHead>
  )
};

export { GridTableHeader };
