import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Button } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { DatePicker } from "../../../../components/Forms";
import { updateField } from "../../../../redux/features/tasks";
import TitleComponent from "../TitleComponent";
const MeetingTimeComp = (prop) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const view = useSelector((state) => state.task.view);
  const [values, setValues] = useState([]);
  const [editable, setEditable] = useState(false);
  useEffect(() => {
    setValues(view.filter((item) => item.id === prop.match.params.id));
  }, [view]);
  const onChange = (e) => {
    const tmp = {
      task_id: prop.match.params.id,
      id: e.target.id,
      data: e.target.value,
    };
    dispatch(updateField(tmp));
  };
  if (values.length > 0) {
    return (
      <Grid container spacing={2}>
        <TitleComponent
          title={t("view_task.meeting_start_to_end_date_and_time")}
          setEditable={() => setEditable(!editable)}
          editable={editable}
        />
        <Grid item xs={10} lg={10} sm={10} md={10}>
          <DatePicker
            onChange={onChange}
            label={t("view_task.meeting_start_time")}
            id="start_time"
            disabled={!prop.globalEditable}
            value={values[0].start_time || ""}
          />
        </Grid>
        <Grid item xs={10} lg={10} sm={10} md={10}>
          <DatePicker
            onChange={(e) => onChange(e)}
            label={t("view_task.meeting_end_time")}
            id="end_time"
            disabled={!prop.globalEditable}
            value={values[0].end_time || ""}
          />
        </Grid>
        {editable && (
          <Grid item xs={10} sm={10} md={10} lg={10}>
            <Button onClick={() => prop.onSave()}>{t("view_task.save")}</Button>
          </Grid>
        )}
      </Grid>
    );
  } else {
    return <div></div>;
  }
};
export default MeetingTimeComp;
