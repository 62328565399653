import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { Grid, ButtonGroup, Button } from "@material-ui/core";
import { HighlightOff } from "@mui/icons-material";
import { InputText, InputSelect } from "../../../../components/Forms";
import { useSelector, useDispatch } from "react-redux";
import { addView, updateField } from "../../../../redux/features/tasks";
import { api } from "../../../../services/api";
import { ListView } from "../../../../components/ListView";
import { IconButton } from "@mui/material";

// const status_data = [
//   { value: 1, text: i18n.t("tasks_status.created") },
//   { value: 2, text: i18n.t("tasks_status.pending") },
//   { value: 3, text: i18n.t("tasks_status.accepted") },
//   { value: 4, text: i18n.t("tasks_status.doing") },
//   { value: 5, text: i18n.t("tasks_status.done") },
//   { value: 6, text: i18n.t("events.on_hold") },
//   { value: 7, text: i18n.t("tasks_status.cancelled") },
// ];
const show_field = ["first_name", "last_name", "mobile"];
const WorkersFields = [
  // { type: "text", label: i18n.t("view_task.id"), id: "id", show_list: true },
  {
    type: "text",
    label: i18n.t("user_fields.full_name"),
    id: "full_name",
    show_list: true,
  },
  {
    type: "text",
    label: i18n.t("view_task.mobile"),
    id: "mobile",
    show_list: true,
  },
  {
    type: "select",
    label: i18n.t("view_task.status_text"),
    id: "status",
    show_list: true,
    defaultsTo: 1,
    data: [
      { value: 1, text: i18n.t("tasks_status.pending") },
      { value: 2, text: i18n.t("worker_view.accept") },
      { value: 3, text: i18n.t("worker_view.cancel") },
    ],
  },
];
const WorkersComp = (prop) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [usersList, setUsersList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState([]);
  const view = useSelector((state) => state.task.view);
  const userModel = useSelector((state) => state.user.value);
  const [selected, setSelected] = useState([]);
  const [inputSearch, setInputSearch] = React.useState("");
  const [userTemp, setUserTemp] = useState([]);
  const [disabledFetch, setDisabledFetch] = useState(false);
  useEffect(() => {
    setValues(view.filter((item) => item.id === prop.match.params.id));
    if (view.filter((item) => item.id === prop.match.params.id)[0]) {
      view
        .filter((item) => item.id === prop.match.params.id)[0]
        .workers_id.map((item) => {
          if (!selected.includes(item.id)) {
            setSelected((prevState) => [
              ...prevState,
              {
                value: item.id,
                text:
                  show_field.length < 2
                    ? item[show_field]
                    : show_field.map((text) => text + " "),
              },
            ]);
          }
        });
    }
    // const selected_tmp = view.filter(item=>item.id == prop.match.params.id)[0].workers_id
    // if(values[0]){
    //     console.log(values[0].workers_id)
    // }
  }, [view]);
  useEffect(() => {
    api
      .getList({ controller: "worker" })
      .then((res) => {
        if (res.length > 0) {
          setUserTemp(res);
          const temp = res.map((item) => {
            return {
              value: item.id,
              text:
                show_field.length < 2
                  ? item[show_field]
                  : show_field.map((text) => item[text] + " "),
            };
          });
          return temp;
        } else {
          return false;
        }
      })
      .then((result) => {
        if (result) {
          setUsersList(result);
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
  }, []);
  useEffect(() => {
    if (
      userTemp.filter(
        (item) =>
          item.mobile === inputSearch ||
          item.first_name === inputSearch ||
          item.last_name === inputSearch
      ).length > 0
    ) {
      const tmp = userTemp
        .filter(
          (item) =>
            item.mobile === inputSearch ||
            item.first_name === inputSearch ||
            item.last_name === inputSearch
        )
        .map((item) => {
          return {
            value: item.id,
            text:
              show_field.length < 2
                ? item[show_field]
                : show_field.map((text) => item[text] + " "),
          };
        });
      setUsersList(tmp);
    } else {
      const tmp = userTemp.map((item) => {
        return {
          value: item.id,
          text:
            show_field.length < 2
              ? item[show_field]
              : show_field.map((text) => item[text] + " "),
        };
      });
      setUsersList(tmp);
    }
  }, [inputSearch]);
  useEffect(() => {
    if (values[0]) {
      // const selected_tmp = values[0].workers_id;
    }
  }, [view]);
  // const onChange = (e) => {
  //   const tmp = {
  //     task_id: prop.match.params.id,
  //     id: e.target.id,
  //     data: { user_id: e.target.value, status: 1 },
  //   };
  //   dispatch(updateField(tmp));
  // };
  const handleChange = (event) => {
    setDisabledFetch(true);
    api
      .create({
        controller: `worker/${prop.match.params.id}`,
        data: { worker_id: event.value },
      })
      .then((res) => {
        dispatch(addView({ id: prop.match.params.id, data: res.data }));
        setDisabledFetch(false);
        console.log(res);
      });
  };
  const handleAccept = (event) => {
    api.create({
      controller: `worker/workers_update_status`,
      data: {
        task_id: prop.match.params.id,
        status: event,
        worker_id: userModel.user_id,
      },
    });
  };
  const clickToRemoveWorker = (e) => {
    setDisabledFetch(true);
    api
      .create({
        controller: `worker/remove_worker_in_task/${prop.match.params.id}`,
        data: { worker_id: `${e.id}` },
      })
      .then((result) => {
        dispatch(addView({ id: prop.match.params.id, data: result.data }));
        setDisabledFetch(false);
        console.log("test");
      });
  };
  if (loading || !values[0]) {
    return <div></div>;
  } else {
    return (
      <Grid container>
        <Grid item xs={10} md={10} lg={10} sm={10}>
          {values[0] && values[0].workers_id && (
            <ListView
              notFoundTitle="global_not_found"
              title={"workers"}
              footerContent={(e) =>
                userModel.role > 2 ? (
                  <IconButton onClick={(a) => clickToRemoveWorker(e)}>
                    <HighlightOff />
                  </IconButton>
                ) : (
                  <React.Fragment />
                )
              }
              localData={values[0].workers_id}
              fields={WorkersFields}
            />
          )}
        </Grid>
        {userModel.role > 2 && (
          <Grid item xs={10} md={10} lg={10} sm={10}>
            <Grid item xs={12} md={12} lg={12} sm={12}>
              <InputText
                id="search"
                value={inputSearch}
                label="Search"
                onChange={(e) => setInputSearch(e.target.value)}
              />
            </Grid>
            <hr />
            <InputSelect
              selected={selected.map((item) => item.value)}
              multiple
              disabled={
                disabledFetch ||
                (usersList.length > 0 &&
                  usersList.filter(
                    (i) =>
                      !values[0].workers_id.map((ii) => ii.id).includes(i.value)
                  ).length < 1)
              }
              data={
                usersList.length > 0
                  ? usersList.filter(
                      (i) =>
                        !values[0].workers_id
                          .map((ii) => ii.id)
                          .includes(i.value)
                    )
                  : []
              }
              value={selected}
              id="workers_id"
              onChange={(e) => handleChange(e)}
              label={t("worker_view.workers")}
            />
          </Grid>
        )}
        {userModel.role === 2 &&
          values[0] &&
          values[0].workers_id &&
          values[0].workers_id.filter((item) => item.id === userModel.user_id)
            .length > 0 &&
          values[0].workers_id.filter(
            (item) => item.id === userModel.user_id
          )[0].status < 2 && (
            <Grid item xs={10} md={10} lg={10} sm={10}>
              <ButtonGroup disableElevation variant="contained">
                <Button onClick={() => handleAccept(3)} color="error">
                  {t("worker_view.workers")}
                </Button>
                <Button onClick={() => handleAccept(2)} color="success">
                  {t("worker_view.accept")}
                </Button>
              </ButtonGroup>
            </Grid>
          )}{" "}
      </Grid>
    );
  }
};
export default WorkersComp;
